import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DatePipe } from '@angular/common';
import firebase from 'firebase/app';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import { eaf } from 'src/app/shared/sgTypes';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
	selector: 'app-feedback-complaints',
	templateUrl: './feedback-complaints.component.html',
	styleUrls: ['./feedback-complaints.component.scss'],
})
export class FeedbackComplaintsComponent implements OnInit {
	name: any;
	errorMsg: any;
	successMsg: any;

	constructor(private db: AngularFirestore) {}

	ngOnInit(): void {}

	insertFeedback() {
		if (this.feedbackForm.invalid) {
			this.errorMsg = 'Please fill all necessary details';
			return;
		}
		try {
			this.db.collection('Feedback&complaints').doc().set({
				name: this.feedbackForm.value.feedbackName,
				email: this.feedbackForm.value.feedbackEmail,
				comments: this.feedbackForm.value.feedback,
				targetGroup: this.feedbackForm.value.feedbackTargetGroup,
			});
			this.errorMsg = '';
			this.successMsg = 'YOUR INFORMATION HAS BEEN SUBMITTED!';
			this.feedbackForm.reset();
		} catch (error) {}
	}

	feedbackForm = new FormGroup({
		feedbackName: new FormControl('', Validators.required),
		feedbackEmail: new FormControl('', Validators.required),
		feedbackTargetGroup: new FormControl('', Validators.required),
		feedback: new FormControl(''),
	});
}
