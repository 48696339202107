<app-nav></app-nav>

<div class="page">
	<h1 class="header">
		<hr>
		ANNOUNCEMENTS
		<hr>
	</h1>

</div>

<app-footer></app-footer>
