import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';
import { petitions } from 'src/app/shared/sgTypes';

@Component({
	selector: 'app-pawprints2',
	templateUrl: './pawprints2.component.html',
	styleUrls: ['./pawprints2.component.scss'],
})
export class Pawprints2Component implements OnInit {
	p: petitions[] = [];

	constructor(private db: AngularFirestore, private datePipe: DatePipe) {}

	ngOnInit(): void {
		this.db
			.collection('Pawprints')
			.ref.get()
			.then((results) => {
				if (!results.empty) {
					for (let doc of results.docs) {
						this.p.push(doc.data() as petitions);
					}
				}
			});
	}
}
