<app-nav></app-nav>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<div class="container">
	<h1>CONNECT WITH US!</h1>
	<div class="triangle"></div>

	<div class="form">
		<form [formGroup]="feedbackForm" (ngSubmit)="insertFeedback()">
			<p>Name: </p>
			<div class="name">
				<input type="text" name="name" formControlName="feedbackName" placeholder="Your Name" />
			</div>

			<p>RIT Email: </p>
			<div class="email">
				<input type="text" name="email" formControlName="feedbackEmail" placeholder="user@rit.edu" />
			</div>

			<p>Who would you like your complaint/feedback to be read by?</p>
			<select name="group" id="selector" formControlName="feedbackTargetGroup">
				<option>Student Government</option>
				<option>Student Affairs</option>
				<option>Computing Senator</option>
				<option>Business Senator</option>
				<option>Engineering Senator</option>
			</select>

			<p id="element">Suggestions/Complaints:*</p>
			<textarea id="textbox" name="textbox" rows="5" cols="50" formControlName="feedback">
			</textarea>

			<div class="success">
				<p>
					<b>{{ successMsg }}</b>
				</p>
			</div>

			<input value="SEND" class="submitbtn" type="submit" />
		</form>
	</div>
</div>