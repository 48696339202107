<div class="admin-page">
	<app-side-panel class="no-print"></app-side-panel>
	<div class="admin-content">
		<div class="admin-header no-print">
			<h1 class="admin-title">View Evaluations</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>

		<div class="evalWrapper">
			<div
				class="monthlyEvaluation"
				*ngFor="let monthlyEvaluation of monthlyEvaluations"
			>
				<h1 class="no-print">{{ monthlyEvaluation.month }}</h1>
				<div
					class="evaluation no-print"
					*ngFor="let evaluation of monthlyEvaluation.evaluations"
					(click)="showEval(monthlyEvaluation.month, evaluation)"
				>
					<h3>{{ evaluation.name }}</h3>
					<p>
						Total evaluations:
						<span>{{ evaluation.feedback.length }}</span>
					</p>
					<p>
						Evaluation score:
						{{ evaluation.calculatedEvaluations.toFixed(0) }}
					</p>
				</div>

				<div class="modal" id="modal" #modal *ngIf="isShowEval">
					<div
						class="modal-container"
						id="modal_container"
						#modal_container
					>
						<h1>{{ currentEvaluation.month }}</h1>
						<button
							class="modal-close no-print"
							(click)="closeEval()"
						>
							X
						</button>
						<div
							class="modal-container-padded"
							id="modal_container_padded"
							#modal_container_padded
						>
							<div
								class="modal-group"
								*ngFor="let group of currentEvaluation.groups"
							>
								<h2 class="modal-group-title">
									{{ group.title }}
								</h2>
								<div class="modal-fields">
									<div
										class="field"
										*ngFor="let field of group.fields"
									>
										<p class="field-name">
											{{ field.name }}
										</p>
										<p class="field-value">
											{{ field.value }}
										</p>
									</div>
								</div>
							</div>
							<div class="commentSection">
								<label>Final Comments</label><br />
								<textarea
									id="memberComment"
									rows="10"
									cols="50"
									[(ngModel)]="finalComment"
								>
								</textarea>
							</div>
							<!-- <button class="generatePDF">Generate PDF</button> -->
							<button
								class="email no-print"
								(click)="sendEmail()"
							>
								Send Email
							</button>
							<button
								class="downloadPDF no-print"
								title="Share As PDF"
								(click)="downloadPDF(); closeEval()"
							>
								Download PDF
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
