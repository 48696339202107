<app-nav></app-nav>

<div class="page" style="margin-left: 5em; margin-right: 5em;">
	<h1 class="header">
		<hr>
		WHAT'S NEW
		<hr>
	</h1>
	<div class="bann"><img src="assets/img/bottle.png" alt="" style="width: 100%;"></div>
	<br>
	<br>
	<div class="no-event">MORE MERCH INCOMING!</div>
</div>

<app-footer></app-footer>
