import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { sgRoles } from 'src/app/shared/sgTypes';

@Component({
	selector: 'app-assign-roles',
	templateUrl: './assign-roles.component.html',
	styleUrls: ['./assign-roles.component.scss'],
})
export class AssignRolesComponent implements OnInit {
	route = 'assignRoles';
	personName: any;
	personEmail: any;
	personRole: any;
	userMessage: any;
	displayName: any;
	roleName: any;

	assignRoles = new FormGroup({
		name: new FormControl('', Validators.required),
		email: new FormControl('', [Validators.required, Validators.email]),
		role: new FormControl('', Validators.required),
	});

	sgRole: sgRoles[] = [];

	constructor(private db: AngularFirestore, public auth: AuthService) {}

	ngOnInit(): void {}

	addRole() {
		this.personName = this.assignRoles.value.name;
		this.personEmail = this.assignRoles.value.email;
		this.personRole = this.assignRoles.value.role;
		switch (this.assignRoles.value.role) {
			case 'sgPresident':
				this.roleName = 'SG President';
				break;
			case 'sgVicePresident':
				this.roleName = 'SG Vice President';
				break;
			case 'itDirector':
				this.roleName = 'IT Director';
				break;
			case 'mediaDirector':
				this.roleName = 'Media Director';
				break;
			case 'eventsDirector':
				this.roleName = 'Events Director';
				break;
			case 'financeDirector':
				this.roleName = 'Finance Director';
				break;
			case 'clubsDirector':
				this.roleName = 'Clubs Director';
				break;
			case 'itCoordinator':
				this.roleName = 'IT Coordinator';
				break;
			case 'mediaCoordinator':
				this.roleName = 'Media Coordinator';
				break;
			case 'eventsCoordinator':
				this.roleName = 'Events Coordinator';
				break;
			case 'clubsCoordinator':
				this.roleName = 'Clubs Coordinator';
				break;
			case 'graphicDesigner':
				this.roleName = 'Graphic Designer';
				break;
			case 'coopOfficer':
				this.roleName = 'Co-op Officer';
				break;
			case 'studentAffairs':
				this.roleName = 'Student Affairs';
				break;
			case 'marketing':
				this.roleName = 'Marketing';
				break;
			case 'finance':
				this.roleName = 'Finance';
				break;
			case 'secretary':
				this.roleName = 'Secretary';
				break;
			case 'club':
				this.roleName = 'Club';
				break;
			case 'photographer':
				this.roleName = 'Photographer';
				break;
			case 'electricalSenator':
				this.roleName = 'Electrical Senator';
				break;
			case 'computingSenator':
				this.roleName = 'Computing Senator';
				break;
			case 'engineeringSenator':
				this.roleName = 'Engineering Senator';
				break;
			case 'businessSenator':
				this.roleName = 'Business Senator';
				break;
			case 'freshmenCoordinator':
				this.roleName = 'Freshmen Coordinator';
				break;
		}
		this.auth.createAUser(
			this.personName,
			this.personEmail,
			this.personRole,
			this.roleName
		);
		this.userMessage = 'Your member has been added successfully';
	}
}
