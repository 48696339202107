import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { evaluationFeedback, evaluationObject } from 'src/app/shared/sgTypes';

@Component({
	selector: 'app-member-eval',
	templateUrl: './member-eval.component.html',
	styleUrls: ['./member-eval.component.scss'],
})
export class MemberEvalComponent implements OnInit {
	total: number;
	errorMsg: string;
	feedback: evaluationFeedback;

	constructor(private db: AngularFirestore, public auth: AuthService) {}

	ngOnInit(): void {}

	memberEval = new FormGroup({
		evalDate: new FormControl(0, Validators.required),
		evalMonth: new FormControl(0, Validators.required),
		email: new FormControl(0, Validators.required),
		attendance: new FormControl(0, Validators.required),
		focus: new FormControl(0, Validators.required),
		productivity: new FormControl(0, Validators.required),
		stress: new FormControl(0, Validators.required),
		teamwork: new FormControl(0, Validators.required),
		coop: new FormControl(0, Validators.required),
		integrity: new FormControl(0, Validators.required),
		communication: new FormControl(0, Validators.required),
		sgUnderstanding: new FormControl(0, Validators.required),
		improvement: new FormControl(0, Validators.required),
		memberFeedback: new FormControl('', Validators.required),
	});

	calcTotal() {
		const attendance = this.memberEval.value.attendance;
		const focus = this.memberEval.value.focus;
		const productivity = this.memberEval.value.productivity;
		const stress = this.memberEval.value.stress;
		const teamwork = this.memberEval.value.teamwork;
		const coop = this.memberEval.value.coop;
		const integrity = this.memberEval.value.integrity;
		const communication = this.memberEval.value.communication;
		const sgUnderstanding = this.memberEval.value.sgUnderstanding;
		const improvement = this.memberEval.value.improvement;

		const currentUser = this.auth.users.filter(
			(user) => user.email === this.auth.user.email
		)[0];

		this.feedback = {
			name: currentUser.name,
			email: this.auth.user.email,
			role: this.auth.userRole,
			attendance: attendance,
			focus: focus,
			productivity: productivity,
			stressManagement: stress,
			teamwork: teamwork,
			attitude: coop,
			integrity: integrity,
			communication: communication,
			uniSGOriented: sgUnderstanding,
			improvement: improvement,
			comment: this.memberEval.value.memberFeedback,
		};

		this.total =
			attendance +
			focus +
			productivity +
			stress +
			teamwork +
			coop +
			integrity +
			communication +
			sgUnderstanding +
			improvement;
	}

	sendData() {
		if (this.memberEval.invalid) {
			this.errorMsg = 'Please fill all necessary details';
			return;
		}

		this.db.doc('SGEvaluation/' + this.memberEval.value.evalMonth).set({});

		this.db
			.doc<evaluationObject>(
				'SGEvaluation/' +
					this.memberEval.value.evalMonth +
					'/MonthEvaluation/' +
					this.memberEval.value.email
			)
			.ref.get()
			.then((obj) => {
				let directorEvaluation = 0;
				let evaluations = 0;
				let totalEvaluations = 0;
				let calculatedEvaluations = 0;
				if (this.auth.userRole.toLowerCase().includes('director')) {
					directorEvaluation += this.total;
					calculatedEvaluations = directorEvaluation;
				} else {
					evaluations += this.total;
					totalEvaluations += 100;
					calculatedEvaluations =
						evaluations / (totalEvaluations / 100);
				}

				if (obj.exists) {
					const data = obj.data();
					data.directorEvaluation =
						data.directorEvaluation == 0
							? data.directorEvaluation + directorEvaluation
							: directorEvaluation;
					data.evaluations += evaluations;
					data.totalEvaluations += totalEvaluations;

					const directorCalculation =
						data.directorEvaluation *
						(data.evaluations > 0 ? 0.4 : 1);
					const coordinatorCalculation =
						data.totalEvaluations > 0
							? (data.evaluations /
									(data.totalEvaluations / 100)) *
							  (data.directorEvaluation > 0 ? 0.6 : 1)
							: 0;
					data.calculatedEvaluations =
						directorCalculation + coordinatorCalculation;

					data.feedback.push(this.feedback);

					this.db
						.doc<evaluationObject>(
							'SGEvaluation/' +
								this.memberEval.value.evalMonth +
								'/MonthEvaluation/' +
								this.memberEval.value.email
						)
						.set(data)
						.then(() => this.memberEval.reset());
				} else {
					const feedbackUser = this.auth.users.filter(
						(user) => user.email === this.memberEval.value.email
					)[0];

					this.db
						.doc<evaluationObject>(
							'SGEvaluation/' +
								this.memberEval.value.evalMonth +
								'/MonthEvaluation/' +
								this.memberEval.value.email
						)
						.set({
							name: feedbackUser.name,
							email: this.memberEval.value.email,
							role: feedbackUser.roleName,
							directorEvaluation: directorEvaluation,
							evaluations: evaluations,
							totalEvaluations: totalEvaluations,
							calculatedEvaluations: calculatedEvaluations,
							feedback: [this.feedback],
						})
						.then(() => this.memberEval.reset());
				}
			})
			.catch((error) => (this.errorMsg = error));
	}
}
