import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-promotions',
	templateUrl: './promotions.component.html',
	styleUrls: ['./promotions.component.scss'],
})
export class PromotionsComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
