<nav class="try">
	<a href="/"><img class="nav-logo" src="assets/img/sgmain-logo.png" /></a>

	<div *ngIf="isMobile">
		<input class="menu" id="burger_menu" type="image" width=25px src="assets/menu.png" (click)="toggleMenu()">
		<div id="hidden_menu">
			<input style="display: none;" id="close_menu" type="image" width=25px src="assets/close.png"
				(click)="closeMenu()">
			<a *ngFor="let route of routes | keyvalue: originalOrder" href="" routerLink="{{ route.key }}"
				[ngClass]="getClass(route.key)">
				{{ route.value }}
			</a>
		</div>
	</div>

	<div *ngIf="!isMobile" class="nav-buttons">
		<a *ngFor="let route of routes | keyvalue: originalOrder" href="" routerLink="{{ route.key }}"
			[ngClass]="getClass(route.key)">
			{{ route.value }}
		</a>
	</div>
</nav>