import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit{
	[x: string]: any;
	@Input() active: string;
	isMenuOpen = false;

	constructor(
		public router: Router,
		private breakpointObserver: BreakpointObserver) {}

	ngOnInit() {
		this.breakpointObserver
		.observe([Breakpoints.Small, Breakpoints.XSmall])
		.subscribe((result) => {
			this.hideLogo = false;
			this.isMobile = false;
			
			if (result.breakpoints[Breakpoints.Small]) {
				this.isMobile = true;
			}
			
			if(result.breakpoints[Breakpoints.XSmall]) {
				this.isMobile = true;
			}
		});
	  }

	routes = {
		'/': 'HOME',
		'/about': 'ABOUT',
		'/events': 'EVENTS',
		'/promotions': 'PROMOTIONS',
		// '/announcements': 'ANNOUNCEMENTS',
		'/clubs': 'CLUBS',
		'/complaints': 'FEEDBACK',
		//'/pawprints': 'PAWPRINTS',
	};

	originalOrder() {
		return 0;
	}

	getClass(url: string): string {
		return this.router.url == url ? 'active' : '';
	}


	toggleMenu(): void {
		const popupElement = document.getElementById("hidden_menu");
		const closeMenu: HTMLImageElement | null = document.getElementById('close_menu') as HTMLImageElement | null;
		if (popupElement) {
		  popupElement.style.display = "block";
		  closeMenu.style.display = "block";
		  document.getElementById("burger_menu").style.display = "none";
		}
	}

	closeMenu(): void {
		document.getElementById("hidden_menu").style.display = "none";
		document.getElementById("close_menu").style.display = "none";
		document.getElementById("burger_menu").style.display = "block";
	}
}