<div class="admin-page">
	<app-side-panel></app-side-panel>
	<div class="admin-content">
		<div class="admin-header">
			<h1 class="admin-title">Assign Roles</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>

		<div class="admin-body">
			<div class="role-container">
				<div class="add-roles-container" [formGroup]="assignRoles">
					<div class="inputs">
						<label>Full Name</label>
						<input type="text" formControlName="name" />
					</div>

					<div class="inputs">
						<label>Email</label>
						<input type="email" formControlName="email" />
					</div>

					<div class="inputs">
						<label>Role</label>
						<select formControlName="role">
							<option value="sgPresident">President</option>
							<option value="sgVicePresident">
								Vice President
							</option>
							<option value="itDirector">IT Director</option>
							<option value="mediaDirector">
								Media Director
							</option>
							<option value="eventsDirector">
								Events Director
							</option>
							<option value="financeDirector">
								Finance Director
							</option>
							<option value="clubsDirector">
								Clubs Director
							</option>
							<option value="itCoordinator">
								IT Coordinator
							</option>
							<option value="mediaCoordinator">
								Media Coordinator
							</option>
							<option value="eventsCoordinator">
								Events Coordinator
							</option>
							<option value="clubsCoordinator">
								Clubs Coordinator
							</option>
							<option value="graphicDesigner">
								Graphic Designer
							</option>
							<option value="photographer">Photographer</option>
							<option value="electricalSenator">
								Electrical Senator
							</option>
							<option value="computingSenator">
								Computing Senator
							</option>
							<option value="engineeringSenator">
								Engineering Senator
							</option>
							<option value="businessSenator">
								Business Senator
							</option>
							<option value="freshmenCoordinator">
								Freshmen Coordinator
							</option>
							<option value="photographer">Photographer</option>
							<option value="coopOfficer">Coop Officer</option>
							<option value="secretary">Secretary</option>
							<option value="studentAffairs">
								Student Affairs
							</option>
							<option value="marketing">Marketing</option>
							<option value="finance">Finance</option>
							<option value="club">Club</option>
						</select>
					</div>

					<div class="inputs">
						<button (click)="addRole()">Add Role</button>
					</div>
				</div>
				<div class="userMessage">
					<p>{{ userMessage }}</p>
				</div>
				<div class="list-members">
					<div class="headers">
						<h2>Name</h2>
						<h2>Email</h2>
						<h2>Role</h2>
					</div>
					<div class="rows" *ngFor="let user of auth.users">
						<p>{{ user.name }}</p>
						<p>{{ user.email }}</p>
						<p>{{ user.roleName }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
