import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventsComponent } from './pages/events/events.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { AnnouncementsComponent } from './pages/announcements/announcements.component';
import { HomeComponent } from './pages/home/home.component';
import { ClubsComponent } from './pages/clubs/clubs.component';
import { AboutComponent } from './pages/about/about.component';
import { LoginComponent } from './admin/login/login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AddEventsComponent } from './admin/add-events/add-events.component';
import { PosterApprovalComponent } from './admin/poster-approval/poster-approval.component';
import { EAFComponent } from './admin/eaf/eaf.component';
import { AssignRolesComponent } from './admin/assign-roles/assign-roles.component';
import { FeedbackComplaintsComponent } from './pages/feedback-complaints/feedback-complaints.component';
import { PawprintsComponent } from './pages/pawprints/pawprints.component';
import { Pawprints2Component } from './pages/pawprints2/pawprints2.component';
import { ComplaintsComponent } from './admin/complaints/complaints.component';
import { ApprovalFormsComponent } from './admin/approval-forms/approval-forms.component';
import { MemberEvalComponent } from './admin/member-eval/member-eval.component';
import { ViewEvalComponent } from './admin/view-eval/view-eval.component';
import { PostApprovalComponent } from './admin/post-approval/post-approval.component';

const routes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: 'events', component: EventsComponent },
	{ path: 'promotions', component: PromotionsComponent },
	{ path: 'announcements', component: AnnouncementsComponent },
	{ path: 'clubs', component: ClubsComponent },
	{ path: 'about', component: AboutComponent },
	{ path: 'complaints', component: FeedbackComplaintsComponent },
	//{ path: 'pawprints', component: PawprintsComponent },
	//{ path: 'pawprints2', component: Pawprints2Component },
	// Admin
	{ path: 'login', component: LoginComponent },
	{ path: 'dashboard', component: AdminDashboardComponent },
	{ path: 'addevents', component: AddEventsComponent },
	{ path: 'posterapproval', component: PosterApprovalComponent },
	{ path: 'postapproval', component: PostApprovalComponent },
	{ path: 'assignroles', component: AssignRolesComponent },
	{ path: 'EAF', component: EAFComponent },
	{ path: 'admincomplaints', component: ComplaintsComponent },
	{ path: 'approvalforms', component: ApprovalFormsComponent },
	{ path: 'memberevaluation', component: MemberEvalComponent },
	{ path: 'viewEvals', component: ViewEvalComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
