<app-nav></app-nav>

<div class="page">
	<h1 class="header">
		<hr />
		ABOUT
		<hr />
	</h1>

	<div class="aboutSection">
		<p>
			The Student Government is a body of student representatives that
			serve as the voice of RIT Dubai Students to the various departments
			of the university. A President and Vice-President pair is elected by
			the student body who then recruit the rest of their cabinet via
			interviews, and in some cases elections.
		</p>
		<p>
			We are here to be your voice! Feel free to contact any of the
			members listed below for assistance during the semester.
		</p>

		<div class="teams">
			<h1>Meet the Student Government</h1>
			<div class="team" *ngFor="let team of sgTeams">
				<h2>{{ team.team }}</h2>
				<div class="teamMembers">
					<div class="member" *ngFor="let member of team.members">
						<img src="{{ member.img }}" alt="" />
						<h3>{{ member.title }}</h3>
						<p>{{ member.name }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>
