import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { eafDoc, postDoc, poster, posterDoc, sp } from 'src/app/shared/sgTypes';
@Component({
	selector: 'app-admin-dashboard',
	templateUrl: './admin-dashboard.component.html',
	styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
	tiltSettings = {
		max: 35,
		speed: 400,
		glare: true,
		'max-glare': 0.5,
	};

	csvRecords: sp[] = [];
	header = true;
	currenDate: any;

	latestNumOfEvents = 4;
	path: any;
	imageName: string;
	downloadUrl: any;
	uploadTask: any;
	userMessageEvents: any;
	userMessageSpiritPoints: any;
	email: any;
	role: any;

	sortedDoc: { title: string; fields: { name: string; value: string }[] }[];
	showEAFDoc = false;
	showPosterDoc = false;
	showPostDoc = false;

	rejectionComment: string;

	constructor(
		public FirebaseService: AuthService,
		public auth: AuthService
	) {}

	ngOnInit(): void {}

	openEAF(doc: eafDoc) {
		this.showEAFDoc = true;
		this.auth.currentEAFDoc = doc;
		this.sortedDoc = [
			{
				title: 'Requester Details',
				fields: [
					{
						name: 'Requester Name',
						value: doc.data.requestorName,
					},
					{
						name: 'Requester Contact',
						value: doc.data.requestorContact.toString(),
					},
					{
						name: 'Requester Email',
						value: doc.data.requestorEmail,
					},
					{
						name: 'Alternate Name',
						value: doc.data.alternateName,
					},
					{
						name: 'Alternate Contact',
						value: doc.data.alternateContact.toString(),
					},
					{
						name: 'Alternate Email',
						value: doc.data.alternateEmail,
					},
					{
						name: 'Club Organization',
						value: doc.data.clubOrganization,
					},
				],
			},
			{
				title: 'Event Details',
				fields: [
					{
						name: 'Event Name',
						value: doc.data.eventName,
					},
					{
						name: 'Event Location',
						value: doc.data.eventLocation,
					},
					{
						name: 'Event Date',
						value: doc.data.eventDate,
					},
					{
						name: 'Event Start Time',
						value: doc.data.eventStartTime,
					},
					{
						name: 'Event End Time',
						value: doc.data.eventEndTime,
					},
					{
						name: 'Event Description',
						value: doc.data.eventDescription,
					},
					{
						name: 'Event Speaker',
						value: doc.data.eventSpeaker,
					},
				],
			},
			{
				title: 'Budget Details',
				fields: [
					{
						name: 'Budget Requested',
						value: doc.data.budgetRequest,
					},
					{
						name: 'Budget Detail',
						value: doc.data.budgetDetail,
					},
					{
						name: 'Budget Collection Date',
						value: doc.data.budgetCollectionDate,
					},
				],
			},
			{
				title: 'Facilities Details',
				fields: [
					{
						name: 'Tables',
						value: doc.data.tables.toString(),
					},
					{
						name: 'Chairs',
						value: doc.data.chairs.toString(),
					},
					{
						name: 'Others',
						value: doc.data.others,
					},
				],
			},
			{
				title: 'Speaker Details',
				fields: [
					{
						name: 'Speaker Name',
						value: doc.data.speakerName,
					},
					{
						name: 'Speaker Position',
						value: doc.data.speakerPosition,
					},
					{
						name: 'Speaker Contact',
						value: doc.data.speakerContact.toString(),
					},
					{
						name: 'Speaker Company',
						value: doc.data.speakerCompany,
					},
					{
						name: 'Speaker Email',
						value: doc.data.speakerEmail,
					},
				],
			},
			{
				title: 'Media Details',
				fields: [
					{
						name: 'Media Email Content',
						value: doc.data.mediaEmailContent,
					},
					{
						name: 'Media SG Poster',
						value: null,
					},
					{
						name: 'Media Advertising',
						value: doc.data.mediaAdvertising,
					},
					{
						name: 'Media Rights',
						value: doc.data.mediaRights,
					},
				],
			},
		];
	}

	closeEAF() {
		this.showEAFDoc = false;
	}

	openPost(doc: postDoc) {
		this.showPostDoc = true;
		this.auth.currentPostDoc = doc;
		this.sortedDoc = [
			{
				title: 'Requester Details',
				fields: [
					{
						name: 'Post Account',
						value: doc.data.postAccount,
					},
					{
						name: 'Post Drive Link',
						value: doc.data.postDriveLink,
					},
					{
						name: 'Post Description',
						value: doc.data.postDescription,
					},
				],
			},
		];
	}

	closePost() {
		this.showPostDoc = false;
	}
	openPoster(doc: posterDoc) {
		this.showPosterDoc = true;
		this.auth.currentPosterDoc = doc;
		this.sortedDoc = [
			{
				title: 'Requester Details',
				fields: [
					{
						name: 'Poster Name',
						value: doc.data.posterName,
					},
					{
						name: 'Poster Event Date',
						value: doc.data.posterEventDate,
					},
					{
						name: 'Poster Link',
						value: doc.data.posterLink,
					},
					{
						name: 'Poster Comments',
						value: doc.data.posterComments,
					},
				],
			},
		];
	}

	closePoster() {
		this.showPosterDoc = false;
	}

	approveEAF() {
		this.auth.approveEAF();
		this.showEAFDoc = false;
	}

	approvePoster() {
		this.auth.approvePoster();
		this.showPosterDoc = false;
	}

	approvePost() {
		this.auth.approvePost();
		this.showPostDoc = false;
	}

	rejectEAF() {
		this.auth.rejectEAF();
		this.showEAFDoc = false;
	}

	rejectPoster() {
		this.auth.rejectPoster(this.rejectionComment);
		this.showPosterDoc = false;
	}

	rejectPost() {
		this.auth.rejectPost(this.rejectionComment);
		this.showPostDoc = false;
	}
}
