import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import firebase from 'firebase/app';
import { AuthService } from 'src/app/services/auth.service';
import { post } from 'src/app/shared/sgTypes';

@Component({
	selector: 'app-post-approval',
	templateUrl: './post-approval.component.html',
	styleUrls: ['./post-approval.component.scss'],
})
export class PostApprovalComponent implements OnInit {
	posterName: any;
	errorMsg: any;
	successMsg: any;

	dateString: any;

	minEventStartDate: string;

	constructor(private db: AngularFirestore, public auth: AuthService) {}

	ngOnInit() {
		const date = new Date();
		date.setDate(date.getDate() + 7);
		this.minEventStartDate = date.toISOString().split('T')[0];
	}

	errorMessage() {}

	insertData() {
		if (this.postDetails.invalid) {
			this.errorMsg = 'Please fill all necessary details';
			return;
		}
		console.log(this.postDetails.value);
		try {
			this.db
				.collection('Approvals/Post/Pending')
				.doc<post>()
				.set({
					postAccount: this.postDetails.value.postAccount,
					postDriveLink: this.postDetails.value.postDriveLink,
					postDescription: this.postDetails.value.postDescription,
					dateCreated:
						firebase.firestore.FieldValue.serverTimestamp(),
					clubsDirector: false,
					studentAffairs: false,
				} as post)
				.then(() => {
					this.errorMsg = '';
					this.successMsg = 'Submitted!';
					this.postDetails.reset();
				});
		} catch (error) {
			console.log(error);
		}
	}

	postDetails = new FormGroup({
		postAccount: new FormControl('', Validators.required),
		postDriveLink: new FormControl('', Validators.required),
		postDescription: new FormControl(''),
	});
}
