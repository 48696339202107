import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';
import { event, eventsCollection } from 'src/app/shared/sgTypes';
import 'firebase/storage';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
	today: Date;
	daysToShow = 5; // Days showing
	test: any;
	empty: boolean = true;

	eventsCollection: eventsCollection = {
		days: [],
		dates: [],
		events: [],
	};

	colorIndex: number = -1;
	colors: string[] = ['#ffc800', '#00aeff', '#00ffcc', '#FB4D3D', '#ff0055'];

	modalToggle: boolean = false;
	eventImage: string;
	eventName: string;
	eventDesc: string;
	eventZoom: string;
	eventDate: string;
	eventTime: string;

	constructor(private db: AngularFirestore, public datepipe: DatePipe) {}
	
	ngOnInit(): void {
		this.today = this.convertTZ(new Date(), 'Asia/Dubai');
	  
		for (let i = 0; i < this.daysToShow; i++) {
		  const date = this.getDate(this.today, i);
	  
		  this.eventsCollection.days.push(this.getDays(date));
		  this.eventsCollection.dates.push(this.formatDate(date));
		}
	  
		this.getTotalEvents();
	  }
	  
	getTotalEvents(): void {
		this.empty = true;
		for (let i = 0; i < this.daysToShow; i++) {
			const dateOfEvent = this.eventsCollection.dates[i];
			this.db
				.collection('UpcomingEvents/' + dateOfEvent + '/Events')
				.ref.get()
				.then((results) => {
					let docs: event[] = [];
					results.docs.forEach((doc) => {
						if (doc.exists) {
							docs.push(doc.data() as event);
							this.empty = false;
						} else docs.push({} as event);
					});
					docs = (docs as event[]).sort((a: event, b: event) => {
						let timeA = parseInt(a.time.replace(':', ''));
						let timeB = parseInt(b.time.replace(':', ''));
						return timeA - timeB;
					});
					this.eventsCollection.events.push(docs);
				});
		}
	}

	// getDays(date: Date, days: number): string {
	// 	date.setDate(date.getDate() + days);
	// 	let currDay = date.getDay();
	// 	if (currDay > 6 || currDay < 0) { // starts from 0 = Sunday to 6 = Saturday
	// 		return null;
	// 	}
	// 	return [
	// 		'Sunday',
	// 		'Monday',
	// 		'Tuesday',
	// 		'Wednesday',
	// 		'Thursday',
	// 		'Friday',
	// 		'Saturday',
	// 	][currDay];
	// }

	// getDate(date: Date, days: number): Date {
	// 	date.setDate(date.getDate() + days);
	// 	return date;
	// } 
	  
	  getDate(baseDate: Date, daysToAdd: number): Date {
		const date = new Date(baseDate);
		date.setDate(baseDate.getDate() + daysToAdd);
		return date;
	  }
	  
	  getDays(date: Date): string {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		const dayIndex = date.getDay();
		return daysOfWeek[dayIndex];
	  }
	  
	  formatDate(date: Date): string {
		const formattedDate = this.datepipe.transform(date, 'yyyy-MM-dd');
		return formattedDate;
	  }
	  

	public dateButtonClick(date: Date, days: number):void{
		this.ngOnInit();
	}

	openEvent(event: event): void {
		this.eventName = event.eventName;
		this.eventDesc = event.eventDescription;
		this.eventImage = event.eventImg;
		this.eventZoom = event.zoomlink;
		this.eventDate = event.date;
		this.eventTime = event.time;
		this.modalToggle = true;
	}

	closeEvent(event: any) {
		event.stopPropagation();
		this.modalToggle = false;
	}

	convertTZ(date: Date, tzString: string): Date {
		const targetTimezone = tzString;
		const timeZoneDifference = date.getTimezoneOffset() / 60 + (tzString === 'Asia/Dubai' ? -4 : 0); // Adjust for Dubai timezone
		const utcDate = date.getTime() + timeZoneDifference * 60 * 60 * 1000;
		return new Date(utcDate);
	  }
}
