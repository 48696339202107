<div class="admin-page">
	<app-side-panel></app-side-panel>
	<div class="admin-content">
		<div class="admin-header">
			<h1 class="admin-title">Feedback and Complaints</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>
		<div class="admin-body">
			<div class="container">
				<div class="feedback-box" *ngFor="let feedback of fandc">
					<img src="../../../assets/admin/feedback/paw rit.png" />
					<h3>
						Name: <span> {{ feedback.name }}</span>
					</h3>
					<h3>
						Email: <span>{{ feedback.email }}</span>
					</h3>
					<h3 class="targetGroup">
						Target Group
						<span> {{ feedback.targetGroup }}</span>
					</h3>
					<h3>
						Feedback/Complaint
						<span> {{ feedback.comments }}</span>
					</h3>
				</div>
			</div>
		</div>
	</div>
</div>
