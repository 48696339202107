<div class="admin-page">
	<app-side-panel></app-side-panel>
	<div class="admin-content">
		<div class="admin-header">
			<h1 class="admin-title">Posters</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>
		<div class="admin-body">
			<!--[formGroup]-->
			<form [formGroup]="posterDetails">
				<!--formgroupname-->
				<div class="new-poster">
					<h1><b>New poster Approval</b></h1>
					<br />
					<p>Name of event</p>
					<div class="poster name">
						<input
							type="text"
							name="pName"
							formControlName="posterName"
						/>
					</div>

					<br />
					<p>Event date</p>
					<div class="poster name">
						<input
							type="date"
							name="posterEventdate"
							formControlName="posterEventDate"
							[min]="minEventStartDate"
						/>
					</div>

					<!--formcontrolname-->
					<label>Poster upload link</label><br />
					<input
						type="text"
						name="posterLink"
						formControlName="posterLink"
					/>
					<!-- <input type="file" id="poster" formControlName="pImage" /> -->
					<br />

					<p>Comments</p>

					<textarea
						id="comms"
						name="comms"
						placeholder="Give us a brief description of your poster!"
						formControlName="posterComms"
						rows="5"
						cols="65"
					></textarea>
					<br />

					<div class="errorMsg">
						<p>{{ errorMsg }}</p>
					</div>
					<div class="success">
						<p>{{ successMsg }}</p>
					</div>

					<button
						class="uploadbtn"
						type="submit"
						(click)="insertData()"
					>
						Upload
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
