import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import {
	eaf,
	eafDoc,
	eafRoles,
	post,
	postDoc,
	poster,
	posterDoc,
	posterRoles,
} from 'src/app/shared/sgTypes';

@Component({
	selector: 'app-approval-forms',
	templateUrl: './approval-forms.component.html',
	styleUrls: ['./approval-forms.component.scss'],
})
export class ApprovalFormsComponent implements OnInit {
	selectedTab = 'events';
	selectedApproval = '';

	showEAFDoc = false;
	showPosterDoc = false;
	showPostDoc = false;

	eafDocs: eafDoc[];
	posterDocs: posterDoc[];
	postDocs: postDoc[];

	isCurrentEAFApproved = false;

	isCurrentPosterApproved = false;

	sortedDoc: { title: string; fields: { name: string; value: string }[] }[];

	constructor(public auth: AuthService, private db: AngularFirestore) {}

	ngOnInit(): void {
		this.loadInProgress();
	}

	openEAF(doc: eafDoc) {
		this.showEAFDoc = true;
		this.auth.currentEAFDoc = doc;
		const state =
			this.auth.currentEAFDoc.data[this.auth.userAuthRole as eafRoles];
		this.isCurrentEAFApproved = state !== undefined && !state;
		this.sortedDoc = [
			{
				title: 'Requester Details',
				fields: [
					{
						name: 'Requester Name',
						value: doc.data.requestorName,
					},
					{
						name: 'Requester Contact',
						value: doc.data.requestorContact.toString(),
					},
					{
						name: 'Requester Email',
						value: doc.data.requestorEmail,
					},
					{
						name: 'Alternate Name',
						value: doc.data.alternateName,
					},
					{
						name: 'Alternate Contact',
						value: doc.data.alternateContact.toString(),
					},
					{
						name: 'Alternate Email',
						value: doc.data.alternateEmail,
					},
					{
						name: 'Club Organization',
						value: doc.data.clubOrganization,
					},
				],
			},
			{
				title: 'Event Details',
				fields: [
					{
						name: 'Event Name',
						value: doc.data.eventName,
					},
					{
						name: 'Event Location',
						value: doc.data.eventLocation,
					},
					{
						name: 'Event Date',
						value: doc.data.eventDate,
					},
					{
						name: 'Event Start Time',
						value: doc.data.eventStartTime,
					},
					{
						name: 'Event End Time',
						value: doc.data.eventEndTime,
					},
					{
						name: 'Event Description',
						value: doc.data.eventDescription,
					},
					{
						name: 'Event Speaker',
						value: doc.data.eventSpeaker,
					},
				],
			},
			{
				title: 'Budget Details',
				fields: [
					{
						name: 'Budget Requested',
						value: doc.data.budgetRequest,
					},
					{
						name: 'Budget Detail',
						value: doc.data.budgetDetail,
					},
					{
						name: 'Budget Collection Date',
						value: doc.data.budgetCollectionDate,
					},
				],
			},
			{
				title: 'Facilities Details',
				fields: [
					{
						name: 'Tables',
						value: doc.data.tables.toString(),
					},
					{
						name: 'Chairs',
						value: doc.data.chairs.toString(),
					},
					{
						name: 'Others',
						value: doc.data.others,
					},
				],
			},
			{
				title: 'Speaker Details',
				fields: [
					{
						name: 'Speaker Name',
						value: doc.data.speakerName,
					},
					{
						name: 'Speaker Position',
						value: doc.data.speakerPosition,
					},
					{
						name: 'Speaker Contact',
						value: doc.data.speakerContact.toString(),
					},
					{
						name: 'Speaker Company',
						value: doc.data.speakerCompany,
					},
					{
						name: 'Speaker Email',
						value: doc.data.speakerEmail,
					},
				],
			},
			{
				title: 'Media Details',
				fields: [
					{
						name: 'Media Email Content',
						value: doc.data.mediaEmailContent,
					},
					{
						name: 'Media SG Poster',
						value: null,
					},
					{
						name: 'Media Advertising',
						value: doc.data.mediaAdvertising,
					},
					{
						name: 'Media Rights',
						value: doc.data.mediaRights,
					},
				],
			},
		];
	}

	openPoster(doc: posterDoc) {
		this.showPosterDoc = true;
		this.auth.currentPosterDoc = doc;
		const state =
			this.auth.currentPosterDoc.data[
				this.auth.userAuthRole as posterRoles
			];
		this.isCurrentEAFApproved = state !== undefined && !state;
		this.sortedDoc = [
			{
				title: 'Requester Details',
				fields: [
					{
						name: 'Poster Name',
						value: doc.data.posterName,
					},
					{
						name: 'Poster Event Date',
						value: doc.data.posterEventDate,
					},
					{
						name: 'Poster Link',
						value: doc.data.posterLink,
					},
					{
						name: 'Poster Comments',
						value: doc.data.posterComments,
					},
					{
						name: 'Rejection Reason',
						value: doc.data.rejectionComment,
					},
				],
			},
		];
	}

	openPost(doc: postDoc) {
		this.showPostDoc = true;
		this.auth.currentPostDoc = doc;
		this.sortedDoc = [
			{
				title: 'Requester Details',
				fields: [
					{
						name: 'Post Account',
						value: doc.data.postAccount,
					},
					{
						name: 'Post Drive Link',
						value: doc.data.postDriveLink,
					},
					{
						name: 'Post Description',
						value: doc.data.postDescription,
					},
				],
			},
		];
	}

	closeEAF() {
		this.showEAFDoc = false;
	}

	closePoster() {
		this.showPosterDoc = false;
	}

	closePost() {
		this.showPostDoc = false;
	}

	approveEAF() {
		this.auth.approveEAF();
		this.showEAFDoc = false;
	}

	approvePoster() {
		this.auth.approvePoster();
		this.showPosterDoc = false;
	}

	rejectEAF() {
		this.auth.rejectEAF();
		this.showEAFDoc = false;
	}

	// rejectPoster() {
	// 	this.auth.rejectPoster(rejectionComment);
	// 	this.showPosterDoc = false;
	// }

	loadEvents() {
		if (this.selectedTab === 'events') return;
		this.selectedTab = 'events';
		this.selectedApproval = '';
		this.loadInProgress();
	}

	loadPosts() {
		if (this.selectedTab === 'posts') return;
		this.selectedTab = 'posts';
		this.selectedApproval = '';
		this.loadInProgress();
	}
	loadPosters() {
		if (this.selectedTab === 'posters') return;
		this.selectedTab = 'posters';
		this.selectedApproval = '';
		this.loadInProgress();
	}

	loadInProgress() {
		if (this.selectedApproval === 'inProgress') return;
		this.selectedApproval = 'inProgress';
		this.eafDocs = [];
	}

	loadApproved() {
		if (this.selectedApproval === 'approved') return;
		this.selectedApproval = 'approved';
		this.eafDocs = [];
		switch (this.selectedTab) {
			case 'events':
				this.getEAFApproved();
				break;
			case 'posters':
				this.getPosterApproved();
				break;
			case 'posts':
				this.getPostApproved();
				break;
			default:
				break;
		}
	}

	loadRejected() {
		if (this.selectedApproval === 'rejected') return;
		this.selectedApproval = 'rejected';
		this.eafDocs = [];
		switch (this.selectedTab) {
			case 'events':
				this.getEAFRejected();
				break;
			case 'posters':
				this.getPosterRejected();
				break;
			case 'posts':
				this.getPostRejected();
				break;
			default:
				break;
		}
	}

	getEAFProgress() {
		// if (this.auth.isEventApprovalRole()) {
		this.db
			.collection<eaf>('Approvals/EAF/Pending')
			.ref.get()
			.then((docs) => {
				const doc = docs.docs.map((doc) => {
					const id = doc.id;
					const data = doc.data();
					return {
						id,
						createdOn: data.dateCreated.toDate(),
						type: 'Event Approval',
						data,
					};
				});

				if (
					this.selectedTab === 'events' &&
					this.selectedApproval === 'inProgress'
				) {
					this.eafDocs = doc.sort((a: any, b: any) => {
						return b.createdOn - a.createdOn;
					});
				}
			});
		// }
	}

	getEAFApproved() {
		// if (this.auth.isEventApprovalRole()) {
		this.db
			.collection<eaf>('Approvals/EAF/Approved')
			.ref.get()
			.then((docs) => {
				const doc = docs.docs.map((doc) => {
					const id = doc.id;
					const data = doc.data();
					return {
						id,
						createdOn: data.dateCreated.toDate(),
						type: 'Event Approval',
						data,
					};
				});

				if (
					this.selectedTab === 'events' &&
					this.selectedApproval === 'approved'
				) {
					this.eafDocs = doc.sort((a: any, b: any) => {
						return b.createdOn - a.createdOn;
					});
				}
			});
		// }
	}

	getEAFRejected() {
		// if (this.auth.isEventApprovalRole()) {
		this.db
			.collection<eaf>('Approvals/EAF/Rejected')
			.ref.get()
			.then((docs) => {
				const doc = docs.docs.map((doc) => {
					const id = doc.id;
					const data = doc.data();
					return {
						id,
						createdOn: data.dateCreated.toDate(),
						type: 'Event Approval',
						data,
					};
				});

				if (
					this.selectedTab === 'events' &&
					this.selectedApproval === 'rejected'
				) {
					this.eafDocs = doc.sort((a: any, b: any) => {
						return b.createdOn - a.createdOn;
					});
				}
			});
		// }
	}

	getPosterProgress() {
		// if (this.auth.isEventApprovalRole()) {
		this.db
			.collection<poster>('Approvals/Poster/Pending')
			.ref.get()
			.then((docs) => {
				const doc = docs.docs.map((doc) => {
					const id = doc.id;
					const data = doc.data();
					return {
						id,
						createdOn: data.dateCreated.toDate(),
						type: 'Poster Approval',
						data,
					};
				});

				if (
					this.selectedTab === 'posters' &&
					this.selectedApproval === 'inProgress'
				) {
					this.posterDocs = doc.sort((a: any, b: any) => {
						return b.createdOn - a.createdOn;
					});
				}
			});
		// }
	}

	getPosterApproved() {
		// if (this.auth.isEventApprovalRole()) {
		this.db
			.collection<poster>('Approvals/Poster/Approved')
			.ref.get()
			.then((docs) => {
				const doc = docs.docs.map((doc) => {
					const id = doc.id;
					const data = doc.data();
					return {
						id,
						createdOn: data.dateCreated.toDate(),
						type: 'Poster Approval',
						data,
					};
				});

				if (
					this.selectedTab === 'posters' &&
					this.selectedApproval === 'approved'
				) {
					this.posterDocs = doc.sort((a: any, b: any) => {
						return b.createdOn - a.createdOn;
					});
				}
			});
		// }
	}
	getPostApproved() {
		// if (this.auth.isEventApprovalRole()) {
		this.db
			.collection<post>('Approvals/Post/Approved')
			.ref.get()
			.then((docs) => {
				const doc = docs.docs.map((doc) => {
					const id = doc.id;
					const data = doc.data();
					return {
						id,
						createdOn: data.dateCreated.toDate(),
						type: 'Post Approval',
						data,
					};
				});

				if (
					this.selectedTab === 'posts' &&
					this.selectedApproval === 'approved'
				) {
					this.postDocs = doc.sort((a: any, b: any) => {
						return b.createdOn - a.createdOn;
					});
				}
			});
		// }
	}

	getPosterRejected() {
		// if (this.auth.isEventApprovalRole()) {
		this.db
			.collection<poster>('Approvals/Poster/Rejected')
			.ref.get()
			.then((docs) => {
				const doc = docs.docs.map((doc) => {
					const id = doc.id;
					const data = doc.data();
					return {
						id,
						createdOn: data.dateCreated.toDate(),
						type: 'Poster Approval',
						data,
					};
				});

				if (
					this.selectedTab === 'posters' &&
					this.selectedApproval === 'rejected'
				) {
					this.posterDocs = doc.sort((a: any, b: any) => {
						return b.createdOn - a.createdOn;
					});
				}
			});
	}
	getPostRejected() {
		this.db
			.collection<post>('Approvals/Post/Rejected')
			.ref.get()
			.then((docs) => {
				const doc = docs.docs.map((doc) => {
					const id = doc.id;
					const data = doc.data();
					return {
						id,
						createdOn: data.dateCreated.toDate(),
						type: 'Post Approval',
						data,
					};
				});

				if (
					this.selectedTab === 'posts' &&
					this.selectedApproval === 'rejected'
				) {
					this.postDocs = doc.sort((a: any, b: any) => {
						return b.createdOn - a.createdOn;
					});
				}
			});
	}
}
