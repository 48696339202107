import { Component, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform'; // Added
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
	title = 'RITDubaiSGWebsite';
	

	// Added
	constructor(
		public platform: Platform,
		private breakpointObserver: BreakpointObserver
	){}
	ngOnInit(): void {
		
		
		// Breakpoints
		this.breakpointObserver.observe(Breakpoints.HandsetLandscape)
      .subscribe(result => {

        const breakpoints = result.breakpoints;

    if (breakpoints[Breakpoints.TabletPortrait]) {
      console.log("screens matches TabletPortrait");
    }
    else if (breakpoints[Breakpoints.HandsetLandscape]) {
      console.log("screens matches HandsetLandscape");
    }
	//throw new Error('Method not implemented.');
  });
	}
}
