import { BrowserModule } from '@angular/platform-browser';
import { LayoutModule } from '@angular/cdk/layout'; // Added
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { EventsComponent } from './pages/events/events.component';
import { NavComponent } from './shared/nav/nav.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SpiritPointsComponent } from './pages/spirit-points/spirit-points.component';
import { ClubsComponent } from './pages/clubs/clubs.component';
import { AboutComponent } from './pages/about/about.component';
import { LoginComponent } from './admin/login/login.component';
import { AddEventsComponent } from './admin/add-events/add-events.component';
import { FormsModule } from '@angular/forms';
import { SidePanelComponent } from './admin/side-panel/side-panel.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import {
	DatePipe,
	HashLocationStrategy,
	LocationStrategy,
} from '@angular/common';
import { EAFComponent } from './admin/eaf/eaf.component';
import { PosterApprovalComponent } from './admin/poster-approval/poster-approval.component';
import { AssignRolesComponent } from './admin/assign-roles/assign-roles.component';
import { AngularTiltModule } from 'angular-tilt';
import { ReactiveFormsModule } from '@angular/forms';
import { FeedbackComplaintsComponent } from './pages/feedback-complaints/feedback-complaints.component';
import { ComplaintsComponent } from './admin/complaints/complaints.component';
import { ApprovalFormsComponent } from './admin/approval-forms/approval-forms.component';
import { MemberEvalComponent } from './admin/member-eval/member-eval.component';
import { UpcomingEventsAdminComponent } from './admin/upcoming-events-admin/upcoming-events-admin.component';
import { ViewEvalComponent } from './admin/view-eval/view-eval.component';
import { AddToCalendarModule } from 'add-events-to-google-calendar';
import { PostApprovalComponent } from './admin/post-approval/post-approval.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { AnnouncementsComponent } from './pages/announcements/announcements.component';
import { PawprintsComponent } from './pages/pawprints/pawprints.component';
import { Pawprints2Component } from './pages/pawprints2/pawprints2.component';
import { SlideshowComponent } from './slideshow/slideshow.component';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		EventsComponent,
		NavComponent,
		FooterComponent,
		SpiritPointsComponent,
		ClubsComponent,
		AboutComponent,
		LoginComponent,
		SidePanelComponent,
		AdminDashboardComponent,
		AddEventsComponent,
		EAFComponent,
		PosterApprovalComponent,
		AssignRolesComponent,
		FeedbackComplaintsComponent,
		ComplaintsComponent,
		ApprovalFormsComponent,
		MemberEvalComponent,
		UpcomingEventsAdminComponent,
		ViewEvalComponent,
		PostApprovalComponent,
		PromotionsComponent,
		AnnouncementsComponent,
		PawprintsComponent,
		Pawprints2Component,
		SlideshowComponent,
	],
	imports: [
		BrowserModule,
		LayoutModule, // Added
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		AngularFireModule.initializeApp({
			apiKey: 'AIzaSyDPDcf1kgUFCsBB8trJSRrFbpXvi_aEEU4',
			authDomain: 'ritdubaisgapp-f5b2b.firebaseapp.com',
			databaseURL: 'https://ritdubaisgapp-f5b2b.firebaseio.com',
			projectId: 'ritdubaisgapp-f5b2b',
			storageBucket: 'ritdubaisgapp-f5b2b.appspot.com',
			messagingSenderId: '281224643386',
			appId: '1:281224643386:web:2ec1e85ef23a33810d0603',
			measurementId: 'G-NMNSV2473T',
		}),
		AngularTiltModule,
		AddToCalendarModule,
	],
	providers: [
		DatePipe,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
