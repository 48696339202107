import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { DatePipe } from '@angular/common';
import { feedbacks } from 'src/app/shared/sgTypes';

@Component({
	selector: 'app-complaints',
	templateUrl: './complaints.component.html',
	styleUrls: ['./complaints.component.scss'],
})
export class ComplaintsComponent implements OnInit {
	fandc: feedbacks[] = [];
	constructor(
		private db: AngularFirestore,
		public auth: AuthService,
		private datePipe: DatePipe
	) {}

	ngOnInit(): void {
		this.db
			.collection('Feedback&complaints')
			.ref.get()
			.then((results) => {
				if (!results.empty) {
					for (let doc of results.docs) {
						this.fandc.push(doc.data() as feedbacks);
					}
				}
			});
	}
}
