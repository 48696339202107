import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DatePipe } from '@angular/common';
import firebase from 'firebase/app';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import { eaf, poster } from 'src/app/shared/sgTypes';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
	selector: 'app-poster-approval',
	templateUrl: './poster-approval.component.html',
	styleUrls: ['./poster-approval.component.scss'],
})
export class PosterApprovalComponent implements OnInit {
	route = 'addPoster';
	posterName: any;
	errorMsg: any;
	successMsg: any;

	dateString: any;

	minEventStartDate: string;

	constructor(private db: AngularFirestore, public auth: AuthService) {}

	ngOnInit() {
		const date = new Date();
		date.setDate(date.getDate() + 7);
		this.minEventStartDate = date.toISOString().split('T')[0];
	}

	errorMessage() {}

	insertData() {
		if (this.posterDetails.invalid) {
			this.errorMsg = 'Please fill all necessary details';
			return;
		}
		try {
			this.db
				.collection('Approvals/Poster/Pending')
				.doc<poster>()
				.set({
					posterName: this.posterDetails.value.posterName,
					posterEventDate: this.posterDetails.value.posterEventDate,
					posterLink: this.posterDetails.value.posterLink,
					posterComments: this.posterDetails.value.posterComms,
					dateCreated:
						firebase.firestore.FieldValue.serverTimestamp(),
					clubsDirector: false,
					studentAffairs: false,
				} as poster)
				.then(() => {
					this.errorMsg = '';
					this.successMsg = 'Submitted!';
					this.posterDetails.reset();
				});
		} catch (error) {
			//this.errorMsg = error;
		}
	}

	posterDetails = new FormGroup({
		posterName: new FormControl('', Validators.required),
		posterEventDate: new FormControl('', Validators.required),
		posterLink: new FormControl(''),
		posterComms: new FormControl(''),
	});
}
