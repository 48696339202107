<div class="admin-page">
	<app-side-panel></app-side-panel>
	<div class="admin-content">
		<div class="admin-header">
			<h1 class="admin-title">Events</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>

		<div class="admin-body">
			<div class="addEvents-Container">
				<h1>Add Events</h1>
				<div class="input">
					<input
						type="text"
						class="input-field"
						#nameOfEvent
						required
					/>
					<label class="input-label">Event name</label>
				</div>

				<div class="input">
					<input
						type="text"
						class="input-field"
						#eventDescription
						required
					/>
					<label class="input-label">Event Description</label>
				</div>

				<div class="input">
					<input
						type="datetime-local"
						class="input-field"
						#eventDateTime
						required
					/>
				</div>

				<div class="input">
					<input type="text" class="input-field" #zoomLink required />
					<label class="input-label">Zoom Link</label>
				</div>

				<div class="input">
					<input
						type="file"
						id="eventImage"
						required
						class="input-field"
						#zoomLink
						required
						(change)="upload($event)"
					/>
					<label class="poster-label" for="eventImage"
						>Upload Event Poster</label
					>
				</div>

				<div class="add-button">
					<p>{{ userMessageEvents }}</p>
					<button
						(click)="
							getValue(
								eventDateTime.value,
								nameOfEvent.value,
								eventDescription.value,
								zoomLink.value
							)
						"
					>
						Add Event
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
