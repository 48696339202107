import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { sp } from 'src/app/shared/sgTypes';

@Component({
	selector: 'app-spirit-points',
	templateUrl: './spirit-points.component.html',
	styleUrls: ['./spirit-points.component.scss'],
})
export class SpiritPointsComponent implements OnInit {
	constructor(private db: AngularFirestore) {}

	rank1: sp;
	rank2: sp;
	rank3: sp;

	rankers: sp[];

	ngOnInit(): void {
		this.db
			.collection('SpiritPoints')
			.valueChanges()
			.subscribe((result) => {
				(result as sp[]).sort((a: sp, b: sp) =>
					a.points > b.points ? -1 : b.points > a.points ? 1 : 0
				);
				this.rank1 = result[0] as sp;
				this.rank2 = result[1] as sp;
				this.rank3 = result[2] as sp;
				this.rankers = result as sp[];
			});
	}

	getRankSuffix(rank: number): string {
		if (rank % 10 == 1 && rank % 100 != 11) return 'st';
		if (rank % 10 == 2 && rank % 100 != 12) return 'nd';
		if (rank % 10 == 3 && rank % 100 != 13) return 'rd';
		return 'th';
	}
}
