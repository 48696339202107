import { Component, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	Validators,
	ReactiveFormsModule,
	FormsModule,
} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import firebase from 'firebase/app';
import { eaf } from 'src/app/shared/sgTypes';

@Component({
	selector: 'app-eaf',
	templateUrl: './eaf.component.html',
	styleUrls: ['./eaf.component.scss'],
})
export class EAFComponent implements OnInit {
	route = 'eaf';
	step = 1;
	submitted: any;
	errorMessage: any;

	multistep = new FormGroup({
		requesterDetails: new FormGroup({
			rname: new FormControl('', Validators.required),
			rcontact: new FormControl('', Validators.required),
			remail: new FormControl('', [
				Validators.email,
				Validators.required,
			]),
			alternateName: new FormControl('', Validators.required),
			alternateContact: new FormControl('', Validators.required),
			alternateEmail: new FormControl('', [
				Validators.email,
				Validators.required,
			]),
			clubOrganization: new FormControl('', Validators.required),
		}),

		eventDetails: new FormGroup({
			eventName: new FormControl('', Validators.required),
			eventLocation: new FormControl('', Validators.required),
			eventDate: new FormControl('', Validators.required),
			eventStartTime: new FormControl('', Validators.required),
			eventEndTime: new FormControl('', Validators.required),
			eventDescription: new FormControl('', Validators.required),
			eventSpeaker: new FormControl('', Validators.required),
		}),

		budgetDetails: new FormGroup({
			budgetRequested: new FormControl('', Validators.required),
			budgetDetail: new FormControl('', [
				Validators.required,
				Validators.pattern(
					'(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
				),
			]),
			budgetCollectionDate: new FormControl('', Validators.required),
		}),

		facilitiesDetails: new FormGroup({
			tables: new FormControl('', Validators.required),
			chairs: new FormControl('', Validators.required),
			others: new FormControl('', Validators.required),
		}),

		speakerDetails: new FormGroup({
			speakerName: new FormControl('', Validators.required),
			speakerPosition: new FormControl('', Validators.required),
			speakerContact: new FormControl('', Validators.required),
			speakerCompany: new FormControl('', Validators.required),
			speakerEmail: new FormControl('', [
				Validators.email,
				Validators.required,
			]),
		}),

		mediaDetails: new FormGroup({
			mediaEmailContent: new FormControl('', Validators.required),
			mediaSgPoster: new FormControl('', Validators.required),
			mediaAdvertising: new FormControl('', Validators.required),
			mediaRights: new FormControl('', Validators.required),
		}),
	});

	minEventStartDate: string;

	clubs = [
		'Student Government',
		'Finance Club',
		'ASME Student Faction',
		'Gaming Club',
		'TEDxRIT',
		'Lend a Hand',
		'The Anime Club',
		'Marketing Club',
		'Music Club',
		'Chess Club',
		'DEDSEC',
		'Arabic Cultural Club',
		'Action Puzzle Club',
		'Levant Cultural Club',
		'RIT Realtors',
		'The Powerlifting Club',
		'The Industrials',
		'Yacht Club De Monaco',
		'Emirati Club',
		'Desi Club',
		'Film Appreciation Club',
		'RIT Realtors',
		'Entrepeneurs Club',
		'Art Club',
		'Debate Club',
		'Graphic Design Club',
		'RITs Active Minds',
	];

	constructor(private db: AngularFirestore, public auth: AuthService) {}

	ngOnInit(): void {
		const date = new Date();
		if (this.auth.userAuthRole == 'club') {
			date.setDate(date.getDate() + 21);
			this.minEventStartDate = date.toISOString().split('T')[0];
		} else {
			date.setDate(date.getDate());
			this.minEventStartDate = date.toISOString().split('T')[0];
		}
	}

	submit() {
		this.submitted = true;
		if (
			(this.multistep.controls.requesterDetails.invalid &&
				this.step == 1) ||
			(this.multistep.controls.eventDetails.invalid && this.step == 2) ||
			(this.multistep.controls.budgetDetails.invalid && this.step == 3) ||
			(this.multistep.controls.facilitiesDetails.invalid &&
				this.step == 4) ||
			(this.multistep.controls.speakerDetails.invalid &&
				this.step == 5) ||
			(this.multistep.controls.mediaDetails.invalid && this.step == 6)
		) {
			this.errorMessage = 'Please fill in all the required fields';
			return;
		}
		this.errorMessage = '';
		this.step++;
		if (
			this.step === 5 &&
			this.multistep.controls.eventDetails.value.eventSpeaker ==
				'speaker-no'
		) {
			this.multistep.controls.speakerDetails.value.speakerName = 'N/A';
			this.multistep.controls.speakerDetails.value.speakerPosition =
				'N/A';
			this.multistep.controls.speakerDetails.value.speakerContact = 'N/A';
			this.multistep.controls.speakerDetails.value.speakerCompany = 'N/A';
			this.multistep.controls.speakerDetails.value.speakerEmail = 'N/A';
			this.step++;
		}
	}

	get userDetails() {
		return this.multistep.controls['requesterDetails'];
	}

	previous() {
		this.step--;
		if (
			this.step === 5 &&
			this.multistep.controls.eventDetails.value.eventSpeaker ==
				'speaker-no'
		) {
			this.step--;
		}
		this.errorMessage = '';
	}

	getValue() {}

	// Pending
	get f() {
		return this.multistep.controls;
	}
	insertData() {
		this.db
			.collection('Approvals')
			.doc('EAF')
			.collection('Pending')
			.doc()
			.set({
				dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
				userSubmitted: this.auth.userAuthRole,
				userSubmittedEmail: this.auth.user.email,
				financeDirector: false,
				sgPresident: false,
				clubsDirector: false,
				studentAffairs: false,
				moneyReadyforCollection: false,
				requestorName:
					this.multistep.controls['requesterDetails'].value.rname,
				requestorContact:
					this.multistep.controls['requesterDetails'].value.rcontact,
				requestorEmail:
					this.multistep.controls['requesterDetails'].value.remail,
				alternateName:
					this.multistep.controls['requesterDetails'].value
						.alternateName,
				alternateContact:
					this.multistep.controls['requesterDetails'].value
						.alternateContact,
				alternateEmail:
					this.multistep.controls['requesterDetails'].value
						.alternateEmail,
				clubOrganization:
					this.multistep.controls['requesterDetails'].value
						.clubOrganization,
				eventName:
					this.multistep.controls['eventDetails'].value.eventName,
				eventLocation:
					this.multistep.controls['eventDetails'].value.eventLocation,
				eventDate:
					this.multistep.controls['eventDetails'].value.eventDate,
				eventStartTime:
					this.multistep.controls['eventDetails'].value
						.eventStartTime,
				eventEndTime:
					this.multistep.controls['eventDetails'].value.eventEndTime,
				eventDescription:
					this.multistep.controls['eventDetails'].value
						.eventDescription,
				eventSpeaker:
					this.multistep.controls['eventDetails'].value.eventSpeaker,
				budgetRequest:
					this.multistep.controls['budgetDetails'].value
						.budgetRequested,
				budgetDetail:
					this.multistep.controls['budgetDetails'].value.budgetDetail,
				budgetCollectionDate:
					this.multistep.controls['budgetDetails'].value
						.budgetCollectionDate,
				tables: this.multistep.controls['facilitiesDetails'].value
					.tables,
				chairs: this.multistep.controls['facilitiesDetails'].value
					.chairs,
				others: this.multistep.controls['facilitiesDetails'].value
					.others,
				speakerName:
					this.multistep.controls['speakerDetails'].value.speakerName,
				speakerPosition:
					this.multistep.controls['speakerDetails'].value
						.speakerPosition,
				speakerContact:
					this.multistep.controls['speakerDetails'].value
						.speakerContact,
				speakerCompany:
					this.multistep.controls['speakerDetails'].value
						.speakerCompany,
				speakerEmail:
					this.multistep.controls['speakerDetails'].value
						.speakerEmail,
				mediaEmailContent:
					this.multistep.controls['mediaDetails'].value
						.mediaEmailContent,
				sgPoster:
					this.multistep.controls['mediaDetails'].value.mediaSgPoster,
				mediaAdvertising:
					this.multistep.controls['mediaDetails'].value
						.mediaAdvertising,
				mediaRights:
					this.multistep.controls['mediaDetails'].value.mediaRights,
			} as eaf);
	}
}
