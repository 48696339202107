import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { event } from 'src/app/shared/sgTypes';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  today: Date;
  latestEvents: event[];

  daysToSearch = 7;
  numOfEvents = 4;
  slideIndex = 1;

  @ViewChild('slides', { static: true }) slidesContainer: ElementRef;

  slides: Slide[] = [];

  private intervalId: number | undefined;

  constructor(
    private db: AngularFirestore,
    private datepipe: DatePipe,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    let fetchedEvents: event[] = [];
    let breakFor = false;
    this.today = this.convertTZ(new Date(), 'Asia/Dubai');

    for (let i = 0; i < this.daysToSearch; i++) {
      let dateOfEvent = this.getDate(new Date(this.today), i)
        .toISOString()
        .split('T')[0];

      this.db
        .collection('UpcomingEvents/' + dateOfEvent + '/Events')
        .ref.get()
        .then((results) => {
          if (!results.empty) {
            for (let doc of results.docs)
              fetchedEvents.push(doc.data() as event);
          }
          fetchedEvents = fetchedEvents.sort((a: event, b: event) => {
            let timeA = parseInt(a.time.replace(':', ''));
            let timeB = parseInt(b.time.replace(':', ''));
            return timeA - timeB;
          });

          fetchedEvents = fetchedEvents.sort((a: event, b: event) => {
            let timeA = new Date(a.date);
            let timeB = new Date(b.date);
            if (timeA < timeB) return -1;
            else if (timeA > timeB) return 1;
            else return 0;
          });

          if (fetchedEvents.length >= this.numOfEvents) {
            fetchedEvents = fetchedEvents.slice(0, this.numOfEvents);
            breakFor = true;
          }
        });
      if (breakFor) break;
    }
    this.latestEvents = fetchedEvents;
	this.startAutoSlideshow();
  }
	startAutoSlideshow() {
		this.intervalId = setInterval(() => {
			this.plusSlides(1); // Move to the next slide
		  }, 5000); // Change slide every 5000 milliseconds (5 seconds)
	}


  // Stop the automatic slideshow
  stopAutoSlideshow() {
    clearInterval(this.intervalId);
  }

  getDate(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  convertTZ(date: Date, tzString: string): Date {
    return new Date(date.toLocaleString('en-US', { timeZone: tzString }));
  }

  // lifecycle hook that is called after the component's view has been initialized
  ngAfterViewInit(): void {
    this.initSlideShow();
    this.showSlides(this.slideIndex);
  }

  // initializes the slideshow
  initSlideShow() {
    this.showSlides(this.slideIndex);
  }

  plusSlides(n: number) {
    this.showSlides((this.slideIndex += n));
  }

  currentSlide(n: number) {
    this.showSlides((this.slideIndex = n));
  }

  showSlides(n: number) {
    //console.log('showSlides called with n:', n);
    //console.log('Setting styles for slide index:', this.slideIndex);
    let i;
    if (!this.slidesContainer || !this.slidesContainer.nativeElement) {
      console.error('Slides container not found.');
      return;
    }
    let slides = Array.from(
      this.slidesContainer.nativeElement.querySelectorAll('.slides')
    );
    let dots = Array.from(
      this.slidesContainer.nativeElement.querySelectorAll('.dot')
    );
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    // console.log('Slides after hiding:', slides);
    // console.log('Dots after hiding:', dots);
    for (i = 0; i < slides.length; i++) {
      this.renderer.setStyle(slides[i], 'display', 'none');
    }
    for (i = 0; i < dots.length; i++) {
      this.renderer.removeClass(dots[i], 'active');
    }
    this.renderer.setStyle(
      slides[this.slideIndex - 1],
      'display',
      'block'
    );
    this.renderer.addClass(dots[this.slideIndex - 1], 'active');
  }
}

interface Slide {
  imageUrl: string;
  caption: string;
}
