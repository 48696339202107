<div class="admin-page">
	<app-side-panel></app-side-panel>
	<div class="admin-content">
		<div class="admin-header">
			<h1 class="admin-title">Approval Forms</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>

		<div class="admin-body">
			<div class="mainApprovalButtons">
				<button class="eventsButton" [ngClass]="{ active: selectedTab === 'events' }" (click)="loadEvents()">
					Events
				</button>
				<button class="postersButton" [ngClass]="{ active: selectedTab === 'posters' }" (click)="loadPosters()">
					Posters
				</button>
				<button class="postsButton" [ngClass]="{ active: selectedTab === 'posts' }" (click)="loadPosts()">
					Posts
				</button>
			</div>

			<hr class="divider" />

			<div class="approvalButtons">
				<button class="inProgress" [ngClass]="{
						active: selectedApproval === 'inProgress'
					}" (click)="loadInProgress()">
					In Progress
				</button>
				<button class="approved" [ngClass]="{
						active: selectedApproval === 'approved'
					}" (click)="loadApproved()">
					Approved
				</button>
				<button class="rejected" [ngClass]="{
						active: selectedApproval === 'rejected'
					}" (click)="loadRejected()">
					Rejected
				</button>
			</div>

			<!-- EAF EMPTY - APPROVED / REJECTED -->
			<div class="empty" *ngIf="
					selectedTab === 'events' &&
					(!eafDocs || eafDocs.length === 0) &&
					selectedApproval !== 'inProgress'
				">
				EMPTY
			</div>

			<!-- EAF APPROVED / REJECTED -->
			<div class="approvals eafApprovals" *ngIf="
					selectedTab === 'events' &&
					selectedApproval !== 'inProgress'
				">
				<div class="approval" *ngFor="let eafDoc of eafDocs" (click)="openEAF(eafDoc)">
					<h1 class="eventType">
						{{ eafDoc.type }}
						<span class="eventStatus approvedStatus" *ngIf="selectedApproval === 'approved'">APPROVED</span>
						<span class="eventStatus rejectedStatus" *ngIf="selectedApproval === 'rejected'">REJECTED</span>
					</h1>
					<p class="dateCreated">
						{{
							eafDoc.createdOn.getDate() +
								"/" +
								eafDoc.createdOn.getMonth() +
								"/" +
								eafDoc.createdOn.getFullYear() +
								" - " +
								eafDoc.createdOn.getHours() +
								":" +
								eafDoc.createdOn.getMinutes()
						}}
					</p>
					<p class="clubName">{{ eafDoc.data.clubOrganization }}</p>
					<p class="eventName">
						{{ eafDoc.data.eventName }}
					</p>
					<p class="eventTiming">
						Event on
						<span class="eventDate">{{
							eafDoc.data.eventDate
						}}</span>
					</p>
					<div class="progress">
						<div class="bar clubsDirector" [ngStyle]="{
								display:
									eafDoc.data.clubOrganization !==
									'Student Government'
										? 'block'
										: 'none'
							}" [ngClass]="{
								barWaiting:
									!eafDoc.data.clubsDirector &&
									selectedApproval !== 'rejected',
								barRejected:
									!eafDoc.data.clubsDirector &&
									selectedApproval === 'rejected',
								barSuccess: eafDoc.data.clubsDirector
							}"></div>
						<!-- <div class="bar clubsDirector" [ngStyle]="{
								display:
									eafDoc.data.clubOrganization !==
									'Student Government'
										? 'block'
										: 'none'
							}" [ngClass]="{
								barWaiting:
									!eafDoc.data.sgPresident &&
									selectedApproval !== 'rejected',
								barRejected:
									!eafDoc.data.clubsDirector &&
									selectedApproval === 'rejected',
								barSuccess: eafDoc.data.clubsDirector
							}"></div> -->
						<div class="bar studentAffairs" [ngClass]="{
								barWaiting:
									eafDoc.data.clubsDirector &&
									selectedApproval !== 'rejected',
								barRejected:
									!eafDoc.data.studentAffairs &&
									eafDoc.data.clubsDirector &&
									selectedApproval === 'rejected',
								barSuccess: eafDoc.data.studentAffairs
							}"></div>
						<div class="bar finance" [ngClass]="{
								barWaiting:
									eafDoc.data.studentAffairs &&
									selectedApproval !== 'rejected',
								barRejected:
									!eafDoc.data.financeDirector &&
									eafDoc.data.studentAffairs &&
									selectedApproval === 'rejected',
								barSuccess: eafDoc.data.financeDirector
							}"></div>
					</div>
				</div>
			</div>
			<!-- EAF EMPTY - IN-PROGRESS -->
			<div class="empty" *ngIf="
					selectedTab === 'events' &&
					(!auth.eafDocs || auth.eafDocs.length === 0) &&
					selectedApproval === 'inProgress'
				">
				EMPTY
			</div>

			<!-- EAF IN-PROGRESS -->
			<div class="approvals eafApprovals" *ngIf="
					selectedTab === 'events' &&
					selectedApproval === 'inProgress'
				">
				<div class="approval" *ngFor="let eafDoc of auth.eafDocs" (click)="openEAF(eafDoc)">
					<h1 class="eventType">
						{{ eafDoc.type }}
						<span class="eventStatus">PENDING</span>
					</h1>
					<p class="dateCreated">
						{{
							eafDoc.createdOn.getDate() +
								"/" +
								eafDoc.createdOn.getMonth() +
								"/" +
								eafDoc.createdOn.getFullYear() +
								" - " +
								eafDoc.createdOn.getHours() +
								":" +
								eafDoc.createdOn.getMinutes()
						}}
					</p>
					<p class="clubName">{{ eafDoc.data.clubOrganization }}</p>
					<p class="eventName">
						{{ eafDoc.data.eventName }}
					</p>
					<p class="eventTiming">
						Event on
						<span class="eventDate">{{
							eafDoc.data.eventDate
						}}</span>
					</p>
					<div class="progress">
						<div class="bar clubsDirector" [ngStyle]="{
								display:
									eafDoc.data.clubOrganization !==
									'Student Government'
										? 'block'
										: 'none'
							}" [ngClass]="{
								barWaiting: !eafDoc.data.clubsDirector,
								barSuccess: eafDoc.data.clubsDirector
							}"></div>
						<!-- <div class="bar clubsDirector" [ngStyle]="{
								display:
									eafDoc.data.clubOrganization !==
									'Student Government'
										? 'block'
										: 'none'
							}" [ngClass]="{
								barWaiting: !eafDoc.data.sgPresident,
								barSuccess: eafDoc.data.clubsDirector
							}"></div> -->
						<div class="bar studentAffairs" [ngClass]="{
								barWaiting: eafDoc.data.clubsDirector,
								barSuccess: eafDoc.data.studentAffairs
							}"></div>
						<div class="bar finance" [ngClass]="{
								barWaiting: eafDoc.data.studentAffairs,
								barSuccess: eafDoc.data.financeDirector
							}"></div>
					</div>
				</div>
			</div>

			<!-- Posters EMPTY - APPROVED / REJECTED -->
			<div class="empty" *ngIf="
					selectedTab === 'posters' &&
					(!posterDocs || posterDocs.length === 0) &&
					selectedApproval !== 'inProgress'
				">
				EMPTY
			</div>

			<!-- Posts EMPTY - APPROVED / REJECTED -->
			<div class="empty" *ngIf="
					selectedTab === 'posts' &&
					(!postDocs || postDocs.length === 0) &&
					selectedApproval !== 'inProgress'
				">
				EMPTY
			</div>

			<!-- Posters APPROVED / REJECTED -->
			<div class="approvals posterApprovals" *ngIf="
					selectedTab === 'posters' &&
					selectedApproval !== 'inProgress'
				">
				<div class="approval" *ngFor="let posterDoc of posterDocs" (click)="openPoster(posterDoc)">
					<h1 class="eventType">
						{{ posterDoc.type }}
						<span class="eventStatus approvedStatus" *ngIf="selectedApproval === 'approved'">APPROVED</span>
						<span class="eventStatus rejectedStatus" *ngIf="selectedApproval === 'rejected'">REJECTED</span>
					</h1>
					<p class="dateCreated">
						{{
							posterDoc.createdOn.getDate() +
								"/" +
								posterDoc.createdOn.getMonth() +
								"/" +
								posterDoc.createdOn.getFullYear() +
								" - " +
								posterDoc.createdOn.getHours() +
								":" +
								posterDoc.createdOn.getMinutes()
						}}
					</p>
					<p class="eventName">{{ posterDoc.data.posterName }}</p>
					<p class="eventTiming">
						Event on
						<span class="eventDate">{{
							posterDoc.data.posterEventDate
						}}</span>
					</p>
					<div class="progress">
						<div class="bar clubsDirector" [ngClass]="{
								barWaiting: !posterDoc.data.clubsDirector,
								barRejected:
									!posterDoc.data.clubsDirector &&
									selectedApproval === 'rejected',
								barSuccess: posterDoc.data.clubsDirector
							}"></div>
						<div class="bar studentAffairs" [ngClass]="{
								barWaiting: posterDoc.data.clubsDirector,
								barRejected:
									!posterDoc.data.studentAffairs &&
									posterDoc.data.clubsDirector &&
									selectedApproval === 'rejected',
								barSuccess: posterDoc.data.studentAffairs
							}"></div>
					</div>
				</div>
			</div>

			<!-- Posts APPROVED / REJECTED -->
			<div class="approvals posterApprovals" *ngIf="
					selectedTab === 'posts' && selectedApproval !== 'inProgress'
				">
				<div class="approval" *ngFor="let postDoc of postDocs" (click)="openPost(postDoc)">
					<h1 class="eventType">
						{{ postDoc.type }}
						<span class="eventStatus approvedStatus" *ngIf="selectedApproval === 'approved'">APPROVED</span>
						<span class="eventStatus rejectedStatus" *ngIf="selectedApproval === 'rejected'">REJECTED</span>
					</h1>
					<p class="dateCreated">
						{{
							postDoc.createdOn.getDate() +
								"/" +
								postDoc.createdOn.getMonth() +
								"/" +
								postDoc.createdOn.getFullYear() +
								" - " +
								postDoc.createdOn.getHours() +
								":" +
								postDoc.createdOn.getMinutes()
						}}
					</p>
					<p class="eventName">{{ postDoc.data.postAccount }}</p>

					<div class="progress">
						<div class="bar clubsDirector" [ngClass]="{
								barWaiting: !postDoc.data.clubsDirector,
								barRejected:
									!postDoc.data.clubsDirector &&
									selectedApproval === 'rejected',
								barSuccess: postDoc.data.clubsDirector
							}"></div>
						<div class="bar studentAffairs" [ngClass]="{
								barWaiting: postDoc.data.clubsDirector,
								barRejected:
									!postDoc.data.studentAffairs &&
									postDoc.data.clubsDirector &&
									selectedApproval === 'rejected',
								barSuccess: postDoc.data.studentAffairs
							}"></div>
					</div>
				</div>
			</div>

			<!-- Posters EMPTY - IN-PROGRESS -->
			<div class="empty" *ngIf="
					selectedTab === 'posters' &&
					(!auth.posterDocs || auth.posterDocs.length === 0) &&
					selectedApproval === 'inProgress'
				">
				EMPTY
			</div>

			<div class="empty" *ngIf="
					selectedTab === 'posts' &&
					(!auth.postDocs || auth.postDocs.length === 0) &&
					selectedApproval === 'inProgress'
				">
				EMPTY
			</div>

			<!-- Posters IN-PROGRESS -->
			<div class="approvals posterApprovals" *ngIf="
					selectedTab === 'posters' &&
					selectedApproval === 'inProgress'
				">
				<div class="approval" *ngFor="let posterDoc of auth.posterDocs" (click)="openPoster(posterDoc)">
					<h1 class="eventType">
						{{ posterDoc.type }}
						<span class="eventStatus">PENDING</span>
					</h1>
					<p class="dateCreated">
						{{
							posterDoc.createdOn.getDate() +
								"/" +
								posterDoc.createdOn.getMonth() +
								"/" +
								posterDoc.createdOn.getFullYear() +
								" - " +
								posterDoc.createdOn.getHours() +
								":" +
								posterDoc.createdOn.getMinutes()
						}}
					</p>
					<p class="eventName">{{ posterDoc.data.posterName }}</p>
					<p class="eventTiming">
						Event on
						<span class="eventDate">{{
							posterDoc.data.posterEventDate
						}}</span>
					</p>
					<div class="progress">
						<div class="bar clubsDirector" [ngClass]="{
								barWaiting: !posterDoc.data.clubsDirector,
								barSuccess: posterDoc.data.clubsDirector
							}"></div>
						<div class="bar studentAffairs" [ngClass]="{
								barWaiting: posterDoc.data.clubsDirector,
								barSuccess: posterDoc.data.studentAffairs
							}"></div>
					</div>
				</div>
			</div>

			<div class="approvals posterApprovals" *ngIf="
					selectedTab === 'posts' && selectedApproval === 'inProgress'
				">
				<div class="approval" *ngFor="let postDoc of auth.postDocs" (click)="openPost(postDoc)">
					<h1 class="eventType">
						{{ postDoc.type }}
						<span class="eventStatus">PENDING</span>
					</h1>
					<p class="dateCreated">
						{{
							postDoc.createdOn.getDate() +
								"/" +
								postDoc.createdOn.getMonth() +
								"/" +
								postDoc.createdOn.getFullYear() +
								" - " +
								postDoc.createdOn.getHours() +
								":" +
								postDoc.createdOn.getMinutes()
						}}
					</p>
					<p class="eventName">{{ postDoc.data.postAccount }}</p>
					<div class="progress">
						<div class="bar clubsDirector" [ngClass]="{
								barWaiting: !postDoc.data.clubsDirector,
								barSuccess: postDoc.data.clubsDirector
							}"></div>
						<div class="bar studentAffairs" [ngClass]="{
								barWaiting: postDoc.data.clubsDirector,
								barSuccess: postDoc.data.studentAffairs
							}"></div>
					</div>
				</div>
			</div>

			<!-- EAF MODAL -->
			<div class="modal" *ngIf="showEAFDoc">
				<div class="modal-container">
					<h1>{{ auth.currentEAFDoc.type }}</h1>
					<button class="modal-close" (click)="closeEAF()">X</button>
					<div class="modal-container-padded">
						<div class="modal-group" *ngFor="let group of sortedDoc">
							<h2 class="modal-group-title">{{ group.title }}</h2>
							<div class="modal-fields">
								<div class="field" *ngFor="let field of group.fields">
									<p class="field-name">{{ field.name }}</p>
									<p class="field-value">{{ field.value }}</p>
								</div>
							</div>
						</div>
						<div class="approve" [ngStyle]="{
								display:
									(auth.currentEAFDoc.data
										.clubOrganization ===
										'Student Government' &&
										auth.userAuthRole === 'sgPresident') ||
									(auth.currentEAFDoc.data
										.clubOrganization !==
										'Student Government' &&
										auth.userAuthRole ===
											'clubsDirector') ||
									(auth.userAuthRole !== 'clubsDirector' &&
										auth.userAuthRole !== 'sgPresident')
										? 'block'
										: 'none'
							}"></div>
					</div>
				</div>
			</div>

			<!-- Poster MODAL -->
			<div class="modal" *ngIf="showPosterDoc">
				<div class="modal-container">
					<h1>{{ auth.currentPosterDoc.type }}</h1>
					<button class="modal-close" (click)="closePoster()">
						X
					</button>
					<div class="modal-container-padded">
						<div class="modal-group" *ngFor="let group of sortedDoc">
							<h2 class="modal-group-title">{{ group.title }}</h2>
							<div class="modal-fields">
								<div class="field" *ngFor="let field of group.fields">
									<p class="field-name">{{ field.name }}</p>
									<p class="field-value">{{ field.value }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Post Modal -->
			<div class="modal" *ngIf="showPostDoc">
				<div class="modal-container">
					<h1>{{ auth.currentPostDoc.type }}</h1>
					<button class="modal-close" (click)="closePost()">X</button>
					<div class="modal-container-padded">
						<div class="modal-group" *ngFor="let group of sortedDoc">
							<h2 class="modal-group-title">{{ group.title }}</h2>
							<div class="modal-fields">
								<div class="field" *ngFor="let field of group.fields">
									<p class="field-name">{{ field.name }}</p>
									<p class="field-value">{{ field.value }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
