import { Component, OnInit } from '@angular/core';
import teams from './sgTeams.json';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
	ngOnInit(): void {
		// I hate empty teams
		this.sgTeams = teams.filter((team) => team.members.length >= 1);
	}

	sgTeams: {
		team: string;
		members: {
			name: string;
			title: string;
			email: string;
			img: string;
		}[];
	}[];
}
