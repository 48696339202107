<div class="admin-page">
	<app-side-panel></app-side-panel>
	<div class="admin-content">
		<div class="admin-header">
			<h1 class="admin-title">Monthly Evaluation Form</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>
		<div class="admin-body">
			<!--[formGroup]-->
			<form [formGroup]="memberEval">
				<!--formgroupname-->
				<div class="eval">
					<label>Evaluation date</label>
					<div class="field">
						<input
							type="date"
							name="evalDate"
							formControlName="evalDate"
						/>
					</div>
					<br />
					<label>Month of Evaluation</label>
					<div class="field">
						<input
							type="month"
							name="evalMonth"
							formControlName="evalMonth"
						/>
					</div>
					<br />
					<label>Name of member being evaluated</label>
					<div class="field">
						<select formControlName="email" name="pTitle">
							<option
								*ngFor="let user of auth.users"
								value="{{ user.email }}"
							>
								{{ user.name }}
							</option>
						</select>
					</div>

					<br />
					<br />
					<div class="num">
						<label for="attendance"
							><b>ATTENDANCE-</b>
							<i>How is the member’s attendance?</i></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							maxlength="2"
							name="attendance"
							id="attendance"
							formControlName="attendance"
						/>
					</div>
					<br />
					<div class="num">
						<label for="focus"
							><b>FOCUS-</b>
							<i>Is the member effective in the team?</i></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							name="focus"
							id="focus"
							formControlName="focus"
						/>
					</div>
					<br />
					<div class="num">
						<label for="prod"
							><b>PRODUCTIVITY / DEADLINES-</b>
							<i
								>Is the member able to consistently meet
								productivity requirements and project
								deadlines?</i
							></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							name="prod"
							id="prod"
							formControlName="productivity"
						/>
					</div>
					<br />
					<div class="num">
						<label for="stress"
							><b>STRESS MANAGEMENT-</b>
							<i
								>How does the member deal with changes in the
								work environment? Is he or she able to sift
								through the "noise" and focus on breaking down
								the task at hand in order to complete it on
								time? How does the member interact with other
								members of the department when tensions are
								high?</i
							></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							name="stress"
							id="stress"
							formControlName="stress"
						/>
					</div>
					<br />
					<div class="num">
						<label for="teamwork"
							><b>TEAMWORK / PITCHING IN-</b>
							<i
								>If the department is short-handed, does the
								member willingly pitch in to finish tasks
								assigned to others in the department as
								appropriate? Does the member volunteer to
								assist?</i
							></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							name="teamwork"
							id="teamwork"
							formControlName="teamwork"
						/>
					</div>
					<br />
					<div class="num">
						<label for="coop"
							><b>ATTITUDE / COOPERATION-</b>
							<i
								>What is the member’s attitude towards you,
								towards colleagues, towards the work in general?
								Is he or she a pleasure or a chore to work with?
								Is the member reasonably flexible when asked to
								perform a job function outside his or her normal
								duties, or to work outside his or her normal
								hours for a special project?</i
							></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							name="coop"
							id="coop"
							formControlName="coop"
						/>
					</div>
					<br />
					<div class="num">
						<label for="integrity"
							><b>INTEGRITY-</b>
							<i
								>Does the member demonstrate ethical behavior in
								the university? Does he or she respect the
								opinions of the other members?</i
							></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							name="integrity"
							id="integrity"
							formControlName="integrity"
						/>
					</div>
					<br />
					<div class="num">
						<label for="comm"
							><b>COMMUNICATION SKILLS-</b>
							<i
								>Does the member have the ability to adequately
								communicate with colleagues, staff/faculty, and
								students? Have there been any issues created, or
								solved, due to the member’s communication
								skills?</i
							></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							name="comm"
							id="comm"
							formControlName="communication"
						/>
					</div>
					<br />
					<div class="num">
						<label for="sg"
							><b>UNIVERSITY AND SG ORIENTED-</b>
							<i
								>Does the member have a broader view and deeper
								understanding than simply his or her own duties?
								Does he or she speak of the SG or the university
								with pride?</i
							></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							name="sg"
							id="sg"
							formControlName="sgUnderstanding"
						/>
					</div>
					<br />
					<div class="num">
						<label for="improvement"
							><b>IMPROVEMENT FROM PREVIOUS EVALUATION-</b>
							<i
								>Has the member demonstrated marked improvements
								from the previous performance evaluation?</i
							></label
						>
						<input
							type="number"
							style="position: relative; float: right"
							max="10"
							min="0"
							name="improvement"
							id="improvement"
							formControlName="improvement"
						/>
					</div>
					<br />
					<p>Provide feedback on the member you are rating:</p>
					<textarea
						id="comms"
						name="comms"
						formControlName="memberFeedback"
						rows="5"
						cols="90"
					></textarea>
					<br />
					<button
						class="uploadbtn"
						type="submit"
						(click)="calcTotal(); sendData()"
					>
						Upload
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
