<div class="admin-page">
	<app-side-panel></app-side-panel>
	<div class="admin-content">
		<div class="admin-header">
			<h1 class="admin-title">Dashboard</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>

		<div class="admin-body">
			<div class="eafWrapper" *ngIf="auth.isEventApprovalRole()">
				<h2>Pending Event Approvals</h2>
				<div class="empty" *ngIf="
						!auth.pendingEafDocs || auth.pendingEafDocs.length === 0
					">
					EMPTY
				</div>

				<div class="pendingApprovals eafPendingApprovals">
					<div class="approval" *ngFor="let eafDoc of auth.pendingEafDocs" (click)="openEAF(eafDoc)">
						<h1 class="eventType">
							{{ eafDoc.type }}
							<span class="eventStatus">PENDING</span>
						</h1>
						<p class="dateCreated">
							{{
								eafDoc.createdOn.getDate() +
									"/" +
									eafDoc.createdOn.getMonth() +
									"/" +
									eafDoc.createdOn.getFullYear() +
									" - " +
									eafDoc.createdOn.getHours() +
									":" +
									eafDoc.createdOn.getMinutes()
							}}
						</p>
						<p class="clubName">
							{{ eafDoc.data.clubOrganization }}
						</p>
						<p class="eventName">
							{{ eafDoc.data.eventName }}
						</p>
						<p class="eventTiming">
							Event on
							<span class="eventDate">{{
								eafDoc.data.eventDate
							}}</span>
						</p>
						<div class="progress">

							<div class="bar clubsDirector" [ngStyle]="{
									display:
										eafDoc.data.clubOrganization !==
										'Student Government'
											? 'block'
											: 'none'
								}" [ngClass]="{
									barWaiting: !eafDoc.data.clubsDirector,
									barSuccess: eafDoc.data.clubsDirector
								}"></div>
							<div class="bar studentAffairs" [ngClass]="{
									barWaiting: eafDoc.data.clubsDirector,
									barSuccess: eafDoc.data.studentAffairs
								}"></div>
							<div class="bar finance" [ngClass]="{
									barWaiting: eafDoc.data.studentAffairs,
									barSuccess: eafDoc.data.financeDirector
								}"></div>
						</div>
					</div>
				</div>
			</div>

			<div class="posterWrapper" *ngIf="auth.isPosterApprovalRole()">
				<h2>Pending Post Approvals</h2>
				<div class="empty" *ngIf="
						!auth.pendingPostDocs ||
						auth.pendingPostDocs.length === 0
					">
					EMPTY
				</div>

				<div class="pendingApprovals posterPendingApprovals">
					<div class="approval" *ngFor="let postDoc of auth.pendingPostDocs" (click)="openPost(postDoc)">
						<h1 class="eventType">
							{{ postDoc.type }}

							<span class="eventStatus">PENDING</span>
						</h1>
						<p class="dateCreated">
							{{
								postDoc.createdOn.getDate() +
									"/" +
									postDoc.createdOn.getMonth() +
									"/" +
									postDoc.createdOn.getFullYear() +
									" - " +
									postDoc.createdOn.getHours() +
									":" +
									postDoc.createdOn.getMinutes()
							}}
						</p>
						<p class="eventName">{{ postDoc.data.postAccount }}</p>
						<div class="progress">
							<div class="bar clubsDirector" [ngClass]="{
									barWaiting: !postDoc.data.clubsDirector,
									barSuccess: postDoc.data.clubsDirector
								}"></div>
							<div class="bar studentAffairs" [ngClass]="{
									barWaiting: postDoc.data.clubsDirector,
									barSuccess: postDoc.data.studentAffairs
								}"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="posterWrapper" *ngIf="auth.isPosterApprovalRole()">
				<h2>Pending Poster Approvals</h2>
				<div class="empty" *ngIf="
						!auth.pendingPosterDocs ||
						auth.pendingPosterDocs.length === 0
					">
					EMPTY
				</div>

				<div class="pendingApprovals posterPendingApprovals">
					<div class="approval" *ngFor="let posterDoc of auth.pendingPosterDocs"
						(click)="openPoster(posterDoc)">
						<h1 class="eventType">
							{{ posterDoc.type }}
							<span class="eventStatus">PENDING</span>
						</h1>
						<p class="dateCreated">
							{{
								posterDoc.createdOn.getDate() +
									"/" +
									posterDoc.createdOn.getMonth() +
									"/" +
									posterDoc.createdOn.getFullYear() +
									" - " +
									posterDoc.createdOn.getHours() +
									":" +
									posterDoc.createdOn.getMinutes()
							}}
						</p>
						<p class="eventName">{{ posterDoc.data.posterName }}</p>
						<p class="eventTiming">
							Event on
							<span class="eventDate">{{
								posterDoc.data.posterEventDate
							}}</span>
						</p>
						<div class="progress">
							<div class="bar clubsDirector" [ngClass]="{
									barWaiting: !posterDoc.data.clubsDirector,
									barSuccess: posterDoc.data.clubsDirector
								}"></div>
							<div class="bar studentAffairs" [ngClass]="{
									barWaiting: posterDoc.data.clubsDirector,
									barSuccess: posterDoc.data.studentAffairs
								}"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" *ngIf="showEAFDoc">
			<div class="modal-container">
				<h1>{{ auth.currentEAFDoc.type }}</h1>
				<button class="modal-close" (click)="closeEAF()">X</button>
				<div class="modal-container-padded">
					<div class="modal-group" *ngFor="let group of sortedDoc">
						<h2 class="modal-group-title">{{ group.title }}</h2>
						<div class="modal-fields">
							<div class="field" *ngFor="let field of group.fields">
								<p class="field-name">{{ field.name }}</p>
								<p class="field-value">{{ field.value }}</p>
							</div>
						</div>
					</div>

					<div class="approve" [ngStyle]="{
							display:
								(auth.currentEAFDoc.data.clubOrganization ===
									'Student Government' &&
									auth.userAuthRole === 'sgPresident') ||
								(auth.currentEAFDoc.data.clubOrganization !==
									'Student Government' &&
									auth.userAuthRole === 'clubsDirector') ||
								(auth.userAuthRole !== 'clubsDirector' &&
									auth.userAuthRole !== 'sgPresident')
									? 'block'
									: 'none'
						}">
						<button class="approveButton" (click)="approveEAF()">
							Approve as {{ auth.userRole }}
						</button>
						<button class="rejectButton" (click)="rejectEAF()">
							Reject as {{ auth.userRole }}
						</button>
						<app-add-to-calendar [startDate]="auth.currentEAFDoc.data.eventDate"
							[endDate]="auth.currentEAFDoc.data.eventDate"
							[startTime]="auth.currentEAFDoc.data.eventStartTime"
							[endTime]="auth.currentEAFDoc.data.eventEndTime" [title]="auth.currentEAFDoc.data.eventName"
							[details]="auth.currentEAFDoc.data.eventDescription"
							[location]="auth.currentEAFDoc.data.eventLocation"></app-add-to-calendar>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" *ngIf="showPostDoc">
			<div class="modal-container">
				<h1>{{ auth.currentPostDoc.type }}</h1>
				<button class="modal-close" (click)="closePost()">X</button>
				<div class="modal-container-padded">
					<div class="modal-group" *ngFor="let group of sortedDoc">
						<h2 class="modal-group-title">{{ group.title }}</h2>
						<div class="modal-fields">
							<div class="field" *ngFor="let field of group.fields">
								<p class="field-name">{{ field.name }}</p>
								<p class="field-value">{{ field.value }}</p>
							</div>
						</div>
						<p class="field-name">Comments for the post</p>
						<textarea id="posterComment" name="posterComment" rows="4" cols="100" style="margin-bottom: 5em"
							placeholder="Reason for Rejection" [(ngModel)]="rejectionComment">
						</textarea>
					</div>
					<div class="approve">
						<button class="approveButton" (click)="approvePost()">
							Approve as {{ auth.userRole }}
						</button>
						<button class="rejectButton" (click)="rejectPost()">
							Reject as {{ auth.userRole }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal" *ngIf="showPosterDoc">
			<div class="modal-container">
				<h1>{{ auth.currentPosterDoc.type }}</h1>
				<button class="modal-close" (click)="closePoster()">X</button>
				<div class="modal-container-padded">
					<div class="modal-group" *ngFor="let group of sortedDoc">
						<h2 class="modal-group-title">{{ group.title }}</h2>
						<div class="modal-fields">
							<div class="field" *ngFor="let field of group.fields">
								<p class="field-name">{{ field.name }}</p>
								<p class="field-value">{{ field.value }}</p>
							</div>
						</div>
						<p class="field-name">Comments for the poster</p>
						<textarea id="posterComment" name="posterComment" rows="4" cols="100" style="margin-bottom: 5em"
							placeholder="Reason for Rejection" [(ngModel)]="rejectionComment">
						</textarea>
					</div>
					<div class="approve">
						<button class="approveButton" (click)="approvePoster()">
							Approve as {{ auth.userRole }}
						</button>
						<button class="rejectButton" (click)="rejectPoster()">
							Reject as {{ auth.userRole }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <pre>{{ eafDocs | json }}</pre> -->
