// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
};

// export const CREDENTIALS = {
// 	type: 'service_account',
// 	project_id: 'events-calendar-sgdubai',
// 	private_key_id: '4c95efdc8f45079439f43f435154a1b1c26c86d8',
// 	private_key:
// 		'-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCnTLR9u7p7boBI\nK5h8kf/aDF1VOgb/ViY1Z9muePk0ZpAgYPiU9G5g9ML63LF3yPImz0xoDn7FORfP\n9q2yEyBzdIKtpgrCbPN+ruWd7sXZVrWCfamPsni1rf/mBZ6QSQjNVHX3truN/5X8\nJG0wo8TWgMYUwGGfAC722NIY/eDcZqOqdI45RerdCy27VsOp7gS7H0xiXKR9wVPL\n6epNdgOGY9YbC73MoEPLkgp8kv4tlmWf4dmBIlJwWs0IFbunlafHJNpG3hSINO6I\nbekIAS4VNkCroc3H0/Z0ZijS+EC+ExtzWEqwIyhtuHz1tFT6XYspXQfHKnj00yi4\nDbW8B3NHAgMBAAECggEABIB0ZOO9z194VTjTGBsHovLtOgNfN9S2H37FZUP9FLNj\n0nM5jSyModBEROLWZa5DEddHK4+kSqNQKDjvkJiiKWkrsQeER5oN7U1AgYDw0vnZ\n1YKTIvo+9EiT17kz/JbyhS/uIrXIkzga7Zd/Vq05eToq2lkY52HmYZ8Q/DosqFGR\ngTF7qN7TksEMogfW5rPsb8KKXIVrcIJ795v5fsAor3J/bWaH0H9ZChJfQo8zrZK5\nnGe93C58+zKxzGD0zimGIuswHWIVhUaI3IcwuimtcaMv4Tg4Ewd7Yfn429p/TTgv\nriK1+LTTg7FV/y3DB7CtErYRE9HBFYPhRUMIRUTbtQKBgQDqmQGep51jr+utTtSH\nEzLMtfMakvso1s+lh1vZ0UrFCUWVhA8w/hKt5Tdzk7pZlZB7OfY5ZunBYlrvupTB\nUmuzOnc7u817vyHyWkaEarn0sxEsPRh5EPqS+DE8KCAhktyj1CmdMJdTtX61xGem\n90BUwL41WZv9aCf7PNSdIkt9wwKBgQC2j/Z6OUEbBBiM0u1hBPAJ+dpgQRPTMog4\nmOed2AlbbxVFLWJs3cWdPgwBsGHc4NZ+A001/1CRY/wXNpptgSyGYIAtct2oVqzp\n4VqG5ROaKc0t7ASbTSkGXgiHE5ovnRjeNRT3wzkN6Yf+t2UutWui77/gvksRLSed\nrIkT6VbILQKBgQDMpaDGGA2yMnpy+rtnAZZlAawKWIGEhK8rBMNZZbvF2mq7HTJr\nWJhO3g/bYNlE4LXi6RMjy+TKb9RIIAk4A4A0HyJ4F0IPWxJv6dRuGvQQxEyMvNcm\ndWtA3gWFJrOw1f5nY+LRXtwDh+X7BlQtUK7rwB+tRnhPPJb0EYKfeJVGJQKBgF0S\nKcMH21Qtk2J/yfrzLHi5gwYdR0Edeur3sOT8lVCi4gIhcBM2QJ+4WSCnTkzv5wnZ\nHwzPO8DTa1peQP5go1VibxY8gBppDcAyXhd1Q7gNZYfq0jJmdOrFF63dZXaurVXj\nU+w3/6dDDkziLFhSrywi8/xcpyJ7lb3BjySyicaFAoGBAL5zlZDEYcSQ9iNS1MNp\nMgbNum71OKABYSdW7zvdtIz3/Gx3t+sV2Cbk+s2Ipkbxrftxbs6PIAhz80VXCMia\nA8RuDetx6kH4gKvfnYUtFN2Au2+MrCH2pyAl+XB+t0x8HW7Tkf41cvL5xmaE/UCb\nQTiFObDxixlXU9ZoJLs81Bn+\n-----END PRIVATE KEY-----\n',
// 	client_email:
// 		'sg-events-dubai@events-calendar-sgdubai.iam.gserviceaccount.com',
// 	client_id: '112111504036846516286',
// 	auth_uri: 'https://accounts.google.com/o/oauth2/auth',
// 	token_uri: 'https://oauth2.googleapis.com/token',
// 	auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
// 	client_x509_cert_url:
// 		'https://www.googleapis.com/robot/v1/metadata/x509/sg-events-dubai%40events-calendar-sgdubai.iam.gserviceaccount.com',
// };
// export const Calendar_ID =
// 	'vt7re3cp03u7tuglkfv5t32gjg@group.calendar.google.com';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
