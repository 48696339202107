<div class="sidePanel">
	<div class="sidePanelWrapper">
		<div class="sidePanel-header">
			<img src="/assets/admin/sidepanel/sg-logo-single-white.svg" />
		</div>

		<div class="sidePanel-nav">
			<div class="sidePanel-icons">
				<div
					class="sidePanel-icon"
					[ngClass]="{ 'sidePanel-active': route === '/dashboard' }"
					routerLink="/dashboard"
					[ngStyle]="{
						display: auth.userRole === 'Club' ? 'none' : 'block'
					}"
				>
					<span class="sidePanel-tooltip">
						<p>dashboard</p>
					</span>
					<svg class="icon-dashboard">
						<use xlink:href="#icon-dashboard" />
					</svg>
				</div>

				<div
					class="sidePanel-icon"
					[ngClass]="{
						'sidePanel-active': route === '/approvalforms'
					}"
					routerLink="/approvalforms"
				>
					<span class="sidePanel-tooltip">
						<p>approval forms</p>
					</span>
					<svg class="icon-dashboard">
						<use xlink:href="#icon-approvals" />
					</svg>
				</div>

				<div
					class="sidePanel-icon"
					[ngClass]="{
						'sidePanel-active': route === '/posterapproval'
					}"
					routerLink="/posterapproval"
				>
					<span class="sidePanel-tooltip">
						<p>poster approval form</p>
					</span>
					<svg class="icon-addPoster">
						<use xlink:href="#icon-addPoster" />
					</svg>
				</div>
				<div
					class="sidePanel-icon"
					[ngClass]="{
						'sidePanel-active': route === '/postapproval'
					}"
					routerLink="/postapproval"
				>
					<span class="sidePanel-tooltip">
						<p>post approval form</p>
					</span>
					<svg class="icon-addPoster">
						<use xlink:href="#icon-socialmedia" />
					</svg>
				</div>
				<div
					class="sidePanel-icon"
					[ngClass]="{
						'sidePanel-active': route === '/memberevaluation'
					}"
					[ngStyle]="{
						display: auth.userRole === 'Club' ? 'none' : 'block'
					}"
					routerLink="/memberevaluation"
				>
					<span class="sidePanel-tooltip">
						<p>member evaluation</p>
					</span>
					<svg class="icon-addPoster">
						<use xlink:href="#icon-evaluations" />
					</svg>
				</div>

				<div
					class="sidePanel-icon"
					[ngClass]="{
						'sidePanel-active': route === '/viewEvals'
					}"
					[ngStyle]="{
						display:
							auth.userRole !== 'President' &&
							auth.userRole !== 'Vice President'
								? 'none'
								: 'block'
					}"
					routerLink="/viewEvals"
				>
					<span class="sidePanel-tooltip">
						<p>view member evaluation</p>
					</span>
					<svg class="icon-addPoster">
						<use xlink:href="#icon-viewevaluations" />
					</svg>
				</div>

				<div
					class="sidePanel-icon"
					routerLink="/EAF"
					[ngClass]="{ 'sidePanel-active': route === '/EAF' }"
				>
					<span class="sidePanel-tooltip">
						<p>event approval form</p>
					</span>
					<svg class="icon-eaf">
						<use xlink:href="#icon-addEvents" />
					</svg>
				</div>

				<div
					class="sidePanel-icon"
					routerLink="/addevents"
					[ngClass]="{ 'sidePanel-active': route === '/addevents' }"
					[ngStyle]="{
						display: auth.userRole === 'Club' ? 'none' : 'block'
					}"
				>
					<span class="sidePanel-tooltip">
						<p>add events</p>
					</span>
					<svg class="icon-addEvents">
						<use xlink:href="#icon-eaf" />
					</svg>
				</div>

				<div
					class="sidePanel-icon"
					routerLink="/assignroles"
					[ngClass]="{ 'sidePanel-active': route === '/assignroles' }"
					[ngStyle]="{
						display: auth.userRole === 'Club' ? 'none' : 'block'
					}"
				>
					<span class="sidePanel-tooltip">
						<p>assign roles</p>
					</span>
					<svg class="icon-assignRoles">
						<use xlink:href="#icon-assignRoles" />
					</svg>
				</div>

				<div
					class="sidePanel-icon"
					routerLink="/admincomplaints"
					[ngClass]="{
						'sidePanel-active': route === '/admincomplaints'
					}"
					[ngStyle]="{
						display: auth.userRole === 'Club' ? 'none' : 'block'
					}"
				>
					<span class="sidePanel-tooltip">
						<p>complaints</p>
					</span>
					<svg class="icon-assignRoles">
						<use xlink:href="#icon-feedback" />
					</svg>
				</div>
			</div>

			<div class="sidePanel-icon" (click)="logout()">
				<span class="sidePanel-tooltip">
					<p>logout</p>
				</span>
				<img
					class="icon-logout"
					src="/assets/admin/sidepanel/logout.svg"
				/>
			</div>
		</div>
	</div>
</div>

<svg display="none">
	<symbol width="100%" viewBox="0 0 50 50" id="icon-dashboard">
		<path
			d="M2.77778 27.7778H19.4444C20.1812 27.7778 20.8877 27.4851 21.4086 26.9642C21.9296 26.4432 22.2222 25.7367 22.2222 25V2.77778C22.2222 2.04107 21.9296 1.33453 21.4086 0.813592C20.8877 0.292658 20.1812 0 19.4444 0H2.77778C2.04107 0 1.33453 0.292658 0.813592 0.813592C0.292658 1.33453 0 2.04107 0 2.77778V25C0 25.7367 0.292658 26.4432 0.813592 26.9642C1.33453 27.4851 2.04107 27.7778 2.77778 27.7778ZM0 47.2222C0 47.9589 0.292658 48.6655 0.813592 49.1864C1.33453 49.7073 2.04107 50 2.77778 50H19.4444C20.1812 50 20.8877 49.7073 21.4086 49.1864C21.9296 48.6655 22.2222 47.9589 22.2222 47.2222V36.1111C22.2222 35.3744 21.9296 34.6679 21.4086 34.1469C20.8877 33.626 20.1812 33.3333 19.4444 33.3333H2.77778C2.04107 33.3333 1.33453 33.626 0.813592 34.1469C0.292658 34.6679 0 35.3744 0 36.1111V47.2222ZM27.7778 47.2222C27.7778 47.9589 28.0704 48.6655 28.5914 49.1864C29.1123 49.7073 29.8188 50 30.5556 50H47.2222C47.9589 50 48.6655 49.7073 49.1864 49.1864C49.7073 48.6655 50 47.9589 50 47.2222V27.7778C50 27.0411 49.7073 26.3345 49.1864 25.8136C48.6655 25.2927 47.9589 25 47.2222 25H30.5556C29.8188 25 29.1123 25.2927 28.5914 25.8136C28.0704 26.3345 27.7778 27.0411 27.7778 27.7778V47.2222ZM30.5556 19.4444H47.2222C47.9589 19.4444 48.6655 19.1518 49.1864 18.6309C49.7073 18.1099 50 17.4034 50 16.6667V2.77778C50 2.04107 49.7073 1.33453 49.1864 0.813592C48.6655 0.292658 47.9589 0 47.2222 0H30.5556C29.8188 0 29.1123 0.292658 28.5914 0.813592C28.0704 1.33453 27.7778 2.04107 27.7778 2.77778V16.6667C27.7778 17.4034 28.0704 18.1099 28.5914 18.6309C29.1123 19.1518 29.8188 19.4444 30.5556 19.4444Z"
		/>
	</symbol>

	<symbol width="100%" viewBox="0 0 24 24" id="icon-socialmedia">
		<path
			d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
		/>
	</symbol>

	<symbol
		width="100%"
		height="100%"
		viewBox="0 0 146.93 130.11"
		id="icon-addPoster"
	>
		<circle cx="55.38" cy="42.2" r="10.09" />
		<path
			d="M646.08,137.9a31.14,31.14,0,0,0-17.39-8.27V78.32a20.15,20.15,0,0,0-5.75-14,19.56,19.56,0,0,0-14-5.75H527.61a20.15,20.15,0,0,0-14,5.75,19.56,19.56,0,0,0-5.75,14V162a20.19,20.19,0,0,0,5.75,14,19.59,19.59,0,0,0,14,5.74h78.65a29.66,29.66,0,0,0,39.82-43.88Zm-50.9-36.45-31.26,31.4-21.17-21.31a3.77,3.77,0,0,0-5.33,0L515.27,134V78.32A12.43,12.43,0,0,1,527.61,66h81.31a12.43,12.43,0,0,1,12.34,12.34v43.74l-20.75-20.61A3.77,3.77,0,0,0,595.18,101.45Zm45.43,73.18a21.9,21.9,0,0,1-15.7,6.45,22.16,22.16,0,0,1-15.29-6.16c-.56-.57-1.12-1.27-1.68-1.83s-.84-1-1.26-1.4a13.29,13.29,0,0,1-1.4-2.38c-.28-.56-.56-1-.84-1.55a11.83,11.83,0,0,1-.7-2.38c-.14-.56-.42-1.26-.56-1.82a22.58,22.58,0,0,1,6-20.19,21.92,21.92,0,0,1,15.71-6.45,22.49,22.49,0,0,1,15.7,6.45,21.7,21.7,0,0,1,6.45,15.7A22.43,22.43,0,0,1,640.61,174.63Z"
			transform="translate(-507.84 -58.55)"
		/>
		<path
			d="M637.1,155.15h-8.55v-8.56a3.79,3.79,0,0,0-7.57,0v8.56h-8.55a3.79,3.79,0,1,0,0,7.57H621v8.55a3.79,3.79,0,1,0,7.57,0v-8.55h8.55a3.79,3.79,0,1,0,0-7.57Z"
			transform="translate(-507.84 -58.55)"
		/>
	</symbol>

	<symbol
		width="100%"
		height="100%"
		viewBox="0 0 512 512"
		id="icon-addEvents"
	>
		<path
			d="M469.333,304.232V85.333c0-23.531-19.146-42.667-42.667-42.667h-53.333c-5.896,0-10.667,4.771-10.667,10.667v53.333
			c0,11.76-9.563,21.333-21.333,21.333C329.563,128,320,118.427,320,106.667c0-8.917,5.729-16.948,14.25-19.979
			c4.25-1.521,7.083-5.542,7.083-10.052V10.667C341.333,4.771,336.563,0,330.667,0C324.771,0,320,4.771,320,10.667v32H160
			c-5.896,0-10.667,4.771-10.667,10.667v53.333c0,11.76-9.563,21.333-21.333,21.333s-21.333-9.573-21.333-21.333
			c0-8.917,5.729-16.948,14.25-19.979c4.25-1.521,7.083-5.542,7.083-10.052V10.667C128,4.771,123.229,0,117.333,0
			c-5.896,0-10.667,4.771-10.667,10.667v32h-64C19.146,42.667,0,61.802,0,85.333v341.333c0,23.531,19.146,42.667,42.667,42.667
			H304.23C325.772,495.376,358.316,512,394.667,512C459.354,512,512,459.365,512,394.667
			C512,358.313,495.374,325.77,469.333,304.232z M277.333,394.667c0,11.104,1.654,21.811,4.549,32H42.667v-256h384v111.216
			c-10.189-2.897-20.896-4.549-32-4.549C329.979,277.333,277.333,329.969,277.333,394.667z M437.333,405.333h-32v32
			c0,5.896-4.771,10.667-10.667,10.667S384,443.229,384,437.333v-32h-5.771H352c-5.896,0-10.667-4.771-10.667-10.667
			S346.104,384,352,384h32v-32c0-5.896,4.771-10.667,10.667-10.667s10.667,4.771,10.667,10.667v26.229V384h32
			c5.896,0,10.667,4.771,10.667,10.667S443.229,405.333,437.333,405.333z"
		/>
	</symbol>

	<symbol width="100%" height="100%" viewBox="0 0 512 512" id="icon-eaf">
		<path
			d="m360.435 128.533h94.578l-119.746-119.746v94.58c0 13.877 11.291 25.166 25.168 25.166z"
		/>
		<path
			d="m256.001 222.8c-53.744 0-97.467 43.724-97.467 97.467s43.723 97.467 97.467 97.467c53.742 0 97.467-43.724 97.467-97.467s-43.725-97.467-97.467-97.467zm44.687 85.352-45.441 45.443c-2.813 2.814-6.628 4.394-10.606 4.394-3.979 0-7.794-1.58-10.607-4.394l-22.723-22.723c-5.857-5.857-5.857-15.355 0-21.213 5.858-5.857 15.355-5.857 21.214 0l12.115 12.115 34.835-34.836c5.857-5.858 15.355-5.859 21.213 0 5.857 5.859 5.857 15.356 0 21.214z"
		/>
		<path
			d="m360.435 158.533c-30.42 0-55.168-24.747-55.168-55.166v-103.367h-201.9c-30.419 0-55.167 24.748-55.167 55.167v401.666c0 30.419 24.748 55.167 55.166 55.167h305.268c30.419 0 55.166-24.748 55.166-55.167v-298.3zm-104.434 289.2c-70.286 0-127.467-57.182-127.467-127.467s57.181-127.466 127.467-127.466c70.285 0 127.467 57.182 127.467 127.467s-57.182 127.466-127.467 127.466z"
		/>
	</symbol>

	<symbol width="100%" height="100%" viewBox="0 0 512 512" id="icon-feedback">
		<g>
			<path
				d="m457 80h-15v-35c0-24.813-20.187-45-45-45s-45 20.187-45 45v12.285c0 15.544-10.438 29.383-25.385 33.653l-14.615 4.176v-.114c0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v67h-177c-24.813 0-45 20.187-45 45v290c0 5.532 3.045 10.615 7.922 13.225 2.218 1.188 4.65 1.775 7.077 1.775 2.91 0 5.812-.846 8.321-2.52l56.222-37.48h285.458c24.813 0 45-20.187 45-45v-157h47c30.327 0 55-24.673 55-55v-80c0-30.327-24.673-55-55-55zm-205 30h30v130h-30zm128 317c0 8.271-6.729 15-15 15h-290c-2.961 0-5.856.877-8.32 2.52l-36.68 24.453v-261.973c0-8.271 6.729-15 15-15h177v63c0 8.284 6.716 15 15 15h60c7.706 0 14.048-5.814 14.896-13.293 13.363 8.522 29.075 13.293 45.415 13.293h22.689zm102-212c0 13.785-11.215 25-25 25h-99.689c-18.238 0-35.271-9.201-45.311-24.246v-89.439l22.856-6.53c27.758-7.931 47.144-33.632 47.144-62.5v-12.285c0-8.271 6.729-15 15-15s15 6.729 15 15v50c0 8.284 6.716 15 15 15h30c13.785 0 25 11.215 25 25z"
			/>
			<path
				d="m75 282h100c8.284 0 15-6.716 15-15s-6.716-15-15-15h-100c-8.284 0-15 6.716-15 15s6.716 15 15 15z"
			/>
			<path
				d="m335 312h-260c-8.284 0-15 6.716-15 15s6.716 15 15 15h260c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
			/>
			<path
				d="m335 372h-260c-8.284 0-15 6.716-15 15s6.716 15 15 15h260c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
			/>
		</g>
	</symbol>

	<symbol
		width="100%"
		height="100%"
		viewBox="0 0 43.028 43.028"
		id="icon-assignRoles"
	>
		<path
			d="M39.561,33.973l-0.145,0.172c-4.774,5.729-11.133,8.884-17.902,8.884c-6.77,0-13.128-3.154-17.903-8.884l-0.144-0.172
l0.034-0.225c0.922-6.014,4.064-10.844,8.847-13.605l0.34-0.196l0.271,0.284c2.259,2.369,5.297,3.674,8.554,3.674
s6.295-1.306,8.554-3.674l0.271-0.284l0.34,0.196c4.783,2.762,7.925,7.592,8.848,13.605L39.561,33.973z M21.514,21.488
c5.924,0,10.744-4.82,10.744-10.744S27.438,0,21.514,0S10.77,4.82,10.77,10.744S15.59,21.488,21.514,21.488z M28.977,35.854
l5.505-5.506c0.616-0.615,0.616-1.615,0-2.229c-0.616-0.617-1.616-0.617-2.231,0l-4.389,4.39l-2.752-2.752
c-0.617-0.617-1.615-0.617-2.232,0c-0.614,0.614-0.614,1.614,0,2.231l3.869,3.866c0.309,0.31,0.711,0.463,1.115,0.463
S28.668,36.16,28.977,35.854z"
		/>
	</symbol>

	<symbol
		width="100%"
		height="100%"
		viewBox="0 0 25.772 25.772"
		id="icon-approvals"
	>
		<g>
			<path
				d="M25.646,13.74l-1.519-2.396l0.901-2.689c0.122-0.367-0.03-0.77-0.365-0.962l-2.458-1.417l-0.452-2.8
			c-0.063-0.382-0.385-0.667-0.771-0.683l-2.835-0.111l-1.701-2.27c-0.232-0.31-0.652-0.413-0.999-0.246l-2.561,1.218l-2.562-1.219
			C9.976,0,9.558,0.103,9.325,0.412l-1.701,2.27L4.789,2.793c-0.385,0.015-0.708,0.3-0.77,0.682l-0.452,2.8L1.109,7.692
			C0.774,7.884,0.621,8.287,0.743,8.654l0.901,2.689L0.126,13.74c-0.207,0.327-0.154,0.754,0.125,1.022l2.047,1.963l-0.23,2.826
			c-0.031,0.387,0.213,0.74,0.584,0.848l2.725,0.785l1.109,2.611c0.152,0.355,0.533,0.561,0.911,0.479l2.78-0.57l2.194,1.797
			c0.149,0.121,0.332,0.184,0.515,0.184s0.365-0.063,0.515-0.184l2.194-1.797l2.78,0.57c0.377,0.08,0.76-0.123,0.911-0.479
			l1.109-2.611l2.725-0.785c0.371-0.107,0.615-0.461,0.584-0.848l-0.23-2.826l2.047-1.963C25.8,14.494,25.853,14.067,25.646,13.74z
			 M18.763,9.829l-5.691,8.526c-0.215,0.318-0.548,0.531-0.879,0.531c-0.33,0-0.699-0.185-0.934-0.421L7.081,14.22
			c-0.285-0.29-0.285-0.76,0-1.05l1.031-1.05c0.285-0.286,0.748-0.286,1.031,0l2.719,2.762l4.484-6.718
			c0.225-0.339,0.682-0.425,1.014-0.196l1.209,0.831C18.902,9.029,18.988,9.492,18.763,9.829z"
			/>
		</g>
	</symbol>

	<symbol
		width="100%"
		height="100%"
		viewBox="0 0 330 330"
		id="icon-evaluations"
	>
		<g>
			<path
				d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300
		c-74.439,0-135-60.561-135-135S90.561,30,165,30s135,60.561,135,135S239.439,300,165,300z"
			/>
			<path
				d="M247.157,128.196l-47.476-6.9l-21.23-43.019c-2.527-5.12-7.741-8.362-13.451-8.362s-10.924,3.242-13.451,8.362
		l-21.23,43.019l-47.476,6.9c-5.65,0.821-10.345,4.779-12.108,10.209c-1.765,5.43-0.293,11.391,3.796,15.376l34.353,33.486
		l-8.109,47.282c-0.965,5.628,1.349,11.315,5.968,14.671c4.618,3.355,10.741,3.799,15.797,1.142L165,228.039l42.462,22.323
		c2.195,1.154,4.592,1.723,6.979,1.723c0.017,0,0.033,0,0.05,0c8.271-0.015,14.972-6.725,14.972-15c0-1.152-0.13-2.274-0.375-3.352
		l-7.97-46.466l34.352-33.486c4.089-3.985,5.561-9.946,3.796-15.376C257.502,132.975,252.808,129.017,247.157,128.196z"
			/>
		</g>
	</symbol>

	<symbol
		width="100%"
		height="100%"
		viewBox="0 0 472.623 472.623"
		id="icon-viewevaluations"
	>
		<g>
			<g>
				<path
					d="M157.062,56.669c-0.985-3.151-3.545-5.514-6.794-6.4l-40.271-11.028L87.056,4.287c-3.643-5.514-12.898-5.514-16.542,0
			L47.573,39.241L7.302,50.269c-3.249,0.886-5.809,3.249-6.794,6.4c-1.083,3.151-0.394,6.695,1.674,9.255l26.191,32.591
			l-2.068,41.748c-0.098,3.348,1.378,6.498,4.135,8.468c2.659,1.969,6.105,2.462,9.255,1.28l39.089-14.868l39.089,14.868
			c1.182,0.394,2.363,0.591,3.545,0.591c1.969,0,4.037-0.591,5.711-1.871c2.757-1.969,4.234-5.12,4.135-8.468l-2.068-41.748
			l26.191-32.591C157.456,63.364,158.145,59.819,157.062,56.669z"
				/>
			</g>
		</g>
		<g>
			<g>
				<rect x="196.931" y="49.058" width="167.385" height="19.692" />
			</g>
		</g>
		<g>
			<g>
				<rect x="196.931" y="98.288" width="275.692" height="19.692" />
			</g>
		</g>
		<g>
			<g>
				<path
					d="M157.062,217.653c-0.985-3.151-3.545-5.612-6.794-6.498l-40.271-11.028l-22.942-34.954
			c-3.643-5.514-12.898-5.514-16.542,0l-22.942,34.954L7.302,211.155c-3.249,0.886-5.809,3.348-6.794,6.498
			c-1.083,3.151-0.394,6.597,1.674,9.157l26.191,32.591l-2.068,41.846c-0.098,3.249,1.378,6.498,4.135,8.369
			c2.659,1.969,6.105,2.462,9.255,1.28l39.089-14.769l39.089,14.769c1.182,0.394,2.363,0.689,3.545,0.689
			c1.969,0,4.037-0.689,5.711-1.969c2.757-1.871,4.234-5.12,4.135-8.369l-2.068-41.846l26.191-32.591
			C157.456,224.25,158.145,220.804,157.062,217.653z"
				/>
			</g>
		</g>
		<g>
			<g>
				<rect x="196.931" y="206.596" width="167.385" height="19.692" />
			</g>
		</g>
		<g>
			<g>
				<rect x="196.931" y="255.827" width="275.692" height="19.692" />
			</g>
		</g>
		<g>
			<g>
				<path
					d="M157.062,378.539c-0.985-3.151-3.545-5.612-6.794-6.498l-40.271-10.929l-22.942-35.052
			c-3.643-5.514-12.898-5.514-16.542,0l-22.942,35.052L7.302,372.041c-3.249,0.886-5.809,3.348-6.794,6.498
			c-1.083,3.151-0.394,6.597,1.674,9.157l26.191,32.689l-2.068,41.748c-0.098,3.348,1.378,6.498,4.135,8.468
			c2.659,1.871,6.105,2.363,9.255,1.182l39.089-14.769l39.089,14.769c1.182,0.492,2.363,0.689,3.545,0.689
			c1.969,0,4.037-0.689,5.711-1.871c2.757-1.969,4.234-5.12,4.135-8.468l-2.068-41.748l26.191-32.689
			C157.456,385.136,158.145,381.69,157.062,378.539z"
				/>
			</g>
		</g>
		<g>
			<g>
				<rect x="196.931" y="373.981" width="167.385" height="19.692" />
			</g>
		</g>
		<g>
			<g>
				<rect x="196.931" y="423.211" width="275.692" height="19.692" />
			</g>
		</g>
	</symbol>
</svg>
