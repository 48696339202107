<app-nav></app-nav>

<div class="container">

	<div class="form">
		<form [formGroup]="petitionForm" (ngSubmit)="insertPetition()">
			<p>Name</p>
			<div class="name">
				<input type="text" name="name" formControlName="petitionName" />
			</div>

			<p>RIT Email</p>
			<div class="email">
				<input type="text" name="email" formControlName="petitionEmail" />
			</div>

			<!-- <p>Who would you like your complaint/feedback to be read by?</p>
			<select name="group" id="" formControlName="feedbackTargetGroup">
				<option>Student Government</option>
				<option>Student Affairs</option>
				<option>Computing Senator</option>
				<option>Business Senator</option>
				<option>Engineering Senator</option>
			</select> -->

			<p>Suggestions/Complaints*</p>
			<textarea id="textbox" name="textbox" rows="5" cols="50" formControlName="prompt">
			</textarea>

			<div class="success">
				<p>
					<b>{{ successMsg }}</b>
				</p>
			</div>

			<input value="SEND" class="submitbtn" type="submit" />
		</form>
	</div>
</div>
<app-footer></app-footer>
