import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgxCsvParser } from 'ngx-csv-parser';
import { ViewChild } from '@angular/core';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { event, sp } from 'src/app/shared/sgTypes';
import { formatCurrency } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
	selector: 'app-add-events',
	templateUrl: './add-events.component.html',
	styleUrls: ['./add-events.component.scss'],
})
export class AddEventsComponent implements OnInit {
	route = 'addEvents';
	csvRecords: sp[] = [];
	header = true;
	currenDate: any;

	latestNumOfEvents = 4;
	path: any;
	imageName: string;
	downloadUrl: any;
	uploadTask: any;
	userMessageEvents: any;
	userMessageSpiritPoints: any;
	email: any;
	role: any;

	constructor(
		public FirebaseService: AuthService,
		public auth: AuthService,
		private ngxCsvParser: NgxCsvParser,
		private db: AngularFirestore,
		private af: AngularFireStorage
	) {}

	ngOnInit(): void {}
	userInfo() {}

	@ViewChild('fileImportInput') fileImportInput: any;
	@ViewChild('nameOfEvent') eventName: any;
	@ViewChild('eventDescription') eventDescription: any;
	@ViewChild('eventDateTime') eventDateTime: any;
	@ViewChild('zoomLink') eventZoomLink: any;

	fileChangeListener($event: any): void {
		const files = $event.srcElement.files;

		this.ngxCsvParser
			.parse(files[0], { header: this.header, delimiter: ',' })
			.pipe()
			.subscribe(
				(result: any) => {
					// Add date to results
					this.currenDate = new Date().toDateString();
					for (let i = 0; i < result.length; i++) {
						this.csvRecords[i] = {
							name: result[i].Name,
							points: parseFloat(result[i].Points),
							ritID: result[i].RITID,
							date: this.currenDate,
						};
					}
					console.table(this.csvRecords);
				},
				(error: NgxCSVParserError) => {}
			);
	}

	updateSpiritPoints() {
		for (let csvData of this.csvRecords as sp[]) {
			this.auth.insertUserData(csvData);
		}
		this.userMessageSpiritPoints = 'Uploaded Successfully';
	}

	getValue(
		dateTimeInput: any,
		eventName: string,
		eventDescription: string,
		zoomlink: string
	) {
		var dateTime = dateTimeInput.split('T');
		var date = dateTime[0];
		var time = dateTime[1];
		this.eventName.nativeElement.value = '';
		this.eventDateTime.nativeElement.value = '';
		this.eventDescription.nativeElement.value = '';
		this.eventZoomLink.nativeElement.value = '';
		this.af
			.upload(`Events/${date}/${eventName}/${this.imageName}`, this.path)
			.then(async (snapshot) => {
				let imgSrc = await snapshot.ref.getDownloadURL();

				let dbEvent: event = {
					eventName: eventName,
					eventDescription: eventDescription,
					eventImg: imgSrc,
					date: date,
					time: time,
					zoomlink: zoomlink,
				};

				this.db
					.collection('UpcomingEvents')
					.doc(date)
					.collection('Events')
					.doc()
					.set(dbEvent);
				this.userMessageEvents = 'Event Created Successfully';
			})
			.catch((error) => {
				this.userMessageEvents = 'Please fill in the required fields';
			});
	}

	upload($event: any) {
		this.path = $event.target.files[0];
		this.imageName = this.path.name;
	}
}
