import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import {
	eaf,
	eafDoc,
	post,
	postDoc,
	poster,
	posterDoc,
	sgRole,
	sgRoles,
	sp,
	userObject,
} from '../shared/sgTypes';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	user: firebase.default.User;
	userRole: any;
	userAuthRole: string;

	initialWait = true;
	baseRoute = 'dashboard';

	users: userObject[];

	eafDocs: eafDoc[];
	pendingEafDocs: eafDoc[];
	currentEAFDoc: eafDoc;

	posterDocs: posterDoc[];
	pendingPosterDocs: posterDoc[];
	currentPosterDoc: posterDoc;

	postDocs: postDoc[];
	pendingPostDocs: postDoc[];
	currentPostDoc: postDoc;

	constructor(
		private firebaseAuth: AngularFireAuth,
		private router: Router,
		private db: AngularFirestore
	) {
		firebaseAuth.user.subscribe((user) => {
			this.initialWait = false;
			this.checkUserState(this.baseRoute);
		});
	}

	checkUserState(route: string) {
		if (this.initialWait) {
			this.baseRoute = route;
		} else if (!this.user) {
			this.router.navigate(['login']);
		} else {
			if (this.userRole === 'Club') {
				if (
					route === '/EAF' ||
					route === '/posterapproval' ||
					route === '/postapproval' ||
					route === '/approvalforms'
				) {
					this.router.navigate([route]);
				} else this.router.navigate(['approvalforms']);
			} else {
				this.router.navigate([route]);
			}
			this.init();
		}
	}

	init() {
		this.db
			.collection('SGUser')
			.valueChanges()
			.subscribe((result) => {
				this.users = result as userObject[];
				// This is very jank - implement native way to do this
				if (!this.userAuthRole) {
					this.userAuthRole = this.userRole = this.users.find(
						(user) => user.email === this.user.email
					).role;
				}
				this.userRole = sgRole[this.userAuthRole as sgRoles];
				console.log('userRoles', this.userRole);
				console.log('userAuthRole', this.userAuthRole);
			});

		this.db
			.collection<eaf>('Approvals/EAF/Pending')
			.snapshotChanges()
			.subscribe((observer) => {
				this.eafDocs = observer.map((doc) => {
					const id = doc.payload.doc.id;
					const data = doc.payload.doc.data();
					return {
						id,
						createdOn: data.dateCreated.toDate(),
						type: 'Event Approval',
						data,
					};
				});

				this.eafDocs.sort((a: any, b: any) => {
					// Why is this written like this - use proper date techniques
					return b.createdOn - a.createdOn;
				});

				this.pendingEafDocs = this.eafDocs.filter((doc) => {
					if (
						doc.data.clubOrganization === 'Student Government' &&
						this.userAuthRole === 'sgPresident' &&
						!doc.data.sgPresident
					) {
						return true;
					} else if (
						doc.data.clubOrganization !== 'Student Government' &&
						this.userAuthRole === 'clubsDirector' &&
						!doc.data.clubsDirector
					) {
						return true;
					} else if (
						this.userAuthRole === 'studentAffairs' &&
						doc.data.clubsDirector &&
						!doc.data.studentAffairs
					) {
						return true;
					} else if (
						this.userAuthRole === 'financeDirector' &&
						doc.data.studentAffairs &&
						!doc.data.financeDirector
					) {
						return true;
					}
					return false;
				});
			});

		this.db
			.collection<post>('Approvals/Post/Pending')
			.snapshotChanges()
			.subscribe((observer) => {
				this.postDocs = observer.map((doc) => {
					const id = doc.payload.doc.id;
					const data = doc.payload.doc.data();

					return {
						id,
						createdOn:
							data.dateCreated && data.dateCreated.toDate(),
						type: 'Post Approval',
						data,
					};
				});
				// Do you know how much I hate "any"?
				this.postDocs.sort((a: any, b: any) => {
					return b.createdOn - a.createdOn;
				});

				this.pendingPostDocs = this.postDocs.filter((doc) => {
					// Refactor this - its clunky
					if (
						this.userAuthRole === 'clubsDirector' &&
						!doc.data.clubsDirector
					) {
						return true;
					} else if (
						this.userAuthRole === 'studentAffairs' &&
						doc.data.clubsDirector &&
						!doc.data.studentAffairs
					) {
						return true;
					}
					return false;
				});
			});
		this.db
			.collection<poster>('Approvals/Poster/Pending')
			.snapshotChanges()
			.subscribe((observer) => {
				this.posterDocs = observer.map((doc) => {
					const id = doc.payload.doc.id;
					const data = doc.payload.doc.data();

					return {
						id,
						createdOn:
							data.dateCreated && data.dateCreated.toDate(),
						type: 'Poster Approval',
						data,
					};
				});
				// Do you know how much I hate "any"?
				this.posterDocs.sort((a: any, b: any) => {
					return b.createdOn - a.createdOn;
				});

				this.pendingPosterDocs = this.posterDocs.filter((doc) => {
					// Refactor this - its clunky
					if (
						this.userAuthRole === 'clubsDirector' &&
						!doc.data.clubsDirector
					) {
						return true;
					} else if (
						this.userAuthRole === 'studentAffairs' &&
						doc.data.clubsDirector &&
						!doc.data.studentAffairs
					) {
						return true;
					}
					return false;
				});
			});
	}

	isValidUserGroup(validGroups: Array<String>): boolean {
		return this.userAuthRole && validGroups.includes(this.userAuthRole);
	}

	isEventApprovalRole(): boolean {
		return this.isValidUserGroup([
			'clubsDirector',
			'financeDirector',
			'studentAffairs',
		]);
	}

	isPosterApprovalRole(): boolean {
		return this.isValidUserGroup([
			'mediaDirector',
			'studentAffairs',
			'marketing',
			'clubsDirector',
		]);
	}

	approveEAF() {
		if (this.userAuthRole === 'clubsDirector') {
			this.db
				.doc('Approvals/EAF/Pending/' + this.currentEAFDoc.id)
				.update({ clubsDirector: true });
		} else if (this.userAuthRole === 'financeDirector') {
			this.currentEAFDoc.data.financeDirector = true;
			this.db
				.collection('Approvals/EAF/Approved')
				.doc()
				.set(this.currentEAFDoc.data);
			this.db
				.doc('Approvals/EAF/Pending/' + this.currentEAFDoc.id)
				.delete();
		} else {
			const state: any = {};
			state[this.userAuthRole] = true;
			this.db
				.doc('Approvals/EAF/Pending/' + this.currentEAFDoc.id)
				.update(state);
		}
	}

	approvePoster() {
		if (
			this.userAuthRole === 'studentAffairs' ||
			this.userAuthRole === 'clubsDirector'
		) {
			this.currentPosterDoc.data.studentAffairs = true;
			this.db
				.collection('Approvals/Poster/Approved')
				.doc()
				.set(this.currentPosterDoc.data);
			this.db
				.doc('Approvals/Poster/Pending/' + this.currentPosterDoc.id)
				.delete();
		} else {
			const state: any = {};
			state[this.userAuthRole] = true;
			this.db
				.doc('Approvals/Poster/Pending/' + this.currentPosterDoc.id)
				.update(state);
		}
	}

	approvePost() {
		if (
			this.userAuthRole === 'studentAffairs' ||
			this.userAuthRole === 'clubsDirector'
		) {
			this.currentPostDoc.data.studentAffairs = true;
			this.db
				.collection('Approvals/Post/Approved')
				.doc()
				.set(this.currentPostDoc.data);
			this.db
				.doc('Approvals/Post/Pending/' + this.currentPostDoc.id)
				.delete();
		} else {
			const state: any = {};
			state[this.userAuthRole] = true;
			this.db
				.doc('Approvals/Post/Pending/' + this.currentPostDoc.id)
				.update(state);
		}
	}

	rejectEAF() {
		this.db
			.collection('Approvals/EAF/Rejected')
			.doc()
			.set(this.currentEAFDoc.data);
		this.db.doc('Approvals/EAF/Pending/' + this.currentEAFDoc.id).delete();
	}

	rejectPost(rejectionComment = '') {
		let post = { ...this.currentPostDoc.data, rejectionComment };
		this.db.collection('Approvals/Post/Rejected').doc().set(post);
		this.db
			.doc('Approvals/Post/Pending/' + this.currentPosterDoc.id)
			.delete();
	}
	rejectPoster(rejectionComment = '') {
		let poster = { ...this.currentPosterDoc.data, rejectionComment };
		console.log(poster);
		this.db.collection('Approvals/Poster/Rejected').doc().set(poster);
		this.db
			.doc('Approvals/Poster/Pending/' + this.currentPosterDoc.id)
			.delete();
	}

	login(email: string, password: string) {
		this.firebaseAuth
			.signInWithEmailAndPassword(email, password)
			.then((value) => {
				this.user = value.user;
				this.router.navigate(['dashboard']);
			})
			.catch((err) => {});
	}

	createAUser(name: any, email: any, role: any, roleName: any) {
		this.firebaseAuth
			.createUserWithEmailAndPassword(email, '3oSLt6pP')
			.then((result) => {
				result.user
					.updateProfile({
						displayName: role,
					})
					.then((x) => {
						this.db.collection('SGUser').doc().set({
							name: name,
							email: result.user.email,
							uid: result.user.uid,
							role: result.user.displayName,
							roleName: roleName,
						});
					});
			})
			.then((func) => {
				this.logout();
			});
	}

	logout(): void {
		this.firebaseAuth.signOut();
	}

	async insertUserData(data: sp): Promise<void> {
		let points = 0.0;
		try {
			await this.db
				.collection('SpiritPoints')
				.doc(data.ritID)
				.ref.get()
				.then((doc) => {
					let data: any = doc.data();
					points += data.points;
					console.table({ name: data.name, points: data.points });
				});
		} catch (error) {}
		points += data.points;
		this.db
			.collection('SpiritPoints')
			.doc(data.ritID)
			.set({
				name: data.name,
				points: this.round(points, 1),
				date: data.date,
				ritID: data.ritID,
			});
	}

	round(value: number, precision: number) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	memberEval(
		_memberName: string,
		_evalDate: string,
		_evalMonth: string,
		_memberPosition: string,
		_dept: string,
		_focus: string,
		_productivity: string,
		_stress: string,
		_teamwork: string,
		_coop: string,
		_integrity: string,
		_communication: string,
		_sgUnderstanding: string,
		_improvement: string,
		_memberFeedback: string
	) {
		this.db
			.collection('SgMemberEvals')
			.doc(_evalMonth)
			.collection('Info')
			.doc()
			.set({
				Name: _memberName,
			});
	}
}
