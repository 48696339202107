import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { evaluationObject } from 'src/app/shared/sgTypes';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { init } from '@emailjs/browser';
init('user_xAXqgX58ky2phBgx67t8u');
@Component({
	selector: 'app-view-eval',
	templateUrl: './view-eval.component.html',
	styleUrls: ['./view-eval.component.scss'],
})
export class ViewEvalComponent implements OnInit {
	@ViewChild('modal', { static: false }) el!: ElementRef;
	monthlyEvaluations: localEvaluationObject[] = [];

	isShowEval = false;
	currentEvaluation: localModalEvaluationObject;

	finalComment: string;

	constructor(public auth: AuthService, private db: AngularFirestore) {}

	ngOnInit(): void {
		let months: string[] = [];
		this.db
			.collection('SGEvaluation')
			.ref.get()
			.then((obj) => {
				obj.forEach((doc) => months.push(doc.id));
			})
			.then(() => {
				months.sort((a, b) => +new Date(a) - +new Date(b));
				months.forEach((month) => {
					let evaluations: evaluationObject[] = [];
					this.db
						.collection(`SGEvaluation/${month}/MonthEvaluation`)
						.ref.get()
						.then((obj) =>
							obj.forEach((doc) =>
								evaluations.push(doc.data() as evaluationObject)
							)
						);

					const newMonth = `${new Date('2021-10').toLocaleString(
						'default',
						{ month: 'long' }
					)} ${new Date('2021-10').getFullYear()}`;

					this.monthlyEvaluations.push({
						month: newMonth,
						evaluations,
					});
				});
			});
	}

	closeEval() {
		this.isShowEval = false;
		this.currentEvaluation = undefined;
	}

	sendEmail() {
		let message = `<b>Your evaluation for ${this.currentEvaluation.month}</b> <br /><br />`;
		this.currentEvaluation.groups.forEach((group) => {
			group.fields.forEach((field) => {
				message += `<b>${field.name}:</b> ${field.value} <br />`;
			});
			message += '<br /><br />';
		});
		message += `<b>Final Comments:</b> ${this.finalComment}`;

		emailjs.send('service_xwp44nm', 'template_eahhfas', {
			name: this.currentEvaluation.name,
			email: this.currentEvaluation.email,
			month: this.currentEvaluation.month,
			message,
		});
	}

	showEval(month: string, evaluation: evaluationObject) {
		const modalEvaluationGroup: groupObject = {
			title: 'Evaluation',
			fields: [
				{ name: 'Name', value: evaluation.name },
				{ name: 'Email', value: evaluation.email },
				{ name: 'Role', value: evaluation.role },
				{
					name: 'Director evaluation (40%)',
					value: evaluation.directorEvaluation,
				},
				{
					name: 'Regular evaluations (60%)',
					value: evaluation.evaluations,
				},
				{
					name: 'Number of evaluations',
					value: evaluation.feedback.length,
				},
				{
					name: 'Calculated evaluation',
					value: evaluation.calculatedEvaluations.toFixed(0),
				},
			],
		};

		let modalFeedbackGroup: groupObject = {
			title: `Feedbacks`,
			fields: [
				{
					name: 'Evaluator names',
					value: evaluation.feedback
						.map((feedback) => feedback.name)
						.join(', '),
				},
				{
					name: 'Attendance',
					value: this.getAverage(
						evaluation.feedback.map(
							(feedback) => feedback.attendance
						)
					),
				},
				{
					name: 'Focus',
					value: this.getAverage(
						evaluation.feedback.map((feedback) => feedback.focus)
					),
				},
				{
					name: 'Productivity / Deadlines',
					value: this.getAverage(
						evaluation.feedback.map(
							(feedback) => feedback.productivity
						)
					),
				},
				{
					name: 'Stress management',
					value: this.getAverage(
						evaluation.feedback.map(
							(feedback) => feedback.stressManagement
						)
					),
				},
				{
					name: 'Teamwork',
					value: this.getAverage(
						evaluation.feedback.map((feedback) => feedback.teamwork)
					),
				},
				{
					name: 'Attitude / Cooperation',
					value: this.getAverage(
						evaluation.feedback.map((feedback) => feedback.attitude)
					),
				},
				{
					name: 'Integrity',
					value: this.getAverage(
						evaluation.feedback.map(
							(feedback) => feedback.integrity
						)
					),
				},
				{
					name: 'Communication',
					value: this.getAverage(
						evaluation.feedback.map(
							(feedback) => feedback.communication
						)
					),
				},
				{
					name: 'University & SG Oriented',
					value: this.getAverage(
						evaluation.feedback.map(
							(feedback) => feedback.uniSGOriented
						)
					),
				},
				{
					name: 'Improvement',
					value: this.getAverage(
						evaluation.feedback.map(
							(feedback) => feedback.improvement
						)
					),
				},
			],
		};

		evaluation.feedback.forEach((feedback, index) => {
			modalFeedbackGroup.fields.push({
				name: `Comment ${index + 1}`,
				value: feedback.comment,
			});
		});

		this.currentEvaluation = {
			name: evaluation.name,
			email: evaluation.email,
			month,
			evaluation: evaluation.calculatedEvaluations.toFixed(0),
			groups: [modalEvaluationGroup, modalFeedbackGroup],
		};
		this.isShowEval = true;
	}

	downloadPDF() {
		window.print();
	}

	getAverage(array: number[]) {
		return (array.reduce((a, b) => a + b, 0) / array.length).toFixed(0);
	}
}

type localEvaluationObject = { month: string; evaluations: evaluationObject[] };

type groupObject = {
	title: string;
	fields: { name: string; value: string | number }[];
};
type localModalEvaluationObject = {
	name: string;
	email: string;
	month: string;
	evaluation: string;
	groups: groupObject[];
};
