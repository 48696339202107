<div class="admin-page">
	<app-side-panel></app-side-panel>
	<div class="admin-content">
		<div class="admin-header">
			<h1 class="admin-title">Event Approval Form</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>

		<div class="admin-body">
			<!--Progress Bar-->
			<div class="progressbar">
				<div class="progress-step" [ngClass]="{
						'progress-step-active': step === 1,
						'progress-step-success': step > 1
					}">
					<svg class="icon icon-person">
						<use xlink:href="#icon-person" />
					</svg>
				</div>

				<div class="progress-step" [ngClass]="{
						'progress-step-active': step === 2,
						'progress-step-success': step > 2
					}">
					<svg class="icon icon-eventDetails">
						<use xlink:href="#icon-eventDetails" />
					</svg>
				</div>

				<div class="progress-step" [ngClass]="{
						'progress-step-active': step === 3,
						'progress-step-success': step > 3
					}">
					<svg class="icon icon-budgetDetails">
						<use xlink:href="#icon-budgetDetails" />
					</svg>
				</div>

				<div class="progress-step" [ngClass]="{
						'progress-step-active': step === 4,
						'progress-step-success': step > 4
					}">
					<svg class="icon icon-facilitiesDetails">
						<use xlink:href="#icon-facilitiesDetails" />
					</svg>
				</div>

				<div class="progress-step" [ngClass]="{
						'progress-step-active': step === 5,
						'progress-step-success': step > 5
					}">
					<svg class="icon icon-speakerDetails">
						<use xlink:href="#icon-speakerDetails" />
					</svg>
				</div>

				<div class="progress-step" [ngClass]="{
						'progress-step-active': step === 6,
						'progress-step-success': step > 6
					}">
					<svg class="icon icon-mediaDetails">
						<use xlink:href="#icon-mediaDetails" />
					</svg>
				</div>
			</div>
			<!-- <button (click)="getValue()">Test</button> -->
			<form class="form" (ngSubmit)="submit()" [formGroup]="multistep">
				<!--Sections-->
				<div class="form-step form-step-active" *ngIf="step == 1" formGroupName="requesterDetails">
					<h1 class="text-center">Section A - Requestor Details</h1>
					<div class="input-group">
						<label for="requestor-name">Requestor Name</label>
						<input type="text" name="requestor-name" id="requestor-name" formControlName="rname"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="requestor-contact">Requestor Contact</label>
						<input type="number" name="requestor-contact" id="requestor-contact" formControlName="rcontact"
							placeholder=" " />
					</div>
					<div class="input-group">
						<label for="requestor-email">Requestor Email</label>
						<input type="email" name="requestor-email" id="requestor-email" formControlName="remail"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="alternate-name">Alternate Contact Person Name</label>
						<input type="text" name="alternate-name" id="alternate-name" formControlName="alternateName"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="alternate-contact">Alternate Contact Person Contact</label>
						<input type="number" name="alternate-contact" id="alternate-contact"
							formControlName="alternateContact" placeholder=" " />
					</div>
					<div class="input-group">
						<label for="alternate-email">Alternate Contact Person Email</label>
						<input type="email" name="alternate-email" id="alternate-email" formControlName="alternateEmail"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="clubs-organization">Club or Organization</label>

						<select name="clubs-organization" id="clubs-organizationStudent Government"
							formControlName="clubOrganization" placeholder=" ">
							<option *ngFor="let club of clubs" value="{{ club }}">
								{{ club }}
							</option>
						</select>
					</div>
				</div>
				<!--Section 2-->
				<div class="form-step" *ngIf="step == 2" formGroupName="eventDetails">
					<h2 class="text-center">Section B - Events Details</h2>
					<div class="input-group">
						<label for="Event Name">Event Details</label>
						<input type="text" name="Event-Name" id="Event-Name" formControlName="eventName"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="event-location">Event Location</label>
						<input type="text" name="event-location" id="event-location" formControlName="eventLocation"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="event-date">Event Date</label>
						<input type="date" name="event-date" id="event-date" formControlName="eventDate" placeholder=" "
							[min]="minEventStartDate" />
					</div>

					<div class="input-group">
						<label for="event-start-time">Event Start Time</label>
						<input type="time" name="event-start-time" id="event-start-time"
							formControlName="eventStartTime" placeholder=" " />
					</div>

					<div class="input-group">
						<label for="event-end-time">Event End Time</label>
						<input type="time" name="event-end-time" id="event-end-time" formControlName="eventEndTime"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="event-description">Brief Event Description</label>
						<input type="text" name="event-description" id="event-description"
							formControlName="eventDescription" placeholder=" " />
					</div>

					<div class="input-group">
						<label for="event-speaker">Does your event feature a speaker?</label>

						<select name="event-speaker" id="event-speaker" formControlName="eventSpeaker" placeholder=" ">
							<option value="speaker-yes">Yes</option>
							<option value="speaker-no">No</option>
						</select>
					</div>
					<!-- <div class="btns-group">
						<a href="#" class="btn btn-prev">Previous</a>
						<a href="#" class="btn btn-next">Next</a>
					</div> -->
				</div>

				<!--Section 3-->
				<div class="form-step" *ngIf="step == 3" formGroupName="budgetDetails">
					<h2 class="text-center">Section C - Finance Details</h2>
					<div class="input-group">
						<label for="budget-request">Total Requested Budget</label>
						<input type="text" name="budget-request" id="budget-request" formControlName="budgetRequested"
							placeholder=" " />
					</div>
					<div class="input-group">
						<label for="budget-detail">Detailed Breakdown for Requested Budget</label>
						<input type="text" name="budget-detail" id="budget-detail" formControlName="budgetDetail"
							placeholder="(insert hyperlink)" />
					</div>

					<div class="input-group">
						<label for="budget-collection-date">Anticipated Cash Collection Date
						</label>
						<input type="date" name="budget-collection-date" id="budget-collection-date"
							formControlName="budgetCollectionDate" placeholder=" " />
					</div>

					<!-- <div class="btns-group">
						<a href="#" class="btn btn-prev">Previous</a>
						<a href="#" class="btn btn-next">Next</a>
					</div> -->
				</div>

				<!--Section 4-->
				<div class="form-step" *ngIf="step == 4" formGroupName="facilitiesDetails">
					<h2 class="text-center">Section D - Facilities</h2>

					<div class="input-group">
						<label for="facilities-tables">Tables</label>
						<input type="number" name="facilities-tables" id="event-tables" formControlName="tables"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="facilities-chairs">Chairs</label>
						<input type="number" name="facilities-chairs" id="event-chairs" formControlName="chairs"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="facilities-others">Other Requests</label>
						<input type="text" name="facilities-others" id="event-others" formControlName="others"
							placeholder=" " />
					</div>

					<!-- <div class="btns-group">
						<a href="#" class="btn btn-prev">Previous</a>
						<a href="#" class="btn btn-next">Next</a>
					</div> -->
				</div>

				<!--Section 5-->
				<div class="form-step" *ngIf="step == 5" formGroupName="speakerDetails">
					<h2 class="text-center">Speaker Information</h2>

					<div class="input-group">
						<label for="speaker-info">Speaker Name</label>
						<input type="text" name="speaker-info" id="speaker-info" formControlName="speakerName"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="speaker-position">Designation/Position</label>
						<input type="text" name="speaker-position" id="speaker-position "
							formControlName="speakerPosition" placeholder=" " />
					</div>

					<div class="input-group">
						<label for="speaker-contact">Speaker Contact</label>
						<input type="number" name="speaker-contact" id="speaker-contact "
							formControlName="speakerContact" placeholder=" " />
					</div>

					<div class="input-group">
						<label for="speaker-email">Speaker Email</label>
						<input type="text" name="speaker-email" id="speaker-email" formControlName="speakerEmail"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="speaker-company">Company</label>
						<input type="text" name="speaker-company" id="speaker-company" formControlName="speakerCompany"
							placeholder=" " />
					</div>
					<label>Please attach resume of Speaker</label>
					<input type="file" />

					<!-- <div class="btns-group">
						<a href="#" class="btn btn-prev">Previous</a>
						<a href="#" class="btn btn-next">Next</a>
					</div> -->
				</div>

				<!--Section 6-->
				<div class="form-step" *ngIf="step == 6" formGroupName="mediaDetails">
					<h2 class="text-center">Media and Marketting</h2>

					<div class="input-group">
						<label for="media-email-content">Please specify the content of the mass e-mail that
							will be sent out to the students</label>
						<input type="text" name="media-email-content" id="media-email-content"
							formControlName="mediaEmailContent" placeholder=" " />
					</div>

					<div class="input-group">
						<label for="sg-poster">Do you require a poster to be designed by the SG
							for this event?</label>
						<input type="text" name="sg-poster" id="sg-poster" formControlName="mediaSgPoster"
							placeholder=" " />
					</div>

					<div class="input-group">
						<label for="media-advertising">How will you be advertising this event? Please
							include all of your official social media handles,
							if any</label>
						<input type="text" name="media-advertising" id="media-advertising"
							formControlName="mediaAdvertising" placeholder=" " />
					</div>

					<div class="input-group">
						<label for="media-rights">RIT Student Government Media team have the rights
							to document and publish videos and photos of the
							event, via Instagram, Facebook, Snapchat and other
							social platforms through the official SG
							account.</label>
						<input type="text" name="media-rights" id="media-rights" formControlName="mediaRights"
							placeholder=" " />
					</div>

					<!-- <div class="btns-group">
						<a href="#" class="btn btn-prev">Previous</a>
						<a href="#" class="btn btn-next">Next</a>
					</div> -->
				</div>
				<div class="errorMessage">{{ errorMessage }}</div>
				<div class="btns-group">
					<a *ngIf="step != 1 && step <= 6" class="btn btn-prev" (click)="previous()">Previous</a>
					<!-- <a href="#" class="btn btn-next">Next</a> -->
					<!-- 1 < step <= 6 -->
					<button type="submit" *ngIf="step < 6" class="btn btn-next">
						Next
					</button>

					<button type="submit" *ngIf="step == 6" (click)="insertData()">
						Submit
					</button>
				</div>
			</form>

			<div class="finished" *ngIf="step == 7">
				<h1>Thank you for submitting your form!</h1>
			</div>
			<!-- <pre>{{ multistep.value | json }}</pre> -->
		</div>
	</div>
</div>

<svg display="none">
	<symbol viewBox="0 0 125.2 125.2" id="icon-person">
		<g>
			<path d="M52.65,125.2h19.9c3.3,0,6-2.7,6-6V86.301h3.399c3.301,0,6-2.701,6-6V43.2c0-3.3-2.699-6-6-6H43.25c-3.3,0-6,2.7-6,6
			v37.101c0,3.299,2.7,6,6,6h3.4V119.2C46.65,122.5,49.25,125.2,52.65,125.2z" />
			<circle cx="62.55" cy="15.7" r="15.7" />
		</g>
	</symbol>

	<symbol viewBox="0 0 397.061 397.061" id="icon-eventDetails">
		<g>
			<g>
				<g>
					<path d="M86.204,67.918h31.347c1.443,0,2.612-1.17,2.612-2.612V2.612c0-1.443-1.169-2.612-2.612-2.612H86.204
					c-1.443,0-2.612,1.17-2.612,2.612v62.694C83.592,66.749,84.761,67.918,86.204,67.918z" />
					<path d="M367.804,47.02h-38.661v16.718c0,9.927-7.314,19.853-17.241,19.853h-30.824c-10.637-0.754-19.099-9.216-19.853-19.853
					V47.02H135.837v16.718c-0.754,10.637-9.216,19.099-19.853,19.853H85.159c-9.927,0-17.241-9.927-17.241-19.853V47.02H29.257
					C15.9,47.305,5.221,58.216,5.224,71.576v64.261h386.612V71.576C391.84,58.216,381.161,47.305,367.804,47.02z" />
					<path d="M279.51,67.918h31.347c1.443,0,2.612-1.17,2.612-2.612V2.612c0-1.443-1.17-2.612-2.612-2.612H279.51
					c-1.443,0-2.612,1.17-2.612,2.612v62.694C276.898,66.749,278.067,67.918,279.51,67.918z" />
					<path d="M159.347,291.527l-7.314,42.318l37.616-19.853l3.657-1.045l3.657,1.045l37.616,19.853l-7.314-42.318
					c-0.279-2.456,0.478-4.917,2.09-6.792l30.825-29.257l-42.318-6.269c-2.474-0.395-4.609-1.948-5.747-4.18l-18.808-38.139
					l-18.808,38.139c-1.138,2.232-3.273,3.785-5.747,4.18l-42.318,6.269l30.825,29.257
					C158.869,286.609,159.626,289.07,159.347,291.527z" />
					<path d="M5.224,373.551c0.284,13.068,10.961,23.513,24.033,23.51h338.547c13.071,0.003,23.748-10.442,24.033-23.51V151.51H5.224
					V373.551z M102.4,247.641c0.834-2.854,3.312-4.919,6.269-5.224l53.812-8.359l24.033-48.588c2.159-3.751,6.951-5.041,10.702-2.882
					c1.198,0.69,2.192,1.684,2.882,2.882l24.033,48.588l53.812,8.359c2.957,0.305,5.436,2.371,6.269,5.224
					c0.948,2.795,0.124,5.885-2.09,7.837l-38.661,37.616l9.404,53.812c0.363,2.827-0.838,5.628-3.135,7.314
					c-1.373,0.986-3.012,1.532-4.702,1.567c-1.307,0.124-2.613-0.249-3.657-1.045l-48.065-25.078l-48.065,25.078
					c-2.62,1.596-5.958,1.388-8.359-0.522c-2.297-1.687-3.497-4.488-3.135-7.314l9.404-53.812l-38.661-37.616
					C102.276,253.526,101.452,250.435,102.4,247.641z" />
				</g>
			</g>
		</g>
	</symbol>

	<symbol viewBox="0 0 509.749 509.749" id="icon-budgetDetails">
		<path
			d="m396.891 268.139c2.868 27.226-.83 55.398-12.16 82.01v.01l39.33 50.795c3.52 4.547 10.12 5.171 14.479 1.421 35.316-30.392 58.684-74.262 62.147-123.628.403-5.747-4.221-10.607-9.982-10.607h-93.814z" />
		<path
			d="m298.141 10.004v124.205c33.32 14.42 60.98 39.53 78.64 70.97h114.182c5.646 0 10.215-4.673 9.997-10.315-4.065-105.586-88.248-189.569-192.395-194.851-5.682-.288-10.424 4.302-10.424 9.991z" />
		<path
			d="m234.514 450.78c-90.907.94-166.032-72.765-166.767-163.674-.741-91.62 73.582-166.398 165.035-166.398v-48.711c0-5.7-4.759-10.26-10.453-9.999-118.849 5.46-213.547 103.545-213.548 223.751 0 123.95 101.524 224.793 225.471 223.996 46.743-.301 90.074-14.912 125.844-39.67 4.706-3.257 5.696-9.812 2.192-14.338l-29.828-38.527c-27.29 20.734-61.193 33.19-97.946 33.57z" />
		<path
			d="m97.741 285.749c0 74.46 60.58 135.04 135.04 135.04 74.452 0 135.04-60.568 135.04-135.04 0-74.458-60.588-135.04-135.04-135.04-74.46 0-135.04 60.58-135.04 135.04zm150.04-89.71v14.31c15.378 0 28.991 6.541 38.59 16.78 5.66 6.04 5.35 15.54-.69 21.2-6.003 5.624-15.457 5.409-21.2-.69-4.3-4.58-10.16-7.24-16.09-7.29-12.138 0-20.343 0-32.43 0-8.407 0-15.2 6.811-15.2 15.2 0 8.38 6.82 15.2 15.21 15.2h33.63c24.92 0 45.2 20.28 45.2 45.2 0 23.286-17.911 45.21-47.02 45.21v14.3c0 8.28-6.71 15-15 15-8.28 0-15-6.72-15-15v-14.3c-10.566 0-20.976-1.144-33.17-9.6-6.8-4.72-8.49-14.07-3.77-20.87 4.72-6.81 14.07-8.5 20.87-3.78 3.6 2.52 8.071 4.192 12.52 4.25h35.37c8.353 0 15.2-6.744 15.2-15.21 0-8.38-6.82-15.2-15.2-15.2-14.882 0-17.949 0-33.63 0-24.93 0-45.21-20.28-45.21-45.2 0-23.359 17.975-45.2 47.02-45.2v-14.31c0-8.28 6.72-15 15-15 8.29 0 15 6.72 15 15z" />
	</symbol>

	<symbol viewBox="0 0 293.129 293.129" id="icon-facilitiesDetails">
		<g>
			<path
				d="M162.179,140.514c3.377-1.727,7.139-2.64,11.042-2.64c6.468,0,12.549,2.511,17.133,7.071l9.868-9.867
			c24.42,8.56,52.664,3.08,72.186-16.441c16.426-16.426,22.904-39.026,19.446-60.329c-0.381-2.346-2.042-4.281-4.303-5.011
			c-2.261-0.731-4.743-0.137-6.423,1.544l-14.652,14.652c-11.932,11.932-31.279,11.932-43.211,0
			c-11.933-11.932-11.933-31.279,0-43.211l14.652-14.652c1.681-1.681,2.28-4.163,1.548-6.425c-0.731-2.263-2.669-3.92-5.016-4.301
			c-21.302-3.458-43.903,3.02-60.328,19.446c-19.812,19.812-25.144,48.604-16.032,73.269l-21.402,21.402L162.179,140.514z" />
			<path d="M123.179,179.296l-25.385-25.385L9.029,242.675c-11.542,11.542-11.542,30.255,0,41.797
			c11.542,11.542,30.255,11.542,41.797,0l76.521-76.52C119.629,200.193,118.238,188.479,123.179,179.296z" />
			<path d="M179.795,155.597c-1.815-1.815-4.195-2.723-6.574-2.723s-4.759,0.908-6.574,2.723l-5.299,5.299L66.956,66.504l4.412-4.412
			c4.02-4.019,3.521-10.686-1.061-14.06L31.795,19.669c-3.701-2.725-8.837-2.338-12.087,0.912L3.356,36.934
			c-3.25,3.25-3.637,8.387-0.912,12.087l28.362,38.512c3.374,4.581,10.037,5.085,14.06,1.061l4.412-4.413l94.392,94.392l-5.672,5.672
			c-3.631,3.631-3.631,9.517,0,13.148l87.079,87.079c11.542,11.542,30.255,11.542,41.797,0c11.542-11.542,11.542-30.255,0-41.797
			L179.795,155.597z" />
		</g>
	</symbol>

	<symbol viewBox="0 0 24 24" id="icon-speakerDetails">
		<path
			d="m16.25 24h-8.5c-.37 0-.685-.27-.741-.636l-1-6.5c-.033-.216.029-.437.172-.603s.35-.261.569-.261h10.5c.219 0 .427.096.569.262s.205.386.172.603l-1 6.5c-.056.365-.371.635-.741.635z" />
		<circle cx="12" cy="4" r="4" />
		<path
			d="m23.217 13c-.159-2.217-1.351-4.269-3.218-5.51-.005-.824-.674-1.49-1.499-1.49-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5c.274 0 .527-.079.748-.207 1.407.969 2.316 2.522 2.469 4.207h-1.777c-.36-2.26-2.32-4-4.69-4h-6.5c-2.37 0-4.33 1.74-4.69 4h-3.31c-.531 0-.874.538-.71 1.01l2 5.5c.11.29.39.49.71.49h1.72l-.44-2.83c-.12-.8.11-1.6.63-2.21.446-.535 1.235-.96 2.09-.96h10.5c.755 0 1.517.311 2.09.96.52.61.75 1.41.63 2.21l-.44 2.83h1.72c.32 0 .6-.2.71-.49l2-5.5c.158-.455-.144-1.01-.743-1.01z" />
	</symbol>

	<symbol viewBox="0 0 512.004 512.004" id="icon-mediaDetails">
		<path
			d="m487.012 300.744 16-7c7.59-3.32 11.051-12.165 7.73-19.754-3.32-7.59-12.166-11.053-19.754-7.73l-16 7c-7.59 3.32-11.051 12.165-7.73 19.754 3.323 7.595 12.172 11.049 19.754 7.73z" />
		<path
			d="m503.012 400.26-16-7c-7.589-3.32-16.434.14-19.754 7.73-3.321 7.59.14 16.434 7.73 19.754l16 7c7.589 3.321 16.435-.141 19.754-7.73 3.321-7.59-.14-16.434-7.73-19.754z" />
		<path d="m497 332.002h-16c-8.284 0-15 6.716-15 15s6.716 15 15 15h16c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
		<path
			d="m289.46 257.002c120.795-59.905 112.715-56.001 115.54-57.06v-139.94c0-24.81-20.19-45-45-45h-315c-24.81 0-45 20.19-45 45v241c0 24.81 20.19 45 45 45h106v-24c0-35.84 29.16-65 65-65zm-140.252-3.084c-9.952 4.977-21.708-2.278-21.708-13.417v-120c0-11.148 11.757-18.392 21.708-13.417l120 60c11.042 5.521 11.033 21.317 0 26.833z" />
		<path d="m157.5 216.231 71.459-35.729-71.459-35.73z" />
		<path
			d="m216 287.002c-19.299 0-35 15.701-35 35v50c0 16.7 11.76 30.697 27.432 34.167l-12.228 73.368c-1.524 9.14 5.536 17.466 14.796 17.466h60c7.271 0 13.496-5.215 14.769-12.375l13.55-76.22 1.681.834v-124.479l-4.515 2.239z" />
		<path
			d="m414.336 228.563-83.336 41.324v154.229l83.336 41.324c9.934 4.927 21.664-2.306 21.664-13.438v-210c0-11.124-11.713-18.374-21.664-13.439z" />
	</symbol>
</svg>
