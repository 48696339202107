<div class="admin-page">
	<app-side-panel></app-side-panel>
	<div class="admin-content">
		<div class="admin-header">
			<h1 class="admin-title">Posters</h1>
			<div class="admin-headBar">
				<h1>{{ auth.userRole || "admin" }}</h1>
			</div>
		</div>
		<div class="admin-body">
			<!--[formGroup]-->
			<form [formGroup]="postDetails">
				<!--formgroupname-->
				<div class="new-post">
					<h1><b>New Post Approval</b></h1>
					<br />
					<p>Social Media Account</p>
					<div class="poster name">
						<input
							type="text"
							name="postAccount"
							formControlName="postAccount"
						/>
					</div>
					<p>Drive Link to Photos</p>
					<div class="poster name">
						<input
							type="text"
							name="postDriveLink"
							formControlName="postDriveLink"
						/>
					</div>

					<br />

					<!--formcontrolname-->

					<p>Post Description</p>
					<textarea
						id="comms"
						name="comms"
						placeholder="Give us a brief description of your post!"
						formControlName="postDescription"
						rows="5"
						cols="65"
					></textarea>
					<br />

					<div class="errorMsg">
						<p>{{ errorMsg }}</p>
					</div>
					<div class="success">
						<p>{{ successMsg }}</p>
					</div>

					<button
						class="uploadbtn"
						type="submit"
						(click)="insertData()"
					>
						Upload
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
