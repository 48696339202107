<app-nav></app-nav>
<a href="" routerLink="/pawprints">
	WRITE A PETITION!
	<img src="assets/img/double-arrows.png" />
</a>
<div class="container">
	<div class="petition-box" *ngFor="let petition of p">
		<img src="../../../assets/admin/feedback/paw rit.png" />
		<h3>
			Name: <span> {{ petition.name }}</span>
		</h3>
		<h3>
			Email: <span>{{ petition.email }}</span>
		</h3>
		<h3>
			Feedback/Complaint
			<span> {{ petition.prompt }}</span>
		</h3>
	</div>

</div>
