import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-side-panel',
	templateUrl: './side-panel.component.html',
	styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit {
	route: string;

	constructor(public auth: AuthService, private router: Router) {}

	ngOnInit(): void {
		this.route = this.router.url;
		this.auth.checkUserState(this.route);

		// this.seeUser();
	}

	logout(): void {
		this.auth.logout();
	}

	seeUser() {
		this.auth.user.updateProfile({
			displayName: 'itCoordinator',
		});
	}
}
