<app-nav></app-nav>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<div class="page">
	<div class="home-banner">
		<div class="bg-title">
			<!-- <h1>STUDENT</h1>
			<h1 class="gov">GOV.</h1> -->
		</div>

		<!-- <img src="assets/img/Homepage_pic.jpg" class="banner-img" /> -->

		<div class="banner-title">
			<p><b>HI THERE,</b></p>
			<h1 class="main-title">WE ARE</h1>
			<h1 class="secondary-title">YOUR VOICE.</h1>
			<a href="" routerLink="/about">
				GET IN TOUCH
				<img src="assets/img/double-arrows.png" />
			</a>
		</div> 

	<!-- Slideshow container -->
<div class="slideshow-container" #slides>
	<!-- Slide 1 -->
	<div class="slides fade">
	  <img src="assets/img/homepage_pic1.png">
	  <div class="text"></div>
	</div>
  
	<!-- Slide 2 -->
	<div class="slides fade">
	  <img src="assets/img/homepage_pic2.png">
	  <div class="text"></div>
	</div>
  
	<!-- Slide 3 -->
	<div class="slides fade">
	  <img src="assets/img/homepage_pic3.png">
	  <div class="text"></div>
	</div>
  
	<!-- Next and previous buttons -->
	<!-- <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
	<a class="next" (click)="plusSlides(1)">&#10095;</a> -->
  </div>
  <br>
  
  <!-- The dots/circles -->
  <!-- <div style="text-align:center">
	<span class="dot" (click)="currentSlide(1)"></span>
	<span class="dot" (click)="currentSlide(2)"></span>
	<span class="dot" (click)="currentSlide(3)"></span>
  </div> -->

		<div class="banner-indicator">
			<img src="assets/img/light_grey_arrow.png" width="6.5%"/>
		</div>
	</div>

	<section class="upcoming-events">
		<h1 class="section-title">UPCOMING EVENTS</h1>

		<div class="no-event" *ngIf="latestEvents.length == 0">
			NO UPCOMING EVENTS YET
		</div>

		<div class="event" *ngFor="let event of latestEvents">
			<div class="event-decor event-section">
				<div class="point"></div>
				<div class="line"></div>
			</div>
			<div class="event-tile event-section">
				<p class="date">
					{{ event.date }} <span class="time">{{ event.time }}</span>
				</p>
				<h2 class="title">{{ event.eventName }}</h2>
			</div>
			<div class="event-banner event-section">
				<img class="banner" [src]="event.eventImg" />
			</div>
			<div class="event-info event-section">
				<p>{{ event.eventDescription }}</p>
				<a [href]="event.zoomlink">JOIN EVENT</a>
			</div>
		</div>
	</section>

	<section class="mvSection">
		<div class="missionSection">
			<div class="missionImg">
				<img src="assets/SG mission.png" class="mission" />
			</div>
			<div class="missionText">
				<p class="mission">
					The Student Government's mission for this academic year is
					to set up a foundation for the efficient running of all the
					services we provide by developing multiple platforms and
					avenues for student outreach and campus improvement by
					becoming involved in every aspect of campus life that
					directly affects the students.
				</p>
			</div>
		</div>

		<div class="missionSection">
			<div class="missionText">
				<p class="vision">
					The Student Government’s vision is to represent the
					interests of the student body through the implementation of
					innovative programs, services, and initiatives that enrich
					student life
				</p>
			</div>
			<div class="missionImg">
				<img src="assets/SG vision.png" class="vision" />
			</div>
		</div>
	</section>
</div>

<app-footer></app-footer>