<app-nav></app-nav>

<div class="page">
	<div class="wrapper">
		<div class="top-rank">
			<div class="top rank2">
				<div class="in1"></div>
				<div class="in2"></div>
				<div class="rank-wrapper">
					<div class="rank">
						<p>2<span>nd</span></p>
					</div>
					<div class="rank-info">
						<div class="name">{{ rank2.name }}</div>
						<div class="points">{{ rank2.points }} Points</div>
					</div>
				</div>
			</div>
			<div class="top rank1">
				<div class="in1"></div>
				<div class="in2"></div>
				<div class="rank-wrapper">
					<div class="rank">
						<p>1<span>st</span></p>
					</div>
					<div class="rank-info">
						<div class="name">{{ rank1.name }}</div>
						<div class="points">{{ rank1.points }} Points</div>
					</div>
				</div>
			</div>
			<div class="top rank3">
				<div class="in1"></div>
				<div class="in2"></div>
				<div class="rank-wrapper">
					<div class="rank">
						<p>3<span>rd</span></p>
					</div>
					<div class="rank-info">
						<div class="name">{{ rank3.name }}</div>
						<div class="points">{{ rank3.points }} Points</div>
					</div>
				</div>
			</div>
		</div>

		<h1 class="header">
			<hr />
			SPIRIT POINTS LEADERBOARD
			<hr />
		</h1>

		<div class="rankings">
			<div
				class="rankings-row"
				*ngFor="let ranker of rankers; let i = index"
				[ngStyle]="{
					display: i != 0 && i != 1 && i != 2 ? 'flex' : 'none'
				}"
			>
				<div class="position">
					{{ i + 1 }} {{ getRankSuffix(i + 1) }}
				</div>
				<div class="name">{{ ranker.name }}</div>
				<div class="points">{{ ranker.points }} Points</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>
