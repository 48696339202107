<app-nav></app-nav>

<div class="page">
	<h1 class="header">
		<hr>
		UPCOMING EVENTS
		<hr>
	</h1>

	<div class="container">
		<div class="child" *ngFor="let day of eventsCollection.days; let i = index">
			<h1>{{ day }}</h1>
			<h2>{{ eventsCollection.dates[i] }}</h2>
			<div class="event" *ngFor="let event of eventsCollection.events[i]" [ngStyle]="{
					'background-color': colors[i % colors.length]
				}" (click)="openEvent(event)">
				<p class="event-time">{{ event.time }}</p>
				<h3 class="event-name">{{ event.eventName }}</h3>
			</div>
		</div>
	</div>

	<div class="no-event" *ngIf="empty">NO UPCOMING EVENTS YET</div>

	<div class="modal-container" *ngIf="modalToggle" (click)="modalToggle = false">
		<div class="modal" (click)="$event.stopPropagation()">
			<div class="close" (click)="modalToggle = false">X</div>
			<img class="modal-img" id="poster" [src]="eventImage" />
			<div class="modal-info">
				<h1>{{ eventName }}</h1>
				<p class="modal-p name">Time: {{ eventTime }}</p>
				<p class="description">{{ eventDesc }}</p>
				<a [attr.href]="eventZoom">{{ eventZoom }}</a>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>