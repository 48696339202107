import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
	selector: 'app-clubs',
	templateUrl: './clubs.component.html',
	styleUrls: ['./clubs.component.scss'],
})
export class ClubsComponent implements OnInit {
	name: any;
	description: string;
	president: string;
	vice_president: string;
	contact_email: string;
	insta: string;
	selectedClub: string = "hi";

	modalToggle: boolean = false;
	eventImage: any;

	private ASSET_BASE = 'assets/clubs/';

	/*
		object with logos and their respective picture
		TODO: test this
		TODO: have this loaded from a JSON file
		TODO: remove need for mapping in favour of 1:1 club names to picture names
	*/
	club_logos: { [id: string]: string } = {
		'American Society of Mechanical Engineering (ASME)':
			'ASME RITD Logo.png',
		'The Anime Club': 'animeClub.png',
		'Chess Club': 'Chess_Logo.png',
		'Finance Club': 'finance_logo.png',
		//'Gaming Club': 'Gaming RITD Logo.png',
		LendAHand: 'Lend a hand.png',
		//'Music Club': 'Music RITD Logo.png',
		//'TEDX RIT': 'tedx3.png',
		'Marketing Club': 'Marketing.png',
		'The Industrials': 'Industrials.jpg',
		'The Emirati Club': 'Emirati Club logo.png',
		//'Arabic Culture Club': 'ACC.png',
		Levant: 'Levant.png',
		//Puzzlers: 'Puzzle.png',
		//'Yacht Club de Monaco': 'monaco.jpeg',
		//'SpiRIT MUN': 'spiritMUN.PNG',
		//DEDSEC: 'dedsec.jpg',
		// 'The Running Club': 'RunningClub_Logo.png',
		'The Powerlifting Club': 'TPC Logo.png',
		Aegis: 'aegis logo.png',
		'IEEE Student Branch': 'ieee.png',
		'Dance Club': 'dance_club.png', 
		'Model United Nations': 'mun_logo.png',
		'Arts Society': 'arts_society.png',
		'TechSphere': 'techsphere_logo.jpeg',
		'RIT Gamers Guild': 'gamers_guild_logo.png',
		'Desi Club': 'desi_logo.png',
		'Graphic Design Club': 'gd_logo.jpeg',
		// 'The Debate Club': 'debate_logo.png'
	};

	getLogo(clubName: string): string | null {
		console.log(clubName);
		const picture_source = this.club_logos[clubName];
		if (picture_source !== undefined) {
			return `${this.ASSET_BASE}${picture_source}`;
		}
		return null;
	}

	// Declaration of an Array
	clubList: {
		name: string;
		president: string;
		vicePresident: string;
		description: string;
		contactEmail: string;
		logo: string;
	}[] = [];

	constructor(private db: AngularFirestore) {}
	
	ngOnInit(): void {
		this.getClubsInfo('WIE');
	}

	openEvent(clubName: string): void {
		this.getClubsInfo(clubName);
		this.modalToggle = true;
	}

	/* This code retrieves information about different clubs from a database, 
	including their names, presidents, vice presidents, descriptions, contact emails, 
	and logos (if available). It then stores this information in the clubList list. 
	The getLogo function is used to obtain the logo for each club, and if the logo 
	is not available, the club's information is skipped and not added to the list. */
	getClubsInfo(clubName: any) {
		this.db
			.collection('Clubs/')
			.get()
			.forEach((snapshot) =>
				snapshot.docs.forEach((doc) => {
					this.db
						.collection('Clubs/' + doc.id + '/Information')
						.get()
						.forEach((snapshot) =>
							snapshot.docs.forEach((doc) => {
								const data: any = doc.data();
								const logo = this.getLogo(data.clubName);
								if (!logo) return;
								this.clubList.push({
									name: data.clubName,
									president: data.president,
									vicePresident: data.vice_president,
									description: data.clubDescription,
									contactEmail: data.contact_email,
									logo: logo,
								});
							})
						);
				})
			);
	}

	getDesc(cName: string): string | null {
		let e: string = "";
		for (const element of this.clubList) {
			e += element.name;
		  }
		  return e 
		/*const element = this.clubList.find((element) => element.name === cName);
  		return element ? element.description : null;*/
	}
	
	/* Added */
	popup(): void {
		const popupElement = document.getElementById("popup");
		const cName: string = document.querySelector(".clubName").textContent;
		const desc = this.getDesc(cName);
		if (popupElement) {
		  popupElement.style.display = "block";
		  //document.getElementById("popup").textContent = 'HI' // desc ? desc : "Description not found."; // returns club name
		}
	}
  
	close(): void {
		  document.getElementById("popup").style.display = "none";
	} 
}
