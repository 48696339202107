import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DatePipe } from '@angular/common';
import firebase from 'firebase/app';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
	selector: 'app-pawprints',
	templateUrl: './pawprints.component.html',
	styleUrls: ['./pawprints.component.scss'],
})
export class PawprintsComponent implements OnInit {
	name: any;
	errorMsg: any;
	successMsg: any;

	constructor(private db: AngularFirestore) {}

	ngOnInit(): void {}

	insertPetition() {
		if (this.petitionForm.invalid) {
			this.errorMsg = 'Please fill all necessary details';
			return;
		}
		try {
			this.db.collection('Pawprints').doc().set({
				name: this.petitionForm.value.petitionName,
				email: this.petitionForm.value.petitionEmail,
				prompt: this.petitionForm.value.prompt,
			});
			this.errorMsg = '';
			this.successMsg = 'YOUR INFORMATION HAS BEEN SUBMITTED!';
			this.petitionForm.reset();
		} catch (error) {}
	}

	petitionForm = new FormGroup({
		petitionName: new FormControl('', Validators.required),
		petitionEmail: new FormControl('', Validators.required),
		prompt: new FormControl(''),
	});
}
