export type sp = {
	name: string;
	points: number;
	ritID: string;
	date: string;
};

export type event = {
	eventName: string;
	eventDescription: string;
	eventImg: string;
	date: string;
	time: string;
	zoomlink: string;
};

export type feedbacks = {
	name: string;
	email: string;
	comments: string;
	targetGroup: string;
};

export type petitions = {
	name: string;
	email: string;
	prompt: string;
};

export type eventsCollection = {
	days?: string[];
	dates?: string[];
	events?: event[][];
};

export type posterDoc = {
	id: string;
	createdOn: Date;
	type: string;
	data: poster;
};

export type poster = {
	posterName: string;
	posterEventDate: string;
	posterLink: string;
	posterComments: string;
	dateCreated: firebase.default.firestore.Timestamp;
	clubsDirector: boolean;
	studentAffairs: boolean;
	marketing: boolean;
	rejectionComment?: string;
};

export type postDoc = {
	id: string;
	createdOn: Date;
	type: string;
	data: post;
};
export type post = {
	postAccount: string;
	postDriveLink: string;
	postDescription: string;
	dateCreated: firebase.default.firestore.Timestamp;
	clubsDirector: boolean;
	studentAffairs: boolean;
	marketing: boolean;
	rejectionComment?: string;
};

export type evaluationObject = {
	name: string;
	email: string;
	role: string;
	directorEvaluation: number;
	evaluations: number; // 0 -> 80 -> 160
	totalEvaluations: number; // 0 -> 100 -> 200
	calculatedEvaluations: number;
	feedback: evaluationFeedback[];
};

export type evaluationFeedback = {
	name: string;
	email: string;
	role: string;
	attendance: number;
	focus: number;
	productivity: number;
	stressManagement: number;
	teamwork: number;
	attitude: number;
	integrity: number;
	communication: number;
	uniSGOriented: number;
	improvement: number;
	comment: string;
};

export type userObject = {
	uid: string;
	name: string;
	email: string;
	role: string;
	roleName: string;
};

export type eafDoc = {
	id?: string;
	createdOn: Date;
	type: string;
	data: eaf;
};

export type eaf = {
	dateCreated: firebase.default.firestore.Timestamp;
	userSubmitted: string;
	userSubmittedEmail: string;
	financeDirector: boolean;
	sgPresident: boolean;
	studentAffairs: boolean;
	clubsDirector: boolean;
	moneyReadyforCollection: boolean;
	requestorName: string;
	requestorContact: number;
	requestorEmail: string;
	alternateName: string;
	alternateContact: number;
	alternateEmail: string;
	clubOrganization: string;
	eventName: string;
	eventLocation: string;
	eventDate: string;
	eventStartTime: string;
	eventEndTime: string;
	eventDescription: string;
	eventSpeaker: string;
	budgetRequest: string;
	budgetDetail: string;
	budgetCollectionDate: string;
	tables: number;
	chairs: number;
	others: string;
	speakerName: string;
	speakerPosition: string;
	speakerContact: number;
	speakerCompany: string;
	speakerEmail: string;
	mediaEmailContent: string;
	sgPoster: string;
	mediaAdvertising: string;
	mediaRights: string;
};

export type eafRoles = 'studentAffairs' | 'financeDirector' | 'clubsDirector';

export type posterRoles = 'clubsDirector' | 'studentAffairs' | 'marketing';

export const sgRole = {
	sgPresident: 'President',
	sgVicePresident: 'Vice President',
	itDirector: 'IT Director',
	mediaDirector: 'Media Director',
	eventsDirector: 'Events Director',
	financeDirector: 'Finance Director',
	clubsDirector: 'Clubs Director',
	itCoordinator: 'IT Coordinator',
	mediaCoordinator: 'Media Coordinator',
	eventsCoordinator: 'Events Coordinator',
	clubsCoordinator: 'Clubs Coordinator',
	graphicDesigner: 'Graphic Designer',
	coopOfficer: 'Coop Officer',
	studentAffairs: 'Student Affairs',
	secretary: 'Secretary',
	clubs: 'Club',
	club: 'Club',
	marketing: 'Marketing',
	computingSenator: 'Computing Senator',
	engineeringSenator: 'Engineering Senator',
	businessSenator: 'Business Senator',
	electricalSenator: 'Electrical Senator',
	photographer: 'Photographer',
	freshmenCoordinator: 'Freshmen Coordinator',
};

export type sgRoles =
	| 'sgPresident'
	| 'sgVicePresident'
	| 'itDirector'
	| 'mediaDirector'
	| 'eventsDirector'
	| 'financeDirector'
	| 'clubsDirector'
	| 'itCoordinator'
	| 'mediaCoordinator'
	| 'eventsCoordinator'
	| 'clubsCoordinator'
	| 'graphicDesigner'
	| 'coopOfficer'
	| 'studentAffairs'
	| 'secretary'
	| 'club'
	| 'marketing'
	| 'computingSenator'
	| 'businessSenator'
	| 'engineeringSenator'
	| 'photographer'
	| 'electricalSenator'
	| 'freshmenCoordinator';
