<footer>
	<div class="col col1">
		<img src="assets/img/sgmain-logo-white.png" alt="" />

		<p>
			Copyright © Rochester Institute of Technology. All Rights Reserved.
		</p>
	</div>

	<div class="col col2">
		<a href="" routerLink="/">HOME</a>
		<a href="" routerLink="/events">EVENTS</a>
		<a href="" routerLink="/promotions">PROMOTIONS</a>
		<!-- <a href="" routerLink="/spiritpoints">SpiRIT POINTS</a> -->
		<a href="" routerLink="/clubs">CLUBS</a>
		<a href="" routerLink="/about">ABOUT</a>
		<a href="" routerLink="/feedback">FEEDBACK</a>
	</div>

	<div class="col col3">
		<div class="bracket">
			<div class="location">
				<p>Silicon Oasis</p>
				<p>Dubai, UAE.</p>
			</div>

			<div class="socials">
				<!-- <img src="assets/img/facebook.svg" alt="" />
				<img src="assets/img/linkedin.svg" alt="" /> -->
				<a href="https://instagram.com/ritd_sg?igshid=9kck3aek8rcp" target="_blank"><img
						src="assets/img/instagram.svg" alt="" /></a>
			</div>
		</div>
		<iframe
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.137919343838!2d55.3872903154644!3d25.13102764054789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f651a66cb419d%3A0xb8b8d2d1353d66f9!2sRIT%20Dubai%20New%20Campus!5e0!3m2!1sen!2sae!4v1608809417005!5m2!1sen!2sae"
			title="map" style="border: 0" allowfullscreen="true" aria-hidden="false" tabindex="0">
		</iframe>
	</div>
</footer>
