<app-nav></app-nav>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<div class="page">
	<h1 class="header">
		<hr />
		CLUBS AND ORGANIZATIONS
		<hr />
	</h1>

	<div class="clubs">
		<div class="club" *ngFor="let club of clubList" (click)="popup(); selectedClub = club.name;">
			<img src="{{ club.logo }}" />
			<div class="clubInfo">
				<div class="clubDetails">
					<h1 class="clubName" id="cName">{{ club.name }}</h1>
					<!-- <p class="clubDescription">{{ club.description }}</p> -->
				</div>
				<!-- <div class="clubContact">
					<p class="clubPresident">{{ club.president }}</p>
					<a href="mailto:{{ club.contactEmail }}" id="emails">{{ club.contactEmail }}</a>
					<br />
				</div> -->
			</div>
		</div>
	</div>
</div>

<div id="popup" [ngSwitch]="selectedClub">
	<h2>Description</h2>
	<div *ngSwitchCase="'American Society of Mechanical Engineering (ASME)'">
		<p style="width:100%; height: 350px;">
			The mission of the ASME club is to bring together students who are interested in engineering and give them opportunities to learn and explore, regardless of their specific field of study. We hope that with our activities all the students can gain an exposure to the exciting world of engineering while improving the field of mechanical engineering and helping each other learn and grow.
		</p>
		<h3>Contact Information:</h3>
		<p>Mahir Mohamed Ibrahim</p>
		<a href="mailto:mm9782@rit.edu">mm9782@rit.edu</a>
		<a href="https://forms.gle/kq7DpryrD22MbW4v8" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase="'Aegis'">
		<p style="width:100%; height: 350px;">
			1- Connect the students with various specialists inside and outside UAE through social media, zoom, and websites.<br>
			2- Compile a list of facilities and programs that the students can use for reference as to where they could practice self defense and fitness, comparing their differences in prices and services.<br>
			3- Act as a research base for students interested in learning about different aspects of self-defense.<br>
			4- Provide the students with equipment that they can borrow to practice and review the different options available
		</p>
		<h3>Contact Information:</h3>
		<p>{{ clubList[1].president }}</p>
		<a href="mailto:{{ clubList[1].contactEmail }}">{{ clubList[1].contactEmail }}</a>
		<button class="signUp">SIGN UP</button>
	</div>
	<div *ngSwitchCase="'The Anime Club'">
		<p style="width:100%; height: 350px;">To create a community where people who enjoy anime and anime related culture can talk about it and share different opinions and experiences.</p>
		<h3>Contact Information:</h3>
		<p>Ayemhoba Donald</p>
		<a href="mailto:doa8542@rit.edu">doa8542@rit.edu</a>
		<a href="https://chat.whatsapp.com/JwNI5WvalBo02RRralwY7i" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<!-- <div *ngSwitchCase="'Arabic Culture Club'">
		<p style="width:100%; height: 350px;">Learn about the Arabic culture, Arabic language, history and more!! Here
			we do several events to spread awareness about different aspects of the Arabic and Islamic culture. Join us
			to
			celebrate the various cultural and Islamic occasions.</p>
		<h3>Contact Information:</h3>
		<p>{{ clubList[3].president }}</p>
		<a href="mailto:{{ clubList[3].contactEmail }}">{{ clubList[3].contactEmail }}</a>
	</div> -->
	<div *ngSwitchCase="'Chess Club'">
		<p style="width:100%; height: 350px;">
			Our main mission is to create a welcoming community for people of all skill levels to enjoy playing chess
		</p>
		<h3>Contact Information:</h3>
		<p>Syed Basit Hussain</p>
		<a href="mailto:sbh7790@rit.edu">sbh7790@rit.edu</a>
		<a href="https://forms.gle/erNn7hW5e28JZ8y97" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<!-- <div *ngSwitchCase="'DEDSEC'">
		<p style="width:100%; height: 350px;">DEDSEC is where you can turn from a normal person who only uses a computer
			to browse
			into a hacker capable of defending and accessing all the data around you as long as there is a connection.
			The
			club's is to help everyone learn the concepts of Cyber Security, and penetration testing to create a
			generation
			that is capable of being the first defense line against hackers in any place and under any circumstances.
		</p>
		<h3>Contact Information:</h3>
		<p>{{ clubList[5].president }}</p>
		<a href="mailto:{{ clubList[5].contactEmail }}">{{ clubList[5].contactEmail }}</a>
	</div> -->
	<div *ngSwitchCase="'Finance Club'">
		<p style="width:100%; height: 350px;">The Finance Club's primary mission is to educate, engage, and empower university students in the realm of finance.</p>
		<h3>Contact Information:</h3>
		<p>Samera Ahmadi</p>
		<a href="mailto:smd1827@g.rit.edu">smd1827@g.rit.edu</a>
		<button class="signUp">SIGN UP</button>
	</div>
	<!-- <div *ngSwitchCase="'Gaming Club'">
		<p style="width:100%; height: 350px;">A proponent of fun times we at the gaming club strive to help get the edge
			off
			the high-stress lifestyle of an RIT student by attempting to provide activities and events that would help
			our
			members and the community to chill down and relax and use their competitive spirit in a much lower stakes
			gambits.</p>
		<h3>Contact Information:</h3>
		<p>{{ clubList[7].president }}</p>
		<a href="mailto:{{ clubList[7].contactEmail }}">{{ clubList[7].contactEmail }}</a>
	</div> -->
	<div *ngSwitchCase="'LendAHand'">
		<p style="width:100%; height: 350px;">
			Our club aims to bring the community together, whether it be volunteering, workshops, charity or fun events for students to enjoy. This coming year we want to provide students with more beneficial workshops and Volunteering/charity opportunities while making it enjoyable for the community.
		</p>
		<h3>Contact Information:</h3>
		<p>Zainab Khan</p>
		<a href="mailto:zmk3870@rit.edu">zmk3870@rit.edu</a>
		<a href="https://chat.whatsapp.com/HS3YEaoWyzr9kRzhXhkhYr" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase="'Levant'">
		<p style="width:100%; height: 350px;">
			The Levant Club aims to showcase and spread knowledge of the outstanding Levantines culture. This includes inviting other cultures to our events and celebrations to create diverse interactions, letting our participants feel the sense of life they may miss out back in their homeland by participating in the events we create which allows then to live the moment and create memories, and creating a fun and interactive environment for RIT students and staff.
		</p>
		<h3>Contact Information:</h3>
		<p>Diana Shhadi</p>
		<a href="mailto:di7728@g.rit.edu">di7728@g.rit.edu</a>
		<a href="https://docs.google.com/forms/d/12bDTUm-YbkH1fXglfflZ8F6I0k-TTM4lPr9GKjc5Hm0/edit" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase="'Marketing Club'">
		<p style="width:100%; height: 350px;">Our mission is to make marketing accessible and enjoyable for students. Whether they're beginners or experts, we're here to help them learn, connect, and have a great time.</p>
		<h3>Contact Information:</h3>
		<p>Osama Ghanem</p>
		<a href="mailto:ong7227@rit.edu">ong7227@rit.edu</a>
		<a href="https://forms.gle/zLzJqzGxp6vrSk4q8" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase="'Music Club'">
		<p style="width:100%; height: 350px;">Our mission is to empower individuals with a passion for music, fostering a vibrant community where musical expression knows no bounds. Through a diverse range of initiatives, we aim to create an environment that encourages artistic growth, collaboration, and cultural appreciation.</p>
		<h3>Contact Information:</h3>
		<p>{{ clubList[11].president }}</p>
		<a href="mailto:{{ clubList[11].contactEmail }}">{{ clubList[11].contactEmail }}</a>
	</div>
	<!-- <div *ngSwitchCase="'TEDX RIT'">
		<p style="width:100%; height: 350px;">A TEDx event consists of short, carefully prepared talks that are
			idea-focused, and cover a wide range of subjects to foster learning, inspiration, and wonder – and provoke
			conversations that matter. Well reputed speakers give their speech on a topic that connects with the main
			theme
			of
			the event. The TEDx event is organised every year and the event is planned and coordinated by our
			productions
			team,
			operations team, curation team and the marketing team.</p>
		<h3>Contact Information:</h3>
		<p>{{ clubList[12].president }}</p>
		<a href="mailto:{{ clubList[12].contactEmail }}">{{ clubList[12].contactEmail }}</a>
		<button class="sign up">SIGN UP</button>
	</div> -->
	<div *ngSwitchCase="'The Emirati Club'">
		<p style="width:100%; height: 350px;">The club mission is to spread the Emirati culture around RIT Dubai and celebrate the UAE’s heritage.</p>
		<h3>Contact Information:</h3>
		<p>Saeed Al Shehhi </p>
		<a href="mailto:saa7436@rit.edu">saa7436@rit.edu</a>
		<button class="signUp">SIGN UP</button>
	</div>
	<div *ngSwitchCase="'The Industrials'">
		<p style="width:100%; height: 350px;">Our club’s mission is to bridge the gap between academia and industry, building a nurturing environment where students can grow, learn and thrive as future industrial leaders. We are committed to promoting collaboration, knowledge sharing, and professional development. We connect members of The Industrials Club with IISE, helping them become offcial members of the institution, giving them access to all the benefits gained from it, and connecting them to a large network of professionals.</p>
		<h3>Contact Information:</h3>
		<p>Tala Taha</p>
		<a href="mailto:tat4063@rit.edu">tat4063@rit.edu</a>
		<button class="signUp">SIGN UP</button>
	</div>
	<div *ngSwitchCase=" 'The Powerlifting Club'">
		<p style="width:100%; height: 350px;">At the Rochester Institute of Technology, Dubai Powerlifting Club, our mission is to cultivate a community of individuals committed to the pursuit of strength, physical well-being, and
			personal growth. We provide a supportive and inclusive environment where RIT Dubai students and enthusiasts of all skill levels come together to achieve their powerlifting goals.</p>
		<h3>Contact Information:</h3>
		<p>Abdel Rahman Abualfeilat</p>
		<a href="mailto:aba9976@rit.edu">aba9976@rit.edu</a>
		<button class="signUp">SIGN UP</button>
	</div>
	<!-- <div *ngSwitchCase=" 'The Running Club'">
		<p style="width:100%; height: 350px;">• To provide a more balanced running community at RIT<br>
			•	To offer support and encouragement through a team atmosphere<br>
			•	To bring positive recognition to RIT through racing and by being active in the community<br>
			•	Personal improvement of each runner's ability with respect to his/her own goals
			</p>
		<h3>Contact Information:</h3>
		<p>Yash Kalvani</p>
		<a href="mailto:yk1956@rit.edu">yk1956@rit.edu</a>
		<button class="sign up">SIGN UP</button>
	</div> -->
	<!-- <div *ngSwitchCase=" 'Yacht Club de Monaco'">
		<p style="width:100%; height: 350px;">{{ clubList[19].description }}</p>
		<h3>Contact Information:</h3>
		<p>{{ clubList[19].president }}</p>
		<a href="mailto:{{ clubList[17].contactEmail }}">{{ clubList[19].contactEmail }}</a>
	</div> -->
	<div *ngSwitchCase=" 'IEEE Student Branch'">
		<p style="width:100%; height: 350px;">
			IEEE Student Branch; an IEEE Student Branches provide IEEE Student members a networking opportunity to meet and learn from fellow students, as well as faculty members and professionals in the field to share their interests, future professions and ideas. In addition to improving their soft and hard skills.
		</p>
		<h3>Contact Information:</h3>
		<p>Samar Abdelhamid</p>
		<a href="mailto:sa2205@rit.edu">sa2205@rit.edu</a>
		<a href="https://chat.whatsapp.com/BdK4aFMBCpdL7ZRYxWcZOt" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase=" 'Dance Club'">
		<p style="width:100%; height: 350px;">
			Our mission is to create an inclusive and vibrant community that transcends language barriers and cultural differences. We strive to provide a platform for students to express themselves, share their diverse dance traditions, and learn from one another.
		</p>
		<h3>Contact Information:</h3>
		<p>Shree Ganesan</p>
		<a href="mailto:ssg5345@rit.edu">ssg5345@rit.edu</a>
		<button class="signUp">SIGN UP</button>
	</div>
	<div *ngSwitchCase=" 'Arts Society'">
		<p style="width:100%; height: 350px;">
			Establish an active and beginner-friendly Arts Community in RIT Dubai. <br> 
			Organize events that encourage artistic involvement and expression.<br>
			Maintain a social media presence exhibiting RIT Dubai’s artistic endeavors.<br> 
			Inculcate art into the university’s culture and experience in RIT Dubai.<br> 
			Encourage often neglected art forms in RIT Dubai.<br> 
			Provide therapeutic and stress-relieving events amidst the demanding academic year.
		</p>
		<h3>Contact Information:</h3>
		<p>Yogesh Damodhar Mata</p>
		<a href="mailto:ydm7743@rit.edu">ydm7743@rit.edu</a>
		<a href="https://forms.gle/ueCgUFU3koH5Uox98" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase=" 'Model United Nations'">
		<p style="width:100%; height: 350px;">
			Act as a communicative body handling outreach and delegation to MUN conferences. Train and provide opportunities to its members for oratorship and debate.
			Involve RIT and its community in the Dubai MUN circuit. Be an inclusive, conducive environment for all members to grow and improve as global citizens.
		</p>
		<h3>Contact Information:</h3>
		<p>Mohamed Abdullah</p>
		<a href="mailto:man1170@rit.edu">man1170@rit.edu</a>
		<a href="https://docs.google.com/forms/d/1e3GKdBqElfdlnKN7-LqYjuxYXgGbnrHgKmUJ7Gu8fqc/edit?usp=sharing_eip_m&ts=650ffc55" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase=" 'TechSphere'">
		<p style="width:100%; height: 350px;">
			Educate & Inspire: Provide our members with cutting-edge knowledge, resources, and hands-on experiences in AI, cybersecurity, cloud, networking, and programming. Collaborate & Innovate: Create a collaborative platform where students can work together on projects, share ideas, and bring innovative solutions to real-world problems.
			Connect & Grow: Build strong connections with industry leaders, alumni, and peers, facilitating networking opportunities, mentorship, and career growth.
			Engage & Empower: Host a range of events, from seminars to hackathons, ensuring active engagement, continuous learning, and the empowerment of our members.
		</p>
		<h3>Contact Information:</h3>
		<p>Waseem Qaffaf</p>
		<a href="mailto:whq8052@rit.edu">whq8052@rit.edu</a>
		<a href="https://docs.google.com/forms/d/e/1FAIpQLSdgWr5AHwOVSeRP79ECOn5WzgLUFQguS4wx7I2setPqOBBJog/viewform?usp=sf_link" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase=" 'RIT Gamers Guild'">
		<p style="width:100%; height: 350px;">
			Our mission is to create a welcoming hub for students of various skill levels who share a similar passion for gaming, allowing them to connect and socialize.
			Serve as a springboard for students aspiring to enter various fields such as game development and game design.<br><br>
			To recognize and celebrate gamers with high skill level, bestowing them bragging rights.
			In addition, our club aspires to become the epicenter of talent, providing opportunities for those who have yet to showcase their skills and abilities, giving them the opportunity to represent our university in professional gaming competitions.			
		</p>
		<h3>Contact Information:</h3>
		<p>Yusuf Azeem</p>
		<a href="mailto:yam3751@g.rit.edu">yam3751@g.rit.edu</a>
		<a href="RIT Gamers Guild Registration Form" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase=" 'Desi Club'">
		<p style="width:100%; height: 350px;">
			Our club aims to introduce RIT students to the rich cultural experience of Desi Culture. All our students are invited to join in the attempt to make you feel at home. So we shall congregate at the RIT Desi Club to celebrate at the RIT Desi Club to celebrate our festivals,
			cultures, and traditions.
		</p>
		<h3>Contact Information:</h3>
		<p>Velan Loganathan</p>
		<a href="mailto:vl9195@rit.edu">vl9195@rit.edu</a>
		<a href="https://forms.gle/2H4CYWjQyBaWHESP6" target="_blank">
			<button class="signUp">SIGN UP</button>
		</a>
	</div>
	<div *ngSwitchCase=" 'Graphic Design Club'">
		<p style="width:100%; height: 350px;">
			To promote and encourage interest in the field of graphic design through activities and events <br>
			To provide students with opportunities to learn and share their creativity with others <br>
			To encourage students to learn design principles through problem-solving <br>
			To establish a strong community where students connect and collaborate with each other to improve their design skills
		</p>
		<h3>Contact Information:</h3>
		<p>Sabrina Al Bukhari</p>
		<a href="mailto:sma5242@rit.edu">sma5242@rit.edu</a>
		<button class="signUp">SIGN UP</button>
	</div>
	<!-- <div *ngSwitchCase=" 'The Debate Club'">
		<p style="width:100%; height: 350px;">The Debate Club is a student organization dedicated to facilitating open dialogue, critical thinking, and effective communication among its members. It provides a safe and inclusive environment where students can express their ideas freely and respectfully. The club organizes various events and activities throughout the year to achieve its objectives, including debate tournaments, debate workshops, public speaking training, and debate forums.
		</p>
		<h3>Contact Information:</h3>
		<p>Mohammed Mohammedtayib</p>
		<a href="mailto:mnm6346@g.rit.edu">mnm6346@g.rit.edu</a>
		<button class="sign up">SIGN UP</button>
	</div> -->
	<button class=" close" (click)="close()">x</button>
</div>
<script src="clubs.component.ts"></script>

<app-footer></app-footer>