<div class="container">
	<div class="top">
		<img src="/assets/admin/login/sg-logo-white.png" />
		<div class="title">admin</div>
	</div>
	<div class="middle">
		<div class="loginBox">
			<div class="title">SG PORTAL<span></span></div>
			<form #loginForm="ngForm" class="loginForm" (ngSubmit)="login()">
				<div class="email input">
					<input
						type="email"
						name="email"
						required
						[(ngModel)]="email"
						required
					/>
				</div>

				<div class="password input">
					<input
						type="password"
						name="password"
						required
						[(ngModel)]="password"
						required
					/>
				</div>

				<button
					class="loginbtn"
					type="submit"
					[disabled]="!loginForm.valid"
				>
					Login
				</button>
			</form>
		</div>
	</div>
	<div class="bottom">
		<img src="/assets/admin/login/rit-logo-horizontal-white.png" />
	</div>
</div>
